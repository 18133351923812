import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { SiteModel } from 'src/app/models/site.model';
import { SiteService } from 'src/app/services/site.service';

@Pipe({
  name: 'getSite'
})
export class GetSitePipe implements PipeTransform {
  constructor(private siteService: SiteService) {}

  transform(value: number): Observable<SiteModel> {
    return new Observable<SiteModel>((observer) => {
      this.siteService.getAllSites().subscribe((s) => {
        observer.next(s.rows.find((_s) => _s.siteId === value));
      });
    });
  }
}

@NgModule({
  declarations: [GetSitePipe],
  exports: [GetSitePipe]
})
export class GetSitePipeModule {}
