<ng-container *transloco="let t">
  @if (processResult) {
    <h2 mat-dialog-title>
      {{ processResult.title }}
      @if (processResult.stepResults[0].vessel) {
        - {{ processResult.stepResults[0].vessel | idToVessel | async }}
      }
    </h2>
    <mat-dialog-content>
      @if (processResult.processDataFields.length) {
        <mat-card>
          <mat-card-title class="gap-xs">{{ t('expansion_table.predefined_fields') }}</mat-card-title>
          <mat-card-content>
            <div class="grid two-columns">
              @for (item of processResult.processDataFields; track $index) {
                <p>{{ item.name }}</p>
                @if (item.name?.includes('Visitor')) {
                  <p>{{ item.value | idToName | async }}</p>
                } @else if (item.name?.includes('Ship')) {
                  <p>{{ item.value | idToVessel | async }}</p>
                } @else {
                  @if (item.type === inputTypes.DATE) {
                    <p>{{ item.value | date: 'short' }}</p>
                  } @else {
                    <p>{{ item.value }}</p>
                  }
                }
              }
            </div>
          </mat-card-content>
        </mat-card>
        <hr />
      }
      @for (step of processResult.stepResults; track $index; let index = $index) {
        <mat-card class="margin-xs-bottom">
          <mat-card-header class="gap-xs">
            <div class="width-100">
              <mat-card-title> {{ step.title }} </mat-card-title>
              <mat-card-subtitle>
                <div class="flex space-between width-100">
                  <span class="text-muted"> {{ t('expansion_table.step') }} {{ index + 1 }} </span>
                  <span class="text-muted">{{ t('expansion_table.from') }}: {{ step.executed | date: 'medium' }}</span>
                </div>
              </mat-card-subtitle>
            </div>
          </mat-card-header>
          <mat-card-content>
            <div class="grid two-columns margin-xs-top">
              @for (item of step.dataFields; track $index) {
                <p>{{ item.fieldName }}</p>
                @switch (item.fieldType) {
                  @case ('date-time') {
                    <p>{{ item.fieldValue | date }}</p>
                  }
                  @default {
                    @if (item.fieldName?.includes('Visitor')) {
                      <p>{{ item.fieldValue | idToName | async }}</p>
                    } @else if (item.fieldName?.includes('Ship')) {
                      <p>{{ item.fieldValue | idToVessel | async }}</p>
                    } @else {
                      <div class="flex align-center">
                        <p>{{ item.fieldValue }}</p>
                        @if (item.fieldType === 'onmap') {
                          <button class="width-70" mat-raised-button (click)="openMapDialog(item.fieldValue)">
                            {{ t('general.show_on_map') }}
                          </button>
                        }
                      </div>
                    }
                  }
                }
              }
            </div>
          </mat-card-content>
        </mat-card>
      }
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-dialog-close mat-raised-button>{{ t('general.close') }}</button>
    </mat-dialog-actions>
  }
</ng-container>
