import { Component, ElementRef, EventEmitter, Input, NgModule, Output, ViewChild } from '@angular/core';
import { BackendService, IResponseInterface } from 'src/app/services/backend.service';
import { HttpEventType } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'app-upload-form',
  templateUrl: './upload-form.component.html',
  styleUrls: ['./upload-form.component.scss']
})
export class UploadFormComponent {
  @Input() url: string;
  @Input() disabledPick = false;
  @Input() disabledUpload = false;
  @Input() additionalData: { [s: string]: any } = {};
  @Input() placeholder: string;
  @Input() hideUpload = false;
  @Input() multiple = false;

  @Output() overrideUpload = new EventEmitter<File | FileList>();
  @Output() afterUpload = new EventEmitter<IResponseInterface<any>>();

  @ViewChild('fileInput') someInput: ElementRef;

  filePath = '';
  fileName = '';
  file: File;

  fileList: FileList;
  progress: number;

  constructor(private backend: BackendService) {}

  selectFile(): void {
    this.someInput.nativeElement.click();
  }

  onChange(e: FileList): void {
    console.log(e);
    this.fileList = e;
    if (e[0] && !this.multiple) {
      this.fileName = e[0].name;
      this.file = e[0];
    } else if (e[0] && this.multiple) {
      this.fileName = Object.values(this.fileList)
        .map((f) => this.truncateFileName(f.name, 12))
        .join(', ');
      this.file = e[0];
    } else {
      this.fileName = null;
      this.file = null;
    }
  }

  truncateFileName(str: string, num: number): string {
    if (str.length <= num) {
      return str;
    }
    const fileExt = str.split('.').pop();
    const fileName = str.substr(0, str.length - fileExt.length);
    return fileName.substr(0, num) + '.. .' + fileExt;
  }

  clearInputs(): void {
    this.fileName = null;
    this.filePath = null;
    this.file = null;
    this.fileList = null;
    this.progress = null;
  }

  uploadFile(): void {
    if (this.overrideUpload.observers.length > 0) {
      this.overrideUpload.emit(this.multiple ? this.fileList : this.file);
    } else if (this.url.length > 0) {
      const formData = new FormData();
      if (this.multiple) {
        for (const file of Object.values(this.fileList)) {
          formData.append('files', file);
        }
      } else {
        formData.append('file', this.file);
      }
      for (const name of Object.keys(this.additionalData)) {
        formData.append(name, this.additionalData[name]);
      }
      this.backend.upload(this.url, formData).subscribe((res) => {
        if (res.type === HttpEventType.Response) {
          this.clearInputs();
          if (this.afterUpload.observers.length > 0) {
            this.afterUpload.emit(res.body);
          }
        } else if (res.type === HttpEventType.UploadProgress) {
          this.progress = Math.round((res.loaded / res.total) * 100);
        }
      });
    } else {
      console.error('URL missing');
    }
  }
}

@NgModule({
  declarations: [UploadFormComponent],
  exports: [UploadFormComponent],
  imports: [
    FormsModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatProgressBarModule,
    MatButtonModule,
    TranslocoModule
  ]
})
export class UploadFormComponentModule {}
