import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

(window as any).global = window;

if (environment.production) {
  enableProdMode();
}

// bootstrapApplication(TableMobileWrapperComponent, {
//   providers: [importProvidersFrom(TranslateModule.forRoot())]
// });

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
