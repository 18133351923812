import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { MemoryService } from 'src/app/services/memory.service';
import { config } from 'src/config';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-test-app-dialog',
  templateUrl: './test-app-dialog.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatMenuModule,
    MatListModule,
    TranslocoModule
  ]
})
export class TestAppDialogComponent implements OnInit {
  languages: { label: string; value: string }[] = [];
  availableLanguages: string[];
  environment = environment.environment;

  constructor(
    public dialogRef: MatDialogRef<TestAppDialogComponent>,
    private readonly translocoService: TranslocoService,
    private readonly memoryService: MemoryService
  ) {}

  ngOnInit(): void {
    this.availableLanguages = this.translocoService.getAvailableLangs() as string[];
    this.languages = [];
    for (const lang of this.availableLanguages) {
      this.languages.push({ label: config.languages[lang], value: lang });
    }
  }

  useLanguage(id: string): void {
    this.memoryService.changeLanguage(id);
  }
}
