import { inject, Injectable } from '@angular/core';
import { BackendObservable, BackendService } from './backend.service';
import { Observable } from 'rxjs';
import {
  EquipmentGroupModel,
  EquipmentModel,
  EquipmentSubGroupModel,
  EquipmentSupplierModel,
  EquipmentUserClassModel
} from '../models/equipment.model';
import { ISequelizeCount } from '../models/sequelize-count.interface';
import { DataField } from '../interfaces/process/data-field.interface';
import { CombinedSizes, JACKET_SIZE_MEN, JacketSize, MULTINORM_MEN } from '../interfaces/user/clothing-sizes';
import { User } from '../interfaces/user/user.interface';
import { ShoppingCart } from '../interfaces/equipment/shopping-types';
import { CachingService } from './caching.service';

@Injectable({
  providedIn: 'root'
})
export class EquipmentService extends BackendService {
  cachingService = inject(CachingService);
  // TYPESSSSS !!!!!!!!!!!!!!!!!!!!!!!!!

  getAvailableSizes(): Observable<{
    headSizes: number[];
    shoeSizes: number[];
    topSizesLetter: number[];
    bottomSizesNumber: number[];
    gloveSizes: number[];
  }> {
    return this.cachingService.createCachingSubscription('availableSizes', this.get('availableSizes'));
  }

  getEquipmentCatalogChanges(): Observable<{ count: number; catalogData: any }> {
    return this.post('equipmentCatalogChanges/get');
  }

  getEquipmentPerUser(
    criteria?: any,
    limit?: number,
    offset?: number,
    order?: string[][]
  ): Observable<{ count: number; assetsPerUser: any }> {
    return this.post('equipmentPerUser/get', { criteria, limit, offset, order });
  }

  getOpenEquipmentPerUser(
    criteria?: any,
    limit?: number,
    offset?: number,
    order?: string[][]
  ): Observable<{ count: number; assetsOpen: any; allReturns: any }> {
    return this.post('equipmentOpenPerUser/get', { criteria, limit, offset, order });
  }

  getUserOrderItems(
    itemsInCart: EquipmentModel[]
  ): Observable<{ requiredEquipment: EquipmentModel[]; structuredEquipment: any[]; runningOrderIds: string[] }> {
    return this.post<{ requiredEquipment: EquipmentModel[]; structuredEquipment: any[]; runningOrderIds: string[] }>(
      'userOrderItems',
      {
        itemsInCart
      }
    );
  }

  hasAllBaseItems(): Observable<{
    hasAllItems: boolean;
    baseItems: EquipmentModel[];
    runningOrderBaseItemIds: string[];
    cartItems: ShoppingCart;
  }> {
    return this.get<{
      hasAllItems: boolean;
      baseItems: EquipmentModel[];
      runningOrderBaseItemIds: string[];
      cartItems: ShoppingCart;
    }>('hasAllBaseItems');
  }

  getGroups(body?: {
    includeSubGroups?: boolean;
    includeEquipment?: boolean;
    queryAll?: boolean;
  }): Observable<ISequelizeCount<EquipmentGroupModel>> {
    return this.post<ISequelizeCount<EquipmentGroupModel>>('group/get', body);
  }

  updateGroup(body: { groupId: number; userClassData: string }) {
    return this.post<EquipmentGroupModel>('group/update', body);
  }

  updateSubGroup(body: { subGroupId: number; userClassData: string }) {
    return this.post<EquipmentSubGroupModel>('subGroup/update', body);
  }

  updateGear(body: { equipmentId: number; userClassData: string; shelfLife: number }) {
    return this.post<EquipmentModel>('update', body);
  }

  getSubGroups(body?: {
    includeEquipment?: boolean;
    includeGearGroups?: boolean;
  }): Observable<ISequelizeCount<EquipmentSubGroupModel>> {
    return this.post<ISequelizeCount<EquipmentSubGroupModel>>('subGroup/get', body);
  }

  getGearGroups(body?: { includeEquipment?: boolean }): Observable<ISequelizeCount<EquipmentSubGroupModel>> {
    return this.post<ISequelizeCount<EquipmentSubGroupModel>>('subGroup/get', body);
  }

  //   createEquipment(formData: FormData): Observable<any> {
  //     return this.upload('equipment/create', formData);
  //   }

  getEquipment(
    criteria?: any,
    limit?: number,
    offset?: number,
    order?: string[][],
    paranoid?: boolean
  ): Observable<ISequelizeCount<EquipmentModel>> {
    return this.post<ISequelizeCount<EquipmentModel>>('get', { criteria, limit, offset, order, paranoid });
  }

  createEquipmentSubGroup(body: any): BackendObservable<EquipmentSubGroupModel> {
    return this.post<EquipmentSubGroupModel>('subGroup/create', body);
  }

  createEquipmentGroup(body: any): BackendObservable<EquipmentGroupModel> {
    return this.post<EquipmentGroupModel>('group/create', body);
  }

  getUserClasses(
    criteria?: any,
    limit?: number,
    offset?: number
  ): Observable<ISequelizeCount<EquipmentUserClassModel>> {
    return this.post<ISequelizeCount<EquipmentUserClassModel>>('userClasses', { criteria, limit, offset });
  }

  addNewUserClass(name: string): Observable<EquipmentUserClassModel> {
    return this.post<EquipmentUserClassModel>('userClasses/add', { name });
  }

  deleteUserClass(id: number): Observable<number> {
    return this.post<number>('userClasses/delete', { id });
  }

  getSupplier(criteria?: any, limit?: number, offset?: number): Observable<ISequelizeCount<EquipmentSupplierModel>> {
    return this.post<ISequelizeCount<EquipmentSupplierModel>>('supplier', { criteria, limit, offset });
  }

  addNewSupplier(name: string): Observable<EquipmentSupplierModel> {
    return this.post<EquipmentSupplierModel>('supplier/add', { name });
  }

  deleteSupplier(id: number): Observable<number> {
    return this.post<number>('supplier/delete', { id });
  }

  getCountries(lang: string) {
    return this.get('countries/europe/' + lang);
  }

  adjustSupplierIdsToCartItems(cartItemIds: string[], processId?: number): Observable<DataField> {
    return this.post<DataField>('adjustSupplierIdsToCartItems', { cartItemIds, processId });
  }

  getCostByOrderProcessId(processId: number): Observable<{ overallCost: number }> {
    return this.get<{ overallCost: number }>(`order/cost/${processId}`);
  }

  updateCatalog(catVersion: string) {
    return this.post('catalog/update', { catVersion });
  }

  getShoppingCartItems(): Observable<EquipmentModel[]> {
    return this.get<EquipmentModel[]>('getCart');
  }

  addItemToShoppingCart(itemId: string | string[]): Observable<EquipmentModel[]> {
    if (Array.isArray(itemId)) {
      return this.post<EquipmentModel[]>('addToCart', { itemIds: itemId });
    } else {
      return this.post<EquipmentModel[]>('addToCart', { itemId });
    }
  }

  canAddInitialItemsToCart(): Observable<{ addCartItems: boolean }> {
    return this.get<{ addCartItems: boolean }>('canAddInitialItemsToCart');
  }

  canCreateInitialItems(): Observable<{ createItems: boolean }> {
    return this.get<{ createItems: boolean }>('canCreateInitialItems');
  }

  removeItemFromShoppingCart(itemId: string): Observable<EquipmentModel[]> {
    return this.post<EquipmentModel[]>('removeFromCart', { itemId });
  }

  removeAllItemFromShoppingCart(): Observable<boolean> {
    return this.post<boolean>('removeFromCart', { clear: true });
  }

  post<T>(url: string, body: any = {}, options?: any): BackendObservable<T> {
    return super.post<T>(`equipment/${url}`, body, options);
  }

  get<T>(url: string, body: any = {}, options?: any): Observable<T> {
    return super.get<T>(`equipment/${url}`, options);
  }

  /**
   * This function calculates the distances from the midpoint of each range and selects the size with the smallest total distance.
   *
   * @param height
   * @param chest
   * @param taille
   * @param hip
   * @param inseam
   * @returns
   */
  getClothingSizes(height: number, chest: number, taille: number, hip: number, inseam?: number): CombinedSizes {
    let closestSizeBody: string | undefined;
    let closestSizeMultinorm: string | undefined;
    let closestDistanceBody: number = Number.MAX_SAFE_INTEGER;
    let closestDistanceMultinorm: number = Number.MAX_SAFE_INTEGER;

    JACKET_SIZE_MEN.forEach((sizeInfo: JacketSize) => {
      const heightRange = sizeInfo.height.split('-').map(Number);
      const chestRange = sizeInfo.chest.split('-').map(Number);
      const tailleRange = sizeInfo.taille.split('-').map(Number);

      const heightDistance = Math.abs(height - (heightRange[0] + heightRange[1]) / 2);
      const chestDistance = Math.abs(chest - (chestRange[0] + chestRange[1]) / 2);
      const tailleDistance = Math.abs(taille - (tailleRange[0] + tailleRange[1]) / 2);

      const totalDistance = heightDistance + chestDistance + tailleDistance;

      if (totalDistance < closestDistanceBody) {
        closestSizeBody = sizeInfo.size;
        closestDistanceBody = totalDistance;
      }
    });

    MULTINORM_MEN.forEach((sizeInfo: JacketSize) => {
      const heightRange = sizeInfo.height.split('-').map(Number);
      const chestRange = sizeInfo.chest.split('-').map(Number);

      const heightDistance = Math.abs(height - (heightRange[0] + heightRange[1]) / 2);
      const chestDistance = Math.abs(chest - (chestRange[0] + chestRange[1]) / 2);

      const totalDistance = heightDistance + chestDistance;

      if (totalDistance < closestDistanceMultinorm) {
        closestSizeMultinorm = sizeInfo.size;
        closestDistanceMultinorm = totalDistance;
      }
    });

    return { bodywear: closestSizeBody, eur: closestSizeMultinorm };
  }

  /**
   * Datatype of classData is any but must contain at least a userClassId and be in json stringified format
   *
   * @param userClassData
   */
  isInUserClass(userData: User, userClassData: any): boolean {
    const data: any[] = JSON.parse(userClassData);
    const match = data.find((item) => Number(item.userClassId) === userData.equipmentClassId);

    if (match) {
      return true;
    }

    return false;
  }
}
