import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ExpansionTableRows } from 'src/app/components/expansion-table/interfaces/expansion-table-data.interface';
import { DataField } from 'src/app/interfaces/process/data-field.interface';
import { ProcessDataModel } from 'src/app/interfaces/process/process-data.interface';

@Injectable({ providedIn: 'root' })
export class InspectorService {
  openTasks$ = new Subject<number>();

  getRowDataFromProcessData(processData: ProcessDataModel, processDefinitionId: number): ExpansionTableRows[] {
    const rows: ExpansionTableRows[] = [];
    let dataFields: DataField[] = [];
    if (processDefinitionId === 5) {
      // only show datafields with errors for process critical euqipment
      // steps with these fields are: 29, 31, 33, 35, 37, 38, 40, 42, 44
      // Datafields are: 39, 41, 43, 45, 47, 49, 51, 53, 54
      dataFields = processData.DataFields.filter((e) =>
        [39, 41, 43, 45, 47, 49, 51, 53, 54].includes(e.data_field_definition_id)
      );
    }

    dataFields.forEach((field) => {
      rows.push({ header: field.name, content: field.value });
    });

    return rows;
  }
}
