import { Component, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { environment } from '../environments/environment';
import { BackendService } from './services/backend.service';
import { MemoryService } from './services/memory.service';
import { NotificationService } from './services/notification.service';
import { ProcessService } from './services/process.service';
import { StaticPageService } from './services/static-page.service';
import { TranslationBackendService } from './services/translation-file.service';
import { InspectorService } from './views/inspector/tasks/inspector.service';
import { MobileViewService } from './services/mobile-view.service';
import { UserService } from './services/user.service';
import { LoginComponent } from './views/authentication/login/login.component';
import { StaticPageModel } from './interfaces/static-pages/static-page.model';
import { StartProcessService } from './components/visits-reports/start/start-process.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [StartProcessService]
})
export class AppComponent implements OnInit {
  showDrawer = false;
  version = localStorage.getItem('app_version');
  title = environment.appTitle;
  navigating = false;

  awaitingLogin = false;

  staticPageMenu: { [s: string]: StaticPageModel[] } = {};

  roleId: number;
  showReload: boolean;

  isMobileView: boolean;
  isOpened: boolean;

  constructor(
    private backend: BackendService,
    private notify: NotificationService,
    private memoryService: MemoryService,
    private router: Router,
    private staticPageService: StaticPageService,
    private readonly processService: ProcessService,
    private readonly userService: UserService,
    private readonly inspectorService: InspectorService,
    private readonly translationFileService: TranslationBackendService,
    private readonly mobileViewService: MobileViewService,
    private readonly startProcessService: StartProcessService
  ) {
    this.isOpened = false;
    this.isMobileView = mobileViewService.detectMobileDevice();
    this.translationFileService.getAllFETranslations();
    this.backend.setErrorHandler((error) => {
      let msg = 'Es ist ein Fehler aufgetreten';
      if (error.status === 400) {
        msg = `Bad Request - ${error.error.error.message}`;
      } else if (error.status === 401) {
        msg = `Unauthorized - ${error.error.error.message}`;
      } else if (error.status === 404) {
        msg = `Not Found - ${error.error.error.message}`;
      } else if (error.status > 404 && error.status < 500) {
        msg = 'Keine Verbindung zum Internet oder Server';
      } else if (error.status >= 500) {
        msg = 'Die Anfrage konnte nicht bearbeitet werden (' + error.status + ')';
      } else if ('onLine' in navigator) {
        msg = 'Keine Verbindung zum ' + (navigator.onLine ? 'Server' : 'Internet');
      } else {
        msg = 'Keine Verbindung zum Internet oder Server';
      }
      console.log(msg);
      if (error.status !== 471) {
        this.notify.error(msg, 7);
      }
    });
    this.memoryService.$userLoginData.subscribe((userData) => {
      this.awaitingLogin = false;
      if (userData) {
        this.showDrawer = true;
        this.staticPageService.getAll(true).subscribe((p) => {
          this.buildMenuFromStaticPages(p);
        });
        this.roleId = userData.roleId;
      } else {
        this.showDrawer = false;
      }
    });
    if (this.memoryService.isLoggedIn()) {
      this.awaitingLogin = true;
      const loginData = this.memoryService.getLoginData();
      this.backend.checkLogin(loginData.loginToken);
    }

    this.router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        this.navigating = true;
      }
      if (e instanceof NavigationEnd || e instanceof NavigationCancel || e instanceof NavigationError) {
        this.navigating = false;
      }
    });

    this.memoryService.$changeSideMenuVisibility.subscribe((e) => {
      this.isOpened = false;
      this.isMobileView = e;
    });
  }

  ngOnInit(): void {
    this.isMobileView = this.mobileViewService.detectMobileDevice();

    this.backend.showReloadButton$.subscribe((reload) => (this.showReload = reload));
    document.title = this.title;
  }

  toggleDrawer() {
    this.isOpened = !this.isOpened;
  }

  onMenuItemSelected() {
    if (this.isMobileView) {
      this.isOpened = false;
    }
  }

  onActivate(event) {
    if (!(event instanceof LoginComponent)) {
      this.memoryService.$userLoginData.subscribe((loginData) => {
        if (loginData?.roleId === 6) {
          this.userService.getUserNotifications().subscribe((data) => {
            this.inspectorService.openTasks$.next(data.count);
          });
        } else if (loginData?.roleId === 10) {
          this.userService
            .getUserNotifications(false, {
              subscription: {
                $or: [
                  { $substring: '%missing%' },
                  { $substring: '%defect%' },
                  { $substring: '%notFitting%' },
                  { $substring: '%price-raise%' },
                  { $substring: '%base-item%' }
                ]
              },
              userId: loginData.userId
            })
            .subscribe((data) => {
              this.inspectorService.openTasks$.next(data.count);
            });
        }
      });
    }
  }

  reload() {
    this.showReload = false;
    window.location.reload();
  }

  navBarToggle(): void {
    this.isOpened = !this.isOpened;
  }

  handleStaticPageAction(staticPage: StaticPageModel): void {
    switch (staticPage.action?.action) {
      case 'startProcessByDefinitionId':
        this.processService
          .findAllActiveSteps(
            {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              '$process.process_definition_id$': 16,
              // eslint-disable-next-line @typescript-eslint/naming-convention
              '$stepDefinition.type$': { $lt: 100 },
              executed: null
            },
            undefined,
            undefined,
            undefined,
            { includeProcessDataFields: true }
          )
          .subscribe((steps) => {
            this.startProcessService.startProcess(
              16,
              this.memoryService.getLoginData().userId,
              null,
              'emergency',
              steps.rows,
              this.startProcessService.generatePredefinedDatafieldsByDefinitionId(16),
              true
            );
          });
        break;
    }
  }

  private buildMenuFromStaticPages(staticPages: StaticPageModel[]) {
    const menu: { [s: string]: StaticPageModel[] } = {};
    for (const page of staticPages) {
      menu[page.menu] = menu[page.menu] || [];
      menu[page.menu].push(page);
    }
    this.staticPageMenu = menu;
  }
}
