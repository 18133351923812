import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';

@Pipe({
  name: 'idToName',
  standalone: true
})
export class IdToNamePipe implements PipeTransform {
  constructor(private userService: UserService) {}

  transform(userId: any, ...args: unknown[]): Observable<string> {
    return new Observable<string>((observer) => {
      if (Number(userId)) {
        this.userService.getAllUsersCached().subscribe(
          (users) => {
            const user = users.rows.find((u) => u.userId === Number(userId));
            if (user) {
              observer.next(`${user.forename} ${user.surname}`);
            } else {
              observer.next('-');
            }
            observer.complete();
          },
          (error) => observer.error(error)
        );
      } else {
        if (!userId) userId = '-';
        observer.next(userId);
      }
    });
  }
}
