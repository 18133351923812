<mat-form-field
  [subscriptSizing]="subscriptSizing"
  [class.form-field-small]="autoWidth"
  [class.width-125px]="autoWidth && !width100"
  [class.width-100]="width100"
>
  <mat-label *ngIf="!autoWidth">{{ placeholder }}</mat-label>
  <mat-select
    (selectionChange)="selectionChange.emit($event)"
    #select
    [placeholder]="placeholder"
    [multiple]="multiple"
    [(value)]="selectedItem"
    [required]="required"
  >
    <mat-option style="background: white">
      <ngx-mat-select-search [formControl]="optionsFilterCtrl"></ngx-mat-select-search>
    </mat-option>
    <ng-container *ngFor="let option of optionsFiltered">
      <ng-container *ngIf="!option.children?.length">
        <mat-option [value]="valueAttribute ? option[valueAttribute] : option">
          <ng-container
            *ngTemplateOutlet="optionsTemplate || defaultOptionsTemplate; context: { $implicit: option }"
          ></ng-container>
        </mat-option>
      </ng-container>
      <ng-container *ngIf="option.children">
        <mat-optgroup [label]="option.name">
          <mat-option
            *ngIf="!parentOnlyAsGroup && (!valueAttribute || option[valueAttribute])"
            [value]="valueAttribute ? option[valueAttribute] : option"
            >Alle</mat-option
          >
          <mat-option *ngFor="let option of option.children" [value]="valueAttribute ? option[valueAttribute] : option">
            <ng-container
              *ngTemplateOutlet="optionsTemplate || defaultOptionsTemplate; context: { $implicit: option }"
            ></ng-container>
          </mat-option>
        </mat-optgroup>
      </ng-container>
    </ng-container>
    <!--<ng-container *ngTemplateOutlet="recursiveMatOptionTemplate; context: {$implicit: options}"></ng-container>-->
  </mat-select>
  <button
    mat-button
    *ngIf="selectedItem && showClear"
    matSuffix
    aria-label="Clear"
    (click)="selectedItem = undefined; clear.emit()"
  >
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
<ng-template #defaultOptionsTemplate let-option>{{ option }}</ng-template>

<ng-template #recursiveMatOptionTemplate let-options>
  <ng-container *ngFor="let option of options">
    <mat-option [value]="valueAttribute ? option[valueAttribute] : option">
      <ng-container
        *ngTemplateOutlet="optionsTemplate || defaultOptionsTemplate; context: { $implicit: option }"
      ></ng-container>
    </mat-option>
    <ng-container *ngIf="option.children">
      <mat-optgroup [label]="option.name">
        <ng-container
          *ngTemplateOutlet="recursiveMatOptionTemplate; context: { $implicit: option.children }"
        ></ng-container>
      </mat-optgroup>
    </ng-container>
  </ng-container>
</ng-template>
