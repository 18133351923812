import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription, Observable, forkJoin } from 'rxjs';
import { ProcessHandlerService } from '../process-handler-dialog/process-handler.service';
import { ActionResult } from 'src/app/interfaces/process/action-result.interface';
import { IProcessStartOptions } from 'src/app/interfaces/process/process.interface';
import { TypeData } from 'src/app/interfaces/process/step-definition.interface';
import { Step } from 'src/app/interfaces/process/step.interface';
import { subscriptionIsActive } from 'src/app/services/backend.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ProcessService } from 'src/app/services/process.service';
import { DataField, DataFieldTranslation } from 'src/app/interfaces/process/data-field.interface';

@Component({
  selector: 'app-process-handler',
  templateUrl: './process-handler.component.html'
})
export class ProcessHandlerComponent implements OnInit, OnChanges {
  @Input() steps: Step<TypeData>[];
  @Input() initialProcessStartOptions: IProcessStartOptions;
  @Input() noTabs: boolean;
  @Input() translations: DataFieldTranslation[];
  @Output() allStepsPerformed = new EventEmitter<any>();
  @Output() onActionPerformed = new EventEmitter<any>();
  processStartOptions: IProcessStartOptions;

  subscriptionIsActive = subscriptionIsActive;

  showDetails = false;
  showDescription = false;
  components: string[];

  step: Step<TypeData>;
  data: any;
  history: Step[];
  suggestedDataFields: DataField[] = [];

  subscriptions: {
    performStep?: Subscription;
  } = {};

  constructor(
    private processService: ProcessService,
    private notification: NotificationService,
    private readonly processHandlerService: ProcessHandlerService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.initialProcessStartOptions && this.initialProcessStartOptions) {
      this.processStartOptions = JSON.parse(JSON.stringify(this.initialProcessStartOptions));
    }
  }

  ngOnInit(): void {
    this.initStep(this.steps.shift());
  }

  initStep(step?: Step<TypeData>, lastStep?: boolean): void {
    if (!step || lastStep) {
      this.processHandlerService.getStepProgression();
      this.allStepsPerformed.emit(step);
      return;
    }
    if (step.stepDefinition.type_data.components) {
      const baseComponontString = step.stepDefinition.type_data.components as string;
      this.components = baseComponontString.split('--');
    }

    this.processHandlerService.getStepProgression(step);
    this.setInitialValues(step);

    if (step.process_id && step.process.process_definition_id !== 22) {
      this.processService.getData(step.process_id).subscribe(async (d) => {
        this.data = d;
        this.suggestedDataFields = [];
        for (const dataFieldId of step.stepDefinition?.type_data._suggested_data_fields || []) {
          const dataField: DataField = this.data.DataFields.find(
            (_d: any) => _d.data_field_definition_id === dataFieldId
          );
          if (dataField) {
            this.suggestedDataFields.push(dataField);
          }
        }

        // get possible changed data and update step array value(s), this happens if datafields change automatically during the process or a process is continued
        try {
          this.step = await this.processHandlerService.transformDataFields(
            step.stepDefinition.type_data.inputs,
            this.data,
            step
          );
          // update this.step to trigger change detection in child components
          this.step = step;
        } catch (error) {
          // just set this.step nothing changed
          this.step = step;
        }
      });
    } else {
      // no process_id => initial step (processDefinition 22 is only working because it has only 1 step)
      // dont call getData the neccessary datafield data is inherited by the parent process (Warenbestellung)
      this.data = step.process;
      this.suggestedDataFields = [];
      if (step.stepDefinition?.type_data._suggested_data_fields) {
        for (const dataFieldId of step.stepDefinition?.type_data._suggested_data_fields) {
          const dataField: DataField = this.data.DataFields.find(
            (_d: any) => _d.data_field_definition_id === dataFieldId
          );
          if (dataField) {
            this.suggestedDataFields.push(dataField);
          }
        }
        // if this loads a step was performed
        this.onActionPerformed.emit(true);
      } else {
        // How to set up suggested datafields in the first step?
      }
      this.step = step;
    }
  }

  onActionHandled(result: any): void {
    this.subscriptions.performStep = this.processService
      .performStep(this.step, result, this.initialProcessStartOptions)
      .subscribe({
        next: (res) => {
          this.onStepPerformed(res);
        },
        error: (error) => {
          this.notification.error(error.message || JSON.stringify(error));
        }
      });
  }

  onBackAction(res): void {
    this.processService.terminateToOtherStepDefinition(res.stepId, res.stepDefinitionId).subscribe(
      (e) => {
        this.processService.getStep(e.step.id, e.step.process_id).subscribe((step) => this.initStep(step));
      },
      (error) => console.log(error)
    );
  }

  async onStepPerformed(res: ActionResult): Promise<void> {
    this.components = [];
    this.showDetails = false;
    if (res.result) {
      if (res.next_steps?.length) {
        // enhance the first next step and load it directly
        const next = res.next_steps.shift();
        this.steps.push(await this.processService.getStep(next.id, next.process_id).toPromise()); // todo enhance
        this.steps = this.steps.filter((s) => s.stepDefinition.type < 100);

        // enhance all other next steps
        const observables: Observable<Step>[] = [];
        for (const s of res.next_steps) {
          observables.push(this.processService.getStep(s.id, s.process_id));
        }

        forkJoin(observables).subscribe((results) => {
          this.steps.push(...results.filter((s) => s.stepDefinition.type < 100));
        });
        this.initStep(this.steps.shift());
      } else {
        // lastStep = true, return last step as reference
        this.initStep(res.step, true);
      }
    }
  }

  /**
   * Check if the start step has additional options for autofill values. Add them to the form.
   *
   * Translation is needed!! Else the type_data entry can not be found!
   */
  private setInitialValues(step: Step) {
    if (step.stepDefinition.StepPredecessorDefinitions) {
      const inputNames = step.stepDefinition.type_data.inputs?.map((e) => e.name);
      if (inputNames && this.processStartOptions) {
        const predefinedDataFields = this.processStartOptions.predefined_DataField_values.map((value) => {
          if (value.name === 'Visitor') {
            value.name = this.translations.find((e) => e.name === 'Visitor').value;
          } else if (value.name === 'Barge/Vessel') {
            value.name = this.translations.find((e) => e.name === 'Barge/Vessel').value;
          } else if (value.name === 'Vorname') {
            value.name = this.translations.find((e) => e.name === 'Vorname').value;
          } else if (value.name === 'Nachname') {
            value.name = this.translations.find((e) => e.name === 'Nachname').value;
          } else if (value.name === 'E-Mail Adresse') {
            value.name = this.translations.find((e) => e.name === 'E-Mail Adresse').value;
          } else if (value.name === 'Telefon') {
            value.name = this.translations.find((e) => e.name === 'Telefon').value;
          }

          return value;
        });
        const matches = predefinedDataFields.filter((item) => inputNames.indexOf(item.name) > -1);
        matches.forEach((item) => {
          const index = inputNames.indexOf(item.name);
          step.stepDefinition.type_data.inputs[index].value = item.value;
        });
      }
    }
  }
}
