import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from 'src/app/interfaces/user/user.interface';
import { EquipmentModel, EquipmentPerClass } from 'src/app/models/equipment.model';
import { EquipmentService } from 'src/app/services/equipment.service';

@Injectable({ providedIn: 'root' })
export class ShoppingService {
  private cartItems: EquipmentModel[] = [];
  private cartItemIds: string[] = [];

  constructor(private readonly equipmentService: EquipmentService) {
    // use getcartitems
    const storedItems = localStorage.getItem('cartItems');
    this.cartItems = storedItems ? JSON.parse(storedItems) : [];
    this.cartItemIds = this.cartItems.map((i) => i.supplierId);
  }

  setCartItemsAndIds(items: EquipmentModel[]) {
    this.cartItems = items;
    this.cartItemIds = items.map((i) => i.supplierId);
  }

  addToCart(item: EquipmentModel): Observable<EquipmentModel[]> {
    return this.equipmentService.addItemToShoppingCart(item.supplierId);
    // localStorage.setItem('cartItems', JSON.stringify(this.cartItems));
  }

  removeFromCart(item: EquipmentModel): Observable<EquipmentModel[]> {
    return this.equipmentService.removeItemFromShoppingCart(item.supplierId);
    // const index = this.cartItemIds.findIndex((e) => e === item.supplierId);
    // if (index > -1) {
    //   this.cartItems= ;
    //   this.cartItemIds.splice(index, 1);
    // }
    // localStorage.setItem('cartItems', JSON.stringify(this.cartItems));
  }

  getCartItems(): Observable<EquipmentModel[]> {
    return this.equipmentService.getShoppingCartItems();
  }

  getCartItemIds(): string[] {
    return this.cartItemIds;
  }

  clearCart() {
    // use endpoint
    // localStorage.removeItem('cartItems');
    return this.equipmentService.removeAllItemFromShoppingCart();
    // this.cartItems = [];
    // this.cartItemIds = [];
  }

  createOrderNumber(userId: number): string {
    const date = new Date();
    const year = date.getFullYear() % 100; // Use last two digits of the year
    const month = date.getMonth() + 1; // Month is zero-based
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    // Format each component and concatenate them
    const formattedDate = String(year).padStart(2, '0') + String(month).padStart(2, '0') + String(day).padStart(2, '0');

    const formattedTime =
      String(hours).padStart(2, '0') + String(minutes).padStart(2, '0') + String(seconds).padStart(2, '0');

    // Combine date, time, and user ID (if provided)
    const orderNumber = formattedDate + formattedTime + (userId ? String(userId).padStart(2, '0') : '');

    // Take the last 7 digits to ensure it fits the length requirement
    return orderNumber.slice(-7);
  }

  checkIfOrderable(item: EquipmentModel, equipmentClassId: number): boolean {
    const userClassData: EquipmentPerClass[] = JSON.parse(item.userClassData);
    const maximumUnits = userClassData.find((uc) => uc.userClassId === equipmentClassId)?.classData.maximumUnits || 0;

    if (item.notOrderable) {
      return false;
    }

    if (item.alreadyOwned >= maximumUnits && maximumUnits > 0) {
      return false;
    } else {
      return true;
    }
  }

  retrieveUserSpecificBaseItems(userData: User, allBaseItems: EquipmentModel[]) {
    const sizes = [`Größe ${userData.clothingSizeTop}`, `Schuhweite XB/Größe ${userData.shoeSize}`, ''];
    const overallSizes = [
      `Größe ${userData.clothingSizeBottom}R`,
      `Größe ${userData.clothingSizeBottom}S`,
      `Größe ${userData.clothingSizeBottom}L`
    ];

    const baseItems = allBaseItems.filter((item) => sizes.includes(item.additionalInformation));
    const overalls = allBaseItems.filter((item) => overallSizes.includes(item.additionalInformation));

    return { baseItems, overalls };
  }
}
