import { MtxDatetimepickerModule } from '@ng-matero/extensions/datetimepicker';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ViewContainerDirective } from './directives/view-container.directive';
import { Nl2brPipe } from '../pipes/nl2br.pipe';
import { TranslocoModule } from '@ngneat/transloco';
import { SearchSelectComponentModule } from '../components/search-select/search-select.component';
import { AssetOutlineComponent } from './process/asset-outline/asset-outline.component';
import { ProcessHandlerDialogComponent } from './process/process-handler-dialog/process-handler-dialog.component';
import { AbstractStepHandleComponent } from './process/process-handler/abstract-step-handle/abstract-step-handle.component';
import { DataFieldComponent } from './process/process-handler/data-field/data-field.component';
import { ProcessHandlerComponent } from './process/process-handler/process-handler.component';
import { StepHandleInputWatcherComponent } from './process/process-handler/step-handle-input-watcher/step-handle-input-watcher.component';
import { StepHandleManualInputComponent } from './process/process-handler/step-handle-manual-input/step-handle-manual-input.component';
import { StepHandleManualSelectionComponent } from './process/process-handler/step-handle-manual-selection/step-handle-manual-selection.component';
import { WarehouseOutlineComponent } from './process/warehouse-outline/warehouse-outline.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSliderModule } from '@angular/material/slider';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { ConvertDaysPipe } from '../pipes/convert-number-to-d-w-m.pipe';
import { UploadFormComponentModule } from '../components/upload-form/upload-form.component';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [
    ProcessHandlerComponent,
    StepHandleManualSelectionComponent,
    ProcessHandlerDialogComponent,
    AbstractStepHandleComponent,
    StepHandleManualInputComponent,
    StepHandleInputWatcherComponent,
    ViewContainerDirective,
    WarehouseOutlineComponent,
    AssetOutlineComponent
  ],
  imports: [
    CommonModule,
    DataFieldComponent,
    MatProgressBarModule,
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    MatSliderModule,
    MatRadioModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDatepickerModule,
    MatTabsModule,
    MatSelectModule,
    FormsModule,
    RouterModule,
    TranslocoModule,
    MtxDatetimepickerModule,
    SearchSelectComponentModule,
    Nl2brPipe,
    ConvertDaysPipe,
    UploadFormComponentModule
  ],
  exports: []
})
export class ProcessEngineModule {}
