import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Step } from 'src/app/interfaces/process/step.interface';

@Component({
  selector: 'app-abstract-step-handle',
  template: '',
  styles: []
})
export class AbstractStepHandleComponent {
  @Input() step: Step;
  @Output() stepActionHandled = new EventEmitter<any>();
  @Output() performBackAction = new EventEmitter<{ stepId: number; stepDefinitionId: number }>();
}
