import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router, UrlSerializer } from '@angular/router';
import {
  Observable,
  Observer,
  Subscription,
  OperatorFunction,
  Subscriber,
  TeardownLogic,
  Operator,
  Subject
} from 'rxjs';
import { config } from 'src/config';
import { CachingService } from './caching.service';
import { MemoryService } from './memory.service';
import { NotificationService } from './notification.service';
import { map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { MobileViewService } from './mobile-view.service';
import { Stripper } from '../models/stripper.model';
import { ErrorResponse } from '../interfaces/errors/error-response';
import { FormDataError } from '../interfaces/errors/form-data.error';
import { InsufficientRequirementError } from '../interfaces/errors/insufficient-requirement.error';
import { UserData, UserLogin } from '../interfaces/user/user.interface';

export interface IResponseError {
  message: string;
  type: string;
  errorData?: any;
}

export interface IResponseMeta {
  login_token: string;
  expire: string | Date;
}
export interface IResponseInterface<T> {
  state: boolean;
  data: T;
  error?: IResponseError;
  meta: IResponseMeta;
}

@Injectable({
  providedIn: 'root'
})
export class BackendService {
  protected static ERROR_HANDLER: (error: HttpErrorResponse) => void;
  protected static LOGIN_CHECK_PENDING = false;
  //toDo: do we need this?
  private static RESPONSE_META: IResponseMeta;

  private static STALLED_REQUESTS: {
    request: Observable<any>;
    observer: Observer<any>;
  }[] = [];

  showReloadButton$ = new Subject<boolean>();
  constructor(
    protected http: HttpClient,
    protected notification: NotificationService,
    protected memoryService: MemoryService,
    protected router: Router,
    protected serializer: UrlSerializer,
    protected cachingService: CachingService,
    protected datePipe: DatePipe,
    protected mobileService: MobileViewService
  ) {}

  static handleFormDataError(error: FormDataError, form: UntypedFormGroup): void {
    for (const field of error.formFields) {
      const formControl = form.get(field);
      if (formControl) {
        formControl.setErrors({
          serverError: error.message
        });
      }
    }
  }

  private static addStalledRequest(request: Observable<any>, observer: Observer<any>): void {
    this.STALLED_REQUESTS.push({
      request,
      observer
    });
  }

  private static removeStalledRequest(request: Observable<any>, observer: Observer<any>): void {
    this.STALLED_REQUESTS = this.STALLED_REQUESTS.filter(
      (data) => data.request !== request && data.observer !== observer
    );
  }

  private static loginCheckIsPending(): boolean {
    return this.LOGIN_CHECK_PENDING;
  }

  convertToModel(model: typeof Stripper): OperatorFunction<any, any> {
    return map((d: any) => {
      if (d.hasOwnProperty('rows')) {
        d.rows = d.rows.map((_d: any) => (model as any).create(_d));
        return d;
      }
      if (Array.isArray(d)) {
        d = d.map((_d: any) => (model as any).create(_d));
        return;
      }
      d = (model as any).create(d);
      return d;
    });
  }

  setErrorHandler(fn: (error: HttpErrorResponse) => void): void {
    BackendService.ERROR_HANDLER = fn;
  }

  setResponseMeta(responseMeta: IResponseMeta): void {
    BackendService.RESPONSE_META = responseMeta;
  }

  checkLogin(loginToken: string): void {
    BackendService.LOGIN_CHECK_PENDING = true;
    this._postRequest<HttpResponse<IResponseInterface<UserData>>>('user/checklogin', { loginToken }).subscribe(
      (login) => {
        const loginResponse = login.body;
        if ('state' in loginResponse && loginResponse.state) {
          this.setResponseMeta({
            login_token: loginResponse.data.userToken.loginToken,
            expire: loginResponse.data.userToken.expires_at
          });

          const userData: UserLogin = loginResponse.data.userLogin;
          userData.loginToken = loginResponse.data.userToken.loginToken;
          userData.loginTokenExpiresAt = loginResponse.data.userToken.expires_at;

          this.memoryService.setLoggedIn(userData);
        } else {
          this.memoryService.setLoggedIn(false, 'You are not logged in.');
          this.setResponseMeta({
            login_token: null,
            expire: null
          });
        }
        BackendService.LOGIN_CHECK_PENDING = false;
        for (const data of BackendService.STALLED_REQUESTS) {
          data.request.subscribe((datum: HttpResponse<any>) => {
            this.handleResponse(datum.body, data.observer);
          });
          BackendService.removeStalledRequest(data.request, data.observer);
        }
      },
      () => {
        this.memoryService.setLoggedIn(false, 'You are not logged in.');
        this.setResponseMeta({
          login_token: null,
          expire: null
        });
        BackendService.LOGIN_CHECK_PENDING = false;
      }
    );
  }

  getEnabledUiFeatures(): Observable<string[]> {
    return this.cachingService.createCachingSubscription('enabledUiFeatures', this.get(`uiFeatures`));
  }

  public get<T>(url: string, options?: any, direct = false): Observable<T> {
    const request = this._getRequest(url, options);
    if (BackendService.loginCheckIsPending() && !direct) {
      return new Observable((observer) => {
        BackendService.addStalledRequest(request, observer);
        return {
          unsubscribe: () => {
            BackendService.removeStalledRequest(request, observer);
          }
        };
      });
    }
    return new Observable((observer) => {
      const subscription = request.subscribe(
        (data: HttpResponse<T>) => {
          this.handleResponse(data.body as unknown as IResponseInterface<T>, observer);
        },
        (error) => {
          BackendService.ERROR_HANDLER(error);
        }
      );
      return {
        unsubscribe: () => {
          subscription.unsubscribe();
        }
      };
    });
  }

  public post<T>(url: string, body?: any, options?: any, direct = false): BackendObservable<T> {
    const request = this._postRequest(url, body, options);
    if (BackendService.loginCheckIsPending() && !direct) {
      return new BackendObservable<T>({ url, body, options }, (observer) => {
        BackendService.addStalledRequest(request, observer);
        return {
          unsubscribe: () => {
            BackendService.removeStalledRequest(request, observer);
          }
        };
      });
    }
    return new BackendObservable({ url, body, options }, (observer) => {
      const subscription = request.subscribe(
        (data: HttpResponse<T>) => {
          this.handleResponse(data.body as unknown as IResponseInterface<T>, observer);
        },
        (error) => {
          observer.error(error);
          BackendService.ERROR_HANDLER(error);
        }
      );

      return {
        unsubscribe: () => {
          subscription.unsubscribe();
        }
      };
    });
  }

  public _postRequest<T>(url: string, body?: any, options?: any): Observable<T> {
    return new Observable<T>((observer) => {
      options = options || {};
      options.headers = options.headers || {};
      if (!options.observe) {
        options.observe = 'response';
      }
      const userData = this.getParsed('userdata');
      if (userData) {
        options.headers['login-token'] = userData.loginToken;
      }
      const http = this.http.post<T>(config.apiUrl + url, body, options).subscribe(observer as any);
      return {
        getRequestData: () => {
          return {
            url,
            body,
            options
          };
        },
        unsubscribe: () => {
          http.unsubscribe();
        }
      };
    });
  }

  public _getRequest<T>(url: string, options?: any): Observable<T> {
    return new Observable<T>((observer) => {
      options = options || {};
      options.headers = options.headers || {};
      options.observe = 'response';
      const userData = this.getParsed('userdata');
      if (userData) {
        options.headers['login-token'] = userData.loginToken;
      }
      const http = this.http.get<T>(config.apiUrl + url, options).subscribe(observer as any);
      return {
        unsubscribe: () => {
          http.unsubscribe();
        }
      };
    });
  }

  public upload(url: string, body?: any): Observable<any> {
    return this._postRequest<any>(url, body, {
      reportProgress: true,
      observe: 'events'
    });
  }

  protected performDownload(route: string, params?: any): void {
    params = params || {};
    params.t = this.memoryService.getLoginToken();
    const queryString = this.router.createUrlTree(['/'], { queryParams: params });
    window.open(config.apiUrl + route + this.serializer.serialize(queryString).substr(1));
  }

  protected handleResponse(response: IResponseInterface<any>, observer: Observer<any>): void {
    if (response.meta) {
      BackendService.RESPONSE_META = response.meta;
    }
    if (response.state === false) {
      if (response.error.type === 'InsufficientCapabilityError') {
        this.notification.error(response.error.message);
        observer.complete();
        return;
      }
      if (response.error.type === 'NotAuthenticatedError') {
        observer.complete();
        this.memoryService.setLoggedIn(false, 'You are not logged in.');
        BackendService.RESPONSE_META = undefined;
        return;
      }
      if (response.error.type === 'FormDataError') {
        observer.error(new FormDataError(response.error));
      } else if (response.error.type === 'InsufficientRequirementError') {
        observer.error(new InsufficientRequirementError(response.error));
      } else {
        this.notification.error(`Error: ${response.error.message}`, 10);
        observer.error(new ErrorResponse(response.error));
      }
      return;
    }
    observer.next(response.data);
    observer.complete();
  }

  private getParsed(key: string): UserLogin {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (e) {
      localStorage.removeItem(key);
      console.error(e);
      return null;
    }
  }
}

export function subscriptionIsActive(subscription?: Subscription): boolean {
  if (subscription && !subscription.closed) {
    return true;
  }
  return false;
}

export interface RequestData {
  url: string;
  body?: any;
  options?: any;
}

export class BackendObservable<T = any> extends Observable<T> {
  private requestData: RequestData;

  constructor(
    requestData?: RequestData,
    subscribe?: (this: Observable<T>, subscriber: Subscriber<T>) => TeardownLogic
  ) {
    super(subscribe);
    this.requestData = requestData;
  }

  getRequestData(): RequestData {
    return this.requestData;
  }

  /**
   * Creates a new BackendObservable, with this Observable instance as the source, and the passed
   * operator defined as the new observable's operator.
   *
   * @method lift
   * @param operator the operator defining the operation to take on the observable
   * @return a new observable with the Operator applied
   * @deprecated This is an internal implementation detail, do not use directly. If you have implemented an operator
   * using `lift`, it is recommended that you create an operator by simply returning `new Observable()` directly.
   * See "Creating new operators from scratch" section here: https://rxjs.dev/guide/operators
   */
  lift<R>(operator?: Operator<T, R>): BackendObservable<R> {
    const observable = new BackendObservable<R>();
    observable.source = this;
    observable.operator = operator;
    observable.requestData = this.requestData;
    return observable;
  }
}
