<div class="overlay-container">
  <div *ngIf="shouldShowOverlay()" class="overlay">
    <ng-content></ng-content>
  </div>
  <mat-action-list *ngIf="step.stepDefinition.type_data.choices.length > 2">
    <mat-list-item
      *ngFor="let choice of step.stepDefinition.type_data.choices; let index = index"
      (click)="stepActionHandled.emit({ choice: choice.title })"
    >
      <mat-icon style="color: var(--primary-color)" matListItemIcon>label_important</mat-icon> {{ choice.title }}
    </mat-list-item>
  </mat-action-list>

  <ng-container *ngIf="itemsInCart && step.step_definition_id !== 324">
    <ng-container *transloco="let t">
      <mat-card appearance="outlined">
        <mat-card-content>
          <div *ngFor="let item of itemsInCart" class="detailed-panel width-100 margin-xxs-bottom radius-4px">
            <div class="flex column no-gap gap-xs space-between">
              <span class="detailed-header width-100">
                {{ item.equipmentName }}
                <div class="flex space-between align-center">
                  <span class="mat-expansion-panel-header-description">
                    <ng-container *ngIf="item.additionalInformation !== ''">
                      {{ item.additionalInformation }}
                      <br />
                    </ng-container>
                    {{ item.packingUnit }}
                    <br />
                    {{ item.deliveryTime | convertDays }}
                  </span>
                  <img class="circle" [src]="item.path" />
                </div>
              </span>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </ng-container>
  </ng-container>
</div>
