import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BackendObservable, BackendService } from './backend.service';
import { ISequelizeCount } from '../models/sequelize-count.interface';
import { SiteModel } from '../models/site.model';

@Injectable({
  providedIn: 'root'
})
export class SiteService extends BackendService {
  activated: boolean;

  getSite(siteId: number): Observable<SiteModel> {
    return this.get<SiteModel>(`${siteId}/get`);
  }

  editSite(siteId: number, mainAssetId?: number, editingOptions?: ISiteEditingOptions): Observable<SiteModel> {
    return this.post<SiteModel>(`${siteId}/edit`, { data: { mainAssetId }, editingOptions });
  }

  createSite(name: string, mainAssetId: number): Observable<SiteModel> {
    return this.post<SiteModel>(`create`, { data: { mainAssetId, name } });
  }

  getAllSites(
    criteria?: any,
    limit?: number,
    offset?: number,
    options?: IGetAllSitesOptions
  ): Observable<ISequelizeCount<SiteModel>> {
    if (!criteria && !limit && !offset && !options) {
      return this.cachingService.createCachingSubscription(
        'getAllSites',
        this.post<ISequelizeCount<SiteModel>>(`all/get`, {})
      );
    }
    return this.post<ISequelizeCount<SiteModel>>(`all/get`, { criteria, limit, offset, options });
  }

  editSiteUser(siteId: number, userId: number, presentFrom: Date, presentTo: Date): Observable<any> {
    return this.post<any>(`${siteId}/editSiteUser`, {
      userId,
      presentFrom,
      presentTo
    });
  }

  post<T>(url: string, body: any = {}, options?: any): BackendObservable<T> {
    return super.post<T>(`site/${url}`, body, options);
  }

  get<T>(url: string, options?: any): Observable<T> {
    return super.get<T>(`site/${url}`, options);
  }
}

export interface ISiteEditingOptions {
  retainCertificates: boolean;
}

export interface IGetAllSitesOptions {
  includeMainAsset?: boolean;
  includeWarehouses?: boolean;
  includeMainAssetAndCertificates?: boolean;
}
