import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { AssetService } from '../services/asset.service';

@Pipe({
  name: 'idToVessel',
  standalone: true
})
export class IdToVesselNamePipe implements PipeTransform {
  constructor(private assetService: AssetService) {}

  transform(vesselId: any, ...args: unknown[]): Observable<string> {
    return new Observable<string>((observer) => {
      if (Number(vesselId)) {
        this.assetService.getAllVesselsCached().subscribe(
          (assets) => {
            const asset = assets.rows.find((u) => u.assetId === Number(vesselId));
            if (asset) {
              observer.next(`${asset.name}`);
            } else {
              observer.next('-');
            }
            observer.complete();
          },
          (error) => observer.error(error)
        );
      } else {
        if (!vesselId) vesselId = '-';
        observer.next(vesselId);
      }
    });
  }
}
