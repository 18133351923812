<ng-container *transloco="let t">
  <div *ngIf="step">
    <ng-container *ngIf="noTabs; else withTabs">
      <ng-container *ngIf="step.step_definition_id === 247">
        <span class="mat-headline-6 no-margin">{{ t('views.psa.address') }}</span>
      </ng-container>
      <div [ngClass]="{ 'grid two-columns gap-xxxs': suggestedDataFields && suggestedDataFields.length > 1 }">
        <div *ngFor="let dataField of suggestedDataFields || []">
          <app-data-field [noDate]="true" [dataField]="dataField"></app-data-field>
        </div>
      </div>
      <hr />
      <div class="flex align-center space-between">
        <span class="mat-headline-6 no-margin">{{ step.title }}</span>
        <button *ngIf="step.stepDefinition.type_data.components" mat-icon-button (click)="showDetails = !showDetails">
          <mat-icon class="material-symbols-outlined">info</mat-icon>
        </button>
        <button mat-icon-button *ngIf="step.stepDefinition.description" (click)="showDescription = !showDescription">
          <mat-icon class="material-symbols-outlined">help</mat-icon>
        </button>
      </div>
      <p *ngIf="showDetails">
        <small>Zugehörige Komponenten:</small>
        <br />
        <ng-container *ngFor="let component of components">
          <small>{{ component }}</small>
          <br />
        </ng-container>
      </p>

      <ng-container *ngIf="showDescription">
        <small>{{ step.stepDefinition.description }}</small>
        <hr />
      </ng-container>
      <app-step-handle-manual-selection
        *ngIf="step.stepDefinition?.type === 1"
        [step]="step"
        (stepActionHandled)="onActionHandled($event)"
        (performBackAction)="onBackAction($event)"
      >
        <div
          #overlayContent
          *ngIf="subscriptionIsActive(subscriptions.performStep)"
          class="height-100 flex column justify-center"
        >
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          <div style="text-align: center" class=""><br />{{ t('general.processing') }}</div>
        </div>
      </app-step-handle-manual-selection>
      <app-step-handle-manual-input
        *ngIf="step.stepDefinition?.type === 2"
        [step]="step"
        (stepActionHandled)="onActionHandled($event)"
        (performBackAction)="onBackAction($event)"
      >
        <div
          #overlayContent
          *ngIf="subscriptionIsActive(subscriptions.performStep)"
          class="height-100 flex column justify-center"
        >
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          <div style="text-align: center" class=""><br />{{ t('general.processing') }}</div>
        </div>
      </app-step-handle-manual-input>
      <app-step-handle-input-watcher
        *ngIf="step.stepDefinition?.type === 4"
        [step]="step"
        (stepActionHandled)="onActionHandled($event)"
        (stepPerformed)="onStepPerformed($event)"
      ></app-step-handle-input-watcher>
    </ng-container>

    <ng-template #withTabs>
      <mat-tab-group dynamicHeight>
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="margin-xxs-right">done_outline</mat-icon>
            Aufgabe
          </ng-template>
          <div class="datafield-grid">
            <div class="space-xs-top" *ngFor="let dataField of suggestedDataFields || []">
              <app-data-field [dataField]="dataField"></app-data-field>
            </div>
          </div>
          <hr />
          <div class="flex align-center space-between">
            <h3>{{ step.title }}</h3>
            <button
              *ngIf="step.stepDefinition.type_data.components"
              mat-icon-button
              (click)="showDetails = !showDetails"
            >
              <mat-icon class="material-symbols-outlined">info</mat-icon>
            </button>
          </div>
          <p *ngIf="showDetails">
            <small>Zugehörige Komponenten:</small>
            <br />
            <ng-container *ngFor="let component of components">
              <small>{{ component }}</small>
              <br />
            </ng-container>
          </p>
          <p [innerHTML]="step.stepDefinition.description | nl2br"></p>
          <hr />
          <ng-container *ngIf="!subscriptionIsActive(subscriptions.performStep)">
            <app-step-handle-manual-selection
              *ngIf="step.stepDefinition?.type === 1"
              [step]="step"
              (stepActionHandled)="onActionHandled($event)"
            ></app-step-handle-manual-selection>
            <app-step-handle-manual-input
              *ngIf="step.stepDefinition?.type === 2"
              [step]="step"
              (stepActionHandled)="onActionHandled($event)"
            ></app-step-handle-manual-input>
            <app-step-handle-input-watcher
              *ngIf="step.stepDefinition?.type === 4"
              [step]="step"
              (stepActionHandled)="onActionHandled($event)"
              (stepPerformed)="onStepPerformed($event)"
            ></app-step-handle-input-watcher>
          </ng-container>
          <ng-container *ngIf="subscriptionIsActive(subscriptions.performStep)">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            <div style="text-align: center"><br />wird geladen...</div>
          </ng-container>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="margin-xxs-right">storage</mat-icon>
            Datenfelder
          </ng-template>
          <app-data-field *ngFor="let dataField of data?.DataFields || []" [dataField]="dataField"></app-data-field>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="margin-xxs-right">history</mat-icon>
            Historie
          </ng-template>
          <br />
          <div *ngFor="let step of history || []" class="mb-05">
            <b>
              <div class="flex align-center"><mat-icon class="mat-icon-valign">check</mat-icon> {{ step.title }}</div>
            </b>
            <br />
            {{ step.process.title }}<br />
            <div class="text-right">
              <small class="text-muted">{{ step.executed | date }}</small>
            </div>
            <hr />
          </div>
        </mat-tab>
      </mat-tab-group>
    </ng-template>
  </div>
</ng-container>
