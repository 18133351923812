import 'reflect-metadata';
import { plainToClass, plainToClassFromExist, Expose, Type, classToPlain } from 'class-transformer';

export abstract class Stripper {
  @Type(() => Date)
  @Expose()
  createdAt?: Date | string;
  @Type(() => Date)
  @Expose()
  updatedAt?: Date | string;
  @Type(() => Date)
  @Expose()
  deletedAt?: Date | string;

  static create<T extends Stripper>(this: new () => T, data: Partial<T>): T {
    return plainToClass(this, data, { excludeExtraneousValues: true });
  }

  static convert<T extends Stripper>(this: new () => T, data: Partial<T>): T {
    return plainToClass(this, data, { excludeExtraneousValues: false });
  }

  update(data: any): void {
    plainToClassFromExist(this, data);
  }

  toJSON(): any {
    return classToPlain(this);
  }
}
