import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslocoModule } from '@ngneat/transloco';
import { WebcamImage, WebcamModule } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';

@Component({
  selector: 'app-confirm',
  templateUrl: './camera-dialog.component.html',
  styleUrls: ['./camera-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    TranslocoModule,
    WebcamModule,
    MatIconModule,
    MatProgressBarModule
  ]
})
export class CameraDialogComponent implements AfterViewInit {
  webcamImage: any = null;
  width: number;
  private trigger: Subject<void> = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<CameraDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { image: string }
  ) {
    if (data.image) {
      this.webcamImage = data.image;
    }
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }
  ngAfterViewInit() {
    this.width = window.screen.availWidth;
  }

  onClick(val: boolean): void {
    this.dialogRef.close(val);
  }

  triggerSnapshot(): void {
    this.trigger.next();
  }

  handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage.imageAsDataUrl;
  }

  onAccept() {
    this.dialogRef.close(this.webcamImage);
  }
}
