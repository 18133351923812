import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-asset-condition-indicator',
  templateUrl: './asset-condition-indicator.component.html',
  styleUrls: ['./asset-condition-indicator.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule, MatTooltipModule]
})
export class AssetConditionIndicatorComponent {
  @Input() condition: number;
  @Input() date: string;

  constructor(private readonly helper: HelperService) {}

  static getConditionColor(condition: number): string {
    switch (condition) {
      case 0:
        return getComputedStyle(document.documentElement).getPropertyValue('--table-no-status');
      case 1:
        return getComputedStyle(document.documentElement).getPropertyValue('--table-success');
      case 3:
        return getComputedStyle(document.documentElement).getPropertyValue('--table-warn');
      case 5:
        return getComputedStyle(document.documentElement).getPropertyValue('--table-error');
    }
  }

  static getStatus(condition: number): string {
    switch (condition) {
      case 5:
        return 'kritisch';
      case 3:
        return 'unkritisch';
      case 1:
        return 'mängelfrei';
      default:
        return 'no status';
    }
  }

  evaluate(condition?: number): string {
    if (!condition) {
      condition = this.helper.calculateCondition(this.date);
    }
    switch (condition) {
      case 1:
        if (this.date) return 'aktuell';
        return 'mängelfrei';
      case 3:
        if (this.date) return 'älter 2h und neuer 12h';
        return 'unkritisch';
      case 5:
        if (this.date) return 'älter 12h';
        return 'kritisch';
    }
  }

  conditionColor(condition?: number): string {
    if (!condition) {
      condition = this.helper.calculateCondition(this.date);
    }

    return AssetConditionIndicatorComponent.getConditionColor(condition);
  }

  conditionStatus(condition: number): string {
    return AssetConditionIndicatorComponent.getStatus(condition);
  }
}
