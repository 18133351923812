export const COLOR_CODES: string[] = [
  '#686360',
  '#C7DB51',
  '#C57C9B',
  '#E74935',
  '#ffd700',
  '#97CFDF',
  '#DB6C37',
  '#D7A2D9',
  '#74EB1B',
  '#949a2b',
  '#a6cee3',
  '#1f78b4',
  '#b2df8a',
  '#33a02c',
  '#fb9a99',
  '#e31a1c',
  '#fdbf6f',
  '#ff7f00',
  '#cab2d6',
  '#6a3d9a',
  '#02e2de',
  '#b15928'
];

export interface JacketSize {
  size: string;
  taille: string;
  chest: string;
  height: string;
  hip?: string;
}
export const JACKET_SIZE_MEN = [
  { size: 'XS', taille: '66-74', chest: '78-86', height: '164-172' },
  { size: 'S', taille: '74-82', chest: '86-94', height: '164-172' },
  { size: 'M', taille: '82-90', chest: '94-102', height: '172-180' },
  { size: 'L', taille: '90-106', chest: '102-110', height: '172-180' },
  { size: 'XL', taille: '98-106', chest: '110-118', height: '180-188' },
  { size: '2XL', taille: '106-117', chest: '118-129', height: '180-188' },
  { size: '3XL', taille: '117-129', chest: '129-141', height: '188-196' },
  { size: '4XL', taille: '129-141', chest: '141-153', height: '188-196' }
];

export const JACKET_SIZE_WOMEN = [
  { size: '2XS', taille: '50-58', chest: '66-74', height: '156-164', hip: '74-82' },
  { size: 'XS', taille: '58-66', chest: '74-82', height: '156-164', hip: '82-90' },
  { size: 'S', taille: '66-74', chest: '82-90', height: '156-164', hip: '90-98' },
  { size: 'M', taille: '74-82', chest: '90-98', height: '164-172', hip: '98-106' },
  { size: 'L', taille: '82-91', chest: '98-107', height: '164-172', hip: '106-115' },
  { size: 'XL', taille: '91-103', chest: '107-119', height: '172-180', hip: '115-125' },
  { size: '2XL', taille: '103-115', chest: '119-131', height: '172-180', hip: '125-135' },
  { size: '3XL', taille: '115-127', chest: '131-143', height: '180-188', hip: '135-145' },
  { size: '4XL', taille: '127-139', chest: '143-155', height: '180-188', hip: '145-155' }
];

export const MULTINORM_MEN = [
  { chest: '86-90', height: '172-176', size: '44' },
  { chest: '90-94', height: '174-178', size: '46' },
  { chest: '94-98', height: '176-180', size: '48' },
  { chest: '98-102', height: '172-182', size: '50' },
  { chest: '102-106', height: '180-184', size: '52' },
  { chest: '106-110', height: '182-186', size: '54' },
  { chest: '110-114', height: '184-188', size: '56' },
  { chest: '114-118', height: '186-190', size: '58' },
  { chest: '118-123', height: '188-192', size: '60' },
  { chest: '123-129', height: '190-194', size: '62' },
  { chest: '129-135', height: '192-196', size: '64' }
];

export interface CombinedSizes {
  bodywear: string;
  eur: string;
}

// multinormWomen
