<mat-progress-bar mode="indeterminate"></mat-progress-bar>
<br />
<span *ngIf="step.deadline"
  >Wartet
  <!-- <span *ngIf="(step.deadline | amFromUtc | amLocal | amDifference : now : 'seconds') < 30"
    >{{ step.deadline | amFromUtc | amLocal | amDifference : now : 'seconds' }} Sekunden</span
  >
  <span *ngIf="(step.deadline | amFromUtc | amLocal | amDifference : now : 'seconds') >= 30">{{
    step.deadline | amFromUtc | amLocal | amDifference : now : 'seconds' | amDuration : 'seconds'
  }}</span> -->
</span>
