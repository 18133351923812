<h1 mat-dialog-title>JustTo.Do</h1>
<mat-dialog-content>
  @if (environment.includes('gefo')) {
    <iframe src="https://justto.do/apov-dryr" style="min-height: 800px; width: 100%"></iframe>
  } @else if (environment.includes('deymann')) {
    <iframe src="https://justto.do/xwnv-cnpo" style="min-height: 800px; width: 100%"></iframe>
  }
</mat-dialog-content>
<mat-dialog-actions class="flex space-between">
  @if (environment.includes('gefo')) {
    <a mat-button href="https://justto.do/apov-dryr" target="_blank">Open in new Tab</a>
  } @else if (environment.includes('deymann')) {
    <a mat-button href="https://justto.do/xwnv-cnpo" target="_blank">Open in new Tab</a>
  }
  <button mat-raised-button color="primary" [mat-dialog-close]="true">Schließen</button>
</mat-dialog-actions>
