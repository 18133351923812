import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { MemoryService } from 'src/app/services/memory.service';
import { environment } from 'src/environments/environment';
import { FilterListMenuOutletComponent } from './filter-list-menu-outlet/filter-list-menu-outlet.component';
import { StaticPageModel } from 'src/app/interfaces/static-pages/static-page.model';
import { ObjectKeysPipeModule } from 'src/app/pipes/object-keys.pipe';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatExpansionModule,
    MatListModule,
    MatIconModule,
    RouterModule,
    ObjectKeysPipeModule,
    FilterListMenuOutletComponent,
    TranslocoModule
  ]
})
export class NavigationComponent implements OnInit {
  @Input() staticPageMenu: { [s: string]: StaticPageModel[] } = {};
  @Output() itemSelected = new EventEmitter();
  @Output() handleStaticPageAction = new EventEmitter<StaticPageModel>();

  currentDate = new Date();
  title = environment.appTitle;
  version = localStorage.getItem('app_version');
  roleId: number;
  constructor(private readonly memoryService: MemoryService) {}

  ngOnInit() {
    this.roleId = this.memoryService.getLoginData().roleId;
  }
}
