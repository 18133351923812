import { IResponseError } from 'src/app/services/backend.service';

export class ErrorResponse {
  type: string;
  message: string;

  constructor(errorResponseData: IResponseError) {
    this.message = errorResponseData.message;
    this.type = errorResponseData.type;
    this.addSpecificErrorAttributes(errorResponseData);
  }

  addSpecificErrorAttributes(errorResponseData: any) {
    for (const attribute in errorResponseData.errorData || []) {
      if (errorResponseData.errorData[attribute]) {
        this[attribute] = errorResponseData.errorData[attribute];
      }
    }
  }
}
