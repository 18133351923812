import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Translation } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { BackendObservable, BackendService } from './backend.service';
import { MemoryService } from './memory.service';
import { TranslationKeeperService } from './translation-keeper.service';

@Injectable({ providedIn: 'root' })
export class TranslationBackendService extends BackendService {
  constructor(
    protected http: HttpClient,
    protected memoryService: MemoryService,
    private translationKeeper: TranslationKeeperService
  ) {
    super(http, null, memoryService, null, null, null, null, null);
  }

  /**
   * Receive a translation for a specific key for PE translations. This is usefull if there need to be a predefined_datafield_value in the start step of a process.
   *
   * Therefore we need to translate the name(s) before providing it to the processhandler. See smv.component for example.
   *
   * @param key
   * @param type
   * @param lang
   * @returns
   */
  getPETranslationByKey(key: string, type: string, lang: string) {
    return this.post<string>('getTranslationPEbyKey', { key, type, lang });
  }

  getAllFETranslations() {
    // get Translations
    this.http.get<Translation>(`/assets/i18n/de.json`).subscribe((data) => {
      this.post('uploadDETranslationFile', { data }).subscribe((e) =>
        this.get<{ language: string; data: { [key: string]: string } }[]>('getTranslationFiles').subscribe((all) => {
          const obj = {};
          all.forEach((element) => {
            obj[element.language] = element.data;
          });
          this.translationKeeper.setTranslations(obj);
          this.memoryService.setLanguage();
        })
      );
    });
  }

  translateAutomatically() {
    return this.post('translateAutomatically');
  }

  post<T>(url: string, body: any = {}, options?: any): BackendObservable<T> {
    return super.post<T>(`translate/${url}`, body, options);
  }

  get<T>(url: string, options?: any): Observable<T> {
    return super.get<T>(`translate/${url}`, options);
  }
}
