import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { ExpansionTableComponent } from 'src/app/components/expansion-table/expansion-table.component';
import { subscriptionIsActive } from '../../services/backend.service';
import { ProcessService } from '../../services/process.service';
import { IdToNamePipe } from 'src/app/pipes/id-to-name.pipe';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { Criteria } from 'src/app/interfaces/criteria';
import { Step } from 'src/app/interfaces/process/step.interface';
import { RoleNamePipeModule } from 'src/app/pipes/role-name.pipe';
import { ProcessHandlerService } from 'src/app/process-engine/process/process-handler-dialog/process-handler.service';
import {
  ProcessHandlerDialogComponent,
  AllStepsPerformedEvent
} from 'src/app/process-engine/process/process-handler-dialog/process-handler-dialog.component';

@Component({
  selector: 'app-process-list',
  templateUrl: './process-list.component.html',
  styleUrls: ['./process-list.component.scss']
})
export class ProcessListComponent implements OnInit {
  @Input() subscription: Subscription;
  @Input() steps: Step[] = [];
  @Input() filter: { [s: string]: any } = {};
  @Input() visibleColumns: string[] = [];
  @Input() showInputFilter = true;
  @Output() stepPerformed = new EventEmitter();
  @Output() filterChanged = new EventEmitter<{ [s: string]: any }>();
  @Output() deleteEvent = new EventEmitter<{
    event: { processId: number; title: string; vessel: string; date: Date };
  }>();

  subscriptionIsActive = subscriptionIsActive;

  private inputFilterTimeout: number;

  constructor(
    private readonly processService: ProcessService,
    private readonly dialog: MatDialog,
    private readonly route: ActivatedRoute,
    private readonly processHandlerService: ProcessHandlerService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((p) => {
      if (p.openProcessHandler && p.processId) {
        this.processService.getStep(Number(p.openProcessHandler), Number(p.processId)).subscribe((_step) => {
          this.openProcessHandlerDialog(_step);
        });
      }
    });
  }

  openStep(step: Step): void {
    this.processService.getStep(step.id, step.process_id).subscribe((_step) => {
      this.openProcessHandlerDialog(_step);
    });
  }

  inputFilterChanged(attribute: string): void {
    clearTimeout(this.inputFilterTimeout);

    this.inputFilterTimeout = window.setTimeout(() => {
      this.filterChanged.emit(
        new Criteria(this.filter)
          .clearEmptyStrings()
          .toLike(['title', '$process.title$', '$process.phase$'], true)
          .toLike(['$process.id_code_1$', '$process.id_code_2$'])
          .toJSON()
      );
    }, 500);
  }

  // check if tabs are neccessary for specific process phase
  private openProcessHandlerDialog(step: Step): void {
    const dimensions = this.processHandlerService.getDialogScreenSize();
    const noTabs = true;
    const dialogRef = this.dialog.open(ProcessHandlerDialogComponent, {
      ...dimensions,
      data: {
        steps: [step],
        processStartOptions: {},
        noTabs
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.stepPerformed.emit(result);
      window.setTimeout(() => {}, 500);
      if (result instanceof AllStepsPerformedEvent) {
        this.stepPerformed.emit({ allPerformed: true });
      }
    });
  }
}

@NgModule({
  declarations: [ProcessListComponent],
  exports: [ProcessListComponent],
  imports: [
    CommonModule,
    MatTableModule,
    TranslocoModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatProgressBarModule,
    RoleNamePipeModule,
    ExpansionTableComponent,
    IdToNamePipe
  ]
})
export class ProcessListComponentModule {}
