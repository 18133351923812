<div *transloco="let t">
  <div *ngFor="let item of items">
    <button
      mat-list-item
      [queryParams]="item.getParamsAsQueryParams()"
      routerLink="{{ for }}"
      (click)="itemSelected.emit()"
    >
      <mat-icon matListItemIcon class="material-symbols-outlined margin-xxs-right">format_list_numbered</mat-icon>
      {{ t(item.title) }}
    </button>
  </div>
</div>
