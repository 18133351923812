import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class MobileViewService {
  // see: https://medium.com/@rchr/detecting-mobile-browsers-with-one-line-of-javascript-109713d5869c
  detectMobileDevice(): boolean {
    return Math.min(window.screen.width, window.screen.height) < 768 || navigator.userAgent.indexOf('Mobi') > -1;
  }

  detectIfPWA(): boolean {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      return true;
    }
  }
}
