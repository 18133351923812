import { Component, Input, OnInit } from '@angular/core';
import { Warehouse } from 'src/app/interfaces/assets/warehouse.interface';

@Component({
  selector: 'app-warehouse-outline',
  templateUrl: './warehouse-outline.component.html',
  styles: []
})
export class WarehouseOutlineComponent implements OnInit {
  @Input() warehouse: Warehouse;

  constructor() {}

  ngOnInit(): void {}
}
