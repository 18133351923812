import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { GoogleMap, GoogleMapsModule } from '@angular/google-maps';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslocoModule } from '@ngneat/transloco';
import { GoogleMapService } from 'src/app/services/map-google.service';

@Component({
  selector: 'app-select-on-map',
  templateUrl: './select-on-map-dialog.component.html',
  standalone: true,
  imports: [CommonModule, GoogleMapsModule, MatDialogModule, MatButtonModule, TranslocoModule]
})
export class SelectOnMapDialogComponent implements AfterViewInit {
  @ViewChild(GoogleMap) googleMap: GoogleMap;

  height = '500px';
  width = 'auto';
  googleStyles: google.maps.MapTypeStyle[] = this.googleMapService.getStyles();
  mapOptions: google.maps.MapOptions = {
    center: { lat: 51.425549683716284, lng: 6.726613588029605 },
    zoom: 11,
    fullscreenControl: false,
    mapTypeControl: true,
    mapTypeControlOptions: { position: 2, mapTypeIds: ['custom', 'roadmap', 'hybrid'] },
    streetViewControl: false
  };

  clickLabel: string;
  clickPosition: google.maps.LatLngLiteral;

  constructor(
    public dialogRef: MatDialogRef<SelectOnMapDialogComponent>,
    private readonly googleMapService: GoogleMapService,
    @Inject(MAT_DIALOG_DATA)
    public data: { position: { lat: number; lng: number }; readonly: boolean }
  ) {
    if (data.position) {
      this.mapOptions.center = data.position;
      this.clickPosition = data.position;
    }
  }

  ngAfterViewInit(): void {
    this.googleMap.mapInitialized.subscribe((r) => {
      const styledMapType = new google.maps.StyledMapType(this.googleStyles, { name: 'Shipping' });
      this.googleMap.mapTypes.set('custom', styledMapType);
    });
  }

  onclick(event: google.maps.MapMouseEvent) {
    if (!this.data.readonly) {
      const latlng = JSON.parse(JSON.stringify(event.latLng.toJSON(), null, 2));
      this.clickPosition = { lat: latlng.lat, lng: latlng.lng };
    }
    // this.geocode(`${latlng.lat},${latlng.lng}`).then((address) => {
    //   this.clickLabel = address.results[0]?.formatted_address || `lat: ${latlng.lat} lng: ${latlng.lng}`;
    // });
  }

  onConfirm() {
    this.dialogRef.close({ mapPosition: this.clickPosition });
  }

  //   private async geocode(position: string): Promise<any> {
  //     return this.http
  //       .get(
  //         `https://maps.googleapis.com/maps/api/geocode/json?key=${environment.googleMapAPIgeo}&latlng=${position}&result_type=postal_code`
  //       )
  //       .toPromise();
  //   }
}
