<ng-container *transloco="let t">
  <div class="flex wrap overlay-container space-between">
    <div *ngIf="shouldShowOverlay()" class="overlay">
      <ng-content></ng-content>
    </div>
    <p
      class="min-width-45"
      [ngClass]="{
        'width-48': input.type === inputTypes.TEXT_READONLY && !isMobile,
        'width-100': input.type !== inputTypes.TEXT_READONLY || (input.type === inputTypes.TEXT_READONLY && isMobile)
      }"
      *ngFor="let input of step.stepDefinition.type_data.inputs; let index = index"
    >
      <ng-container
        *ngIf="
          ![inputTypes.FILE, inputTypes.YES_NO, inputTypes.TEXT_READONLY].includes(input.type) &&
          !['select', 'radio', 'slider', 'checkboxArray', 'hidden', 'manual', 'onmap'].includes(
            input.inputDefinitionOfStep?.options?.suggestedInputMethod
          )
        "
      >
        <!-- normal single line input -->
        <ng-container *ngIf="input.type !== inputTypes.TEXT && input.type !== inputTypes.DATE">
          <div class="flex">
            <mat-form-field class="auto-width" *ngIf="input.type !== inputTypes.TEXT && input.type !== inputTypes.DATE">
              <mat-label>{{ input.name }}</mat-label>
              <input
                [autofocus]="false"
                matInput
                type="text"
                [(ngModel)]="input.value"
                [disabled]="input.inputDefinitionOfStep?.options?.suggestedInputMethod === 'info'"
                #inputField="ngModel"
                [required]="input.required"
              />
              <!-- (input)="checkForbiddenSubstrings($event.target.value, input)" -->
              <mat-error *ngIf="inputField.touched && inputField.invalid">
                {{ t('general.required_field') }}
              </mat-error>
            </mat-form-field>
            <mat-form-field
              class="auto-width"
              *ngIf="input.inputDefinitionOfStep?.options?.suggestedInputMethod === 'street'"
            >
              <mat-label>Nr.</mat-label>
              <input
                [autofocus]="false"
                matInput
                type="text"
                [(ngModel)]="streetNumber"
                #inputField="ngModel"
                [required]="input.required"
              />
              <mat-error *ngIf="inputField.touched && inputField.invalid">
                {{ t('general.required_field') }}
              </mat-error>
            </mat-form-field>
          </div>
        </ng-container>

        <ng-container *ngIf="input.type === inputTypes.DATE">
          <!-- DateTime -->
          <div class="flex align-center">
            <button
              *ngIf="[6, 16].includes(step.process.process_definition_id)"
              mat-raised-button
              color="primary"
              (click)="setNow(input)"
            >
              {{ t('modules.calendar.now') }}
            </button>
            <mat-form-field
              *ngIf="input.inputDefinitionOfStep?.options?.suggestedInputMethod === 'date-time'; else date"
            >
              <mat-label>{{ input.name }}</mat-label>
              <mtx-datetimepicker #datepicker type="datetime" mode="auto" [timeInput]="true"> </mtx-datetimepicker>
              <input
                matInput
                [mtxDatetimepicker]="datepicker"
                (click)="datepicker.open()"
                [max]="maxDate"
                [(ngModel)]="input.value"
                [required]="input.required"
              />
              <mtx-datetimepicker-toggle matSuffix [for]="datepicker"></mtx-datetimepicker-toggle>
            </mat-form-field>
          </div>
          <!-- Date -->
          <ng-template #date>
            <mat-form-field>
              <mat-label>{{ input.name }} </mat-label>
              <input
                matInput
                [mtxDatetimepicker]="datepicker"
                (click)="datepicker.open()"
                [(ngModel)]="input.value"
                [required]="input.required"
              />
              <mtx-datetimepicker #datepicker type="date" mode="auto"></mtx-datetimepicker>
              <mtx-datetimepicker-toggle matSuffix [for]="datepicker"></mtx-datetimepicker-toggle>
            </mat-form-field>
          </ng-template>
        </ng-container>

        <!-- textarea input-->
        <mat-form-field *ngIf="input.type === inputTypes.TEXT">
          <mat-label>{{ input.name }} </mat-label>
          <textarea
            style="height: var(--space-xxl)"
            [autofocus]="false"
            matInput
            autocomplete="off"
            [(ngModel)]="input.value"
          ></textarea>
        </mat-form-field>
      </ng-container>
      <!-- select -->
      <ng-container
        *ngIf="
          ['select', 'manual'].includes(input.inputDefinitionOfStep?.options?.suggestedInputMethod) &&
          ![inputTypes.YES_NO].includes(input.type)
        "
      >
        <div class="flex align-center">
          <app-search-select
            *ngIf="input._selectOptions?.length"
            [disabled]="input.inputDefinitionOfStep?.options?.suggestedInputMethod === 'info'"
            [subscriptSizing]="'fixed'"
            valueAttribute="value"
            filterAttribute="label"
            class="flex-width"
            placeholder="{{ input.name }}"
            [multiple]="input.type === inputTypes.ARRAY_STRING"
            [(selectedItem)]="input.value"
            [options]="input._selectOptions"
            [optionsTemplate]="optionTemplate"
            [required]="input.required"
          ></app-search-select>
          <ng-template let-option #optionTemplate>{{ option.label || option.text }}</ng-template>
          <ng-container *ngIf="input.inputDefinitionOfStep?.options?.suggestedInputMethod.includes('manual')">
            <p>{{ t('filter.operator.or') }}</p>
            <mat-form-field class="flex-width">
              <mat-label>{{ t('process.select_manually') }}</mat-label>
              <input matInput type="text" [(ngModel)]="input.value" />
            </mat-form-field>
          </ng-container>
        </div>
      </ng-container>
      <!-- Slider for 0 to 6 condition -->
      <ng-container *ngIf="'slider' === input.inputDefinitionOfStep?.options?.suggestedInputMethod">
        <h4 class="space-md-bottom" style="font-weight: normal">
          {{ input.name }} <small *ngIf="input.required" class="text-warn">*</small>
        </h4>
        <!-- TODO: The 'tickInterval' property no longer exists -->
        <mat-slider color="accent" style="width: 80%" showTickMarks [max]="6" [min]="0" [step]="1" discrete>
          <input matSliderThumb [(ngModel)]="input.value" />
          <!-- <input matSliderEndThumb /> -->
        </mat-slider>
      </ng-container>
      <!-- radio -->
      <ng-container *ngIf="'radio' === input.inputDefinitionOfStep?.options?.suggestedInputMethod">
        <h4 class="space-md-bottom" style="font-weight: normal">
          {{ input.name }} <small *ngIf="input.required" class="text-warn">*</small>
        </h4>
        <mat-radio-group class="vertical-radio-group" [(ngModel)]="input.value">
          <mat-radio-button
            class="radio-button"
            value="{{ option.value }}"
            *ngFor="let option of input._selectOptions || []"
            >{{ option.label }}</mat-radio-button
          >
        </mat-radio-group>
      </ng-container>
      <!-- checkbox Array - this is a list of mat cards, input is an array datafield, a second datafield with inputMethod = hidden is modified -->
      <ng-container *ngIf="'checkboxArray' === input.inputDefinitionOfStep?.options?.suggestedInputMethod">
        <ng-container *ngIf="itemsInCart">
          <mat-card appearance="outlined">
            <mat-card-content>
              <div *ngFor="let item of itemsInCart" class="detailed-panel width-100 margin-xxs-bottom radius-4px">
                <div class="flex column no-gap gap-xxs space-between width-100">
                  <span class="detailed-header width-100">
                    {{ item.equipmentName }}
                    <div class="flex space-between align-center">
                      <span class="mat-expansion-panel-header-description">
                        <ng-container *ngIf="item.additionalInformation !== ''">
                          {{ item.additionalInformation }}
                          <br />
                        </ng-container>
                        {{ item.packingUnit }}
                      </span>
                      <img class="circle" [src]="item.path" />
                    </div>
                  </span>
                </div>
                <div class="flex column margin-xxs-left margin-xxs-right">
                  <mat-checkbox
                    #notdelivered
                    (change)="
                      toggleNotDelivered($event.checked, item.supplierId);
                      missing.checked = false;
                      defect.checked = false;
                      nofit.checked = false;
                      toggleMissing(false, item.supplierId);
                      toggleDefect(false, item.supplierId);
                      toggleFitting(false, item.supplierId)
                    "
                  >
                    {{ t('views.psa.order.not_delivered') }} <br />
                    <small>({{ t('views.psa.order.missing_no_bill') }})</small>
                  </mat-checkbox>
                  <mat-checkbox
                    #missing
                    (change)="
                      toggleMissing($event.checked, item.supplierId);
                      defect.checked = false;
                      nofit.checked = false;
                      notdelivered.checked = false;
                      toggleNotDelivered(false, item.supplierId);
                      toggleDefect(false, item.supplierId);
                      toggleFitting(false, item.supplierId)
                    "
                  >
                    {{ t('views.psa.order.missing') }} <br />
                    <small>({{ t('views.psa.order.missing_bill') }})</small>
                  </mat-checkbox>
                  <!-- take photo (mobile) or upload image or file -->
                  <div class="flex align-center space-between">
                    <mat-checkbox
                      #defect
                      (change)="
                        toggleDefect($event.checked, item.supplierId);
                        missing.checked = false;
                        nofit.checked = false;
                        notdelivered.checked = false;
                        toggleNotDelivered(false, item.supplierId);
                        toggleMissing(false, item.supplierId);
                        toggleFitting(false, item.supplierId)
                      "
                    >
                      {{ t('views.psa.order.defect') }} <br />
                      <small>({{ t('general.take_photo') }})</small>
                    </mat-checkbox>
                    <div class="margin-md-left flex align-center">
                      <button
                        *ngIf="isMobile"
                        mat-raised-button
                        [disabled]="!defect.checked"
                        [color]="getWebCamData(item) ? '' : 'warn'"
                        [class.bg-success]="getWebCamData(item)"
                        (click)="onCameraClick(getWebCamData(item), item)"
                      >
                        <mat-icon *ngIf="!getWebCamData(item)" class="material-symbols-outlined">
                          photo_camera
                        </mat-icon>
                        <mat-icon *ngIf="getWebCamData(item)" class="material-symbols-outlined">image</mat-icon>
                      </button>
                      <ng-container *ngIf="!isMobile">
                        <span [class.text-muted]="!defect.checked" *ngIf="fileInput.files">
                          {{ getFileNamePreview(item) }}
                        </span>
                        <span [class.text-muted]="!defect.checked" *ngIf="!getFileNamePreview(item)">
                          {{ t('general.choose_file') }}
                        </span>
                        <input
                          #fileInput
                          type="file"
                          style="display: none"
                          (change)="fileChange($event.target.files[0], item)"
                        />
                        <button
                          [disabled]="!defect.checked"
                          mat-raised-button
                          (click)="fileInput.click()"
                          [color]="getFileNamePreview(item) ? 'primary' : 'warn'"
                        >
                          <mat-icon class="material-symbols-outlined">publish</mat-icon>
                          {{ t('general.choose_file_button') }}
                        </button>
                      </ng-container>
                    </div>
                  </div>
                  <mat-checkbox
                    #nofit
                    (change)="
                      toggleFitting($event.checked, item.supplierId);
                      missing.checked = false;
                      defect.checked = false;
                      notdelivered.checked = false;
                      toggleNotDelivered(false, item.supplierId);
                      toggleMissing(false, item.supplierId);
                      toggleDefect(false, item.supplierId)
                    "
                  >
                    {{ t('general.no_fit') }} <br />
                    <small>(Separater Prozess)</small>
                  </mat-checkbox>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </ng-container>
      </ng-container>
      <!-- Yes no - checkbox -->
      <ng-container *ngIf="input.type === inputTypes.YES_NO">
        @if ('lsr-image' === input.inputDefinitionOfStep?.options?.suggestedInputMethod) {
          <div [ngClass]="{ 'flex align-center column width-100': isMobile, 'silhouette-container': !isMobile }">
            <div class="hidden-xs">
              <div class="title flex align-center" id="title-line">
                <p class="text-primary">{{ t('views.crew.lsr_1') }}</p>
              </div>

              <div class="lsr" id="chest-line">
                <p class="text-primary">
                  {{ t('views.crew.lsr_2') }} GEFO <span class="text-warn">L</span>ive
                  <span class="text-warn">S</span>aving <span class="text-warn">R</span>ules
                </p>
              </div>
              <div class="subtitle" id="inseam-line">
                <p>{{ t('views.crew.lsr_3') }}</p>
              </div>
              <div class="checkbox" id="hip-line">
                <mat-checkbox [(ngModel)]="input.value">
                  {{ t('views.crew.lsr_4') }}
                  <a href="/documents/Appendix13PPE_Rev042020.docx.pdf" target="_blank">
                    <b>{{ t('views.crew.lsr_5') }}</b>
                  </a>
                  {{ t('views.crew.lsr_6') }}
                </mat-checkbox>
              </div>
            </div>

            <img src="/assets/dummy-pics/stop_work_policy.png" style="width: 200px" alt="Stop work policy" />
            <div class="visible-xs margin-xs-top">
              <p class="text-primary text-xl font-medium">{{ t('views.crew.lsr_1') }}</p>
              <p class="text-primary text-lg font-medium">
                {{ t('views.crew.lsr_2') }} GEFO <span class="text-warn">L</span>ive
                <span class="text-warn">S</span>aving <span class="text-warn">R</span>ules
              </p>
              <p class="margin-xs-bottom">{{ t('views.crew.lsr_3') }}</p>
              <mat-checkbox [(ngModel)]="input.value">
                {{ t('views.crew.lsr_4') }}
                <a href="/documents/Appendix13PPE_Rev042020.docx.pdf" target="_blank">
                  <b>{{ t('views.crew.lsr_5') }}</b>
                </a>
                {{ t('views.crew.lsr_6') }}
              </mat-checkbox>
            </div>
          </div>
        } @else if ('select' === input.inputDefinitionOfStep?.options?.suggestedInputMethod) {
          <div class="flex align-center margin-sm-bottom">
            <p>{{ t('views.psa.default_address') }} {{ input.name }}</p>
            <mat-form-field [subscriptSizing]="'dynamic'" class="auto-width">
              <mat-label>{{ input.name }}</mat-label>
              <mat-select [(ngModel)]="input.value" (selectionChange)="updateAddress($event.value)">
                @for (option of input._selectOptions; track $index) {
                  <mat-option [value]="option.value">{{ option.label }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        } @else {
          <mat-checkbox class="width-100" [(ngModel)]="input.value">{{ input.name }}</mat-checkbox>
        }
      </ng-container>
      <!-- file -->
      <ng-container *ngIf="input.type === inputTypes.FILE">
        <ng-container *ngIf="'hideTitle' !== input.inputDefinitionOfStep?.options?.suggestedInputMethod">
          {{ input.name }}
        </ng-container>
        <div
          *ngIf="'hideTitle' === input.inputDefinitionOfStep?.options?.suggestedInputMethod"
          class="margin-xs-top"
        ></div>
        <div class="flex align-center space-between">
          <button
            *ngIf="isMobile"
            mat-raised-button
            [color]="getWebCamData() ? 'primary' : 'warn'"
            (click)="onCameraClick(getWebCamData(), undefined, input)"
          >
            <ng-container *ngIf="!getWebCamData()">
              <div class="flex align-center">
                <mat-icon class="material-symbols-outlined">photo_camera</mat-icon>
                {{ t('general.take_photo') }}
              </div>
            </ng-container>
            <ng-container *ngIf="getWebCamData()">
              <div class="flex align-center">
                <mat-icon class="material-symbols-outlined">image</mat-icon>
                {{ t('general.edit_photo') }}
              </div>
            </ng-container>
          </button>
          <ng-container *ngIf="!isMobile">
            <span>
              <span *ngIf="fileInputSingle.files">
                {{ filePreviewPath }}
              </span>
              <span *ngIf="!filePreviewPath">
                {{ t('general.choose_file') }}
              </span>
            </span>
            <span>
              <input
                #fileInputSingle
                type="file"
                style="display: none"
                (change)="fileChange($event.target.files[0], null, null, input)"
              />
              <button
                mat-raised-button
                (click)="fileInputSingle.click()"
                [color]="filePreviewPath ? 'primary' : 'warn'"
              >
                <mat-icon class="material-symbols-outlined">publish</mat-icon>
                {{ t('general.choose_file_button') }}
              </button>
            </span>
          </ng-container>
        </div>
      </ng-container>
      <!-- Information and input readonly fields -->
      @if (input.inputDefinitionOfStep?.options?.suggestedInputMethod !== 'hidden') {
        <ng-container
          *ngIf="
            input.type === inputTypes.TEXT_READONLY &&
            'info' !== input.inputDefinitionOfStep?.options?.suggestedInputMethod
          "
        >
          <mat-form-field>
            <mat-label>{{ input.name }}</mat-label>
            <input disabled matInput type="text" [(ngModel)]="input.value" #inputField="ngModel" />
          </mat-form-field>
        </ng-container>
      }
      <!-- readonly -->
      <ng-container
        *ngIf="
          input.type === inputTypes.TEXT_READONLY &&
          'info' === input.inputDefinitionOfStep?.options?.suggestedInputMethod
        "
      >
        <div class="flex space-between align-center">
          <h4>{{ input.name }}</h4>
          <button mat-icon-button (click)="showInfo = !showInfo">
            <mat-icon class="material-symbols-outlined">help</mat-icon>
          </button>
        </div>
        <p *ngIf="showInfo">
          <small>{{ input.value }}</small>
        </p>
      </ng-container>
      <!-- select lat and lng from map -->
      <ng-container *ngIf="'onmap' === input.inputDefinitionOfStep?.options?.suggestedInputMethod">
        <div class="flex align-center">
          <mat-form-field class="auto-width">
            <input
              [autofocus]="false"
              matInput
              readonly
              type="text"
              placeholder="{{ input.name }}"
              [(ngModel)]="input.value"
              #inputField="ngModel"
              [required]="input.required"
            />
            <mat-error *ngIf="inputField.touched && inputField.invalid">
              {{ t('general.required_field') }}
            </mat-error>
          </mat-form-field>
          <button class="width-70" mat-raised-button (click)="openMapDialog(input)">
            {{ t('general.select_on_map') }}
          </button>
        </div>
      </ng-container>
    </p>
  </div>
</ng-container>
