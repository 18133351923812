import { Inject, Injectable } from '@angular/core';
import { ILocationMeta, SiteModel } from 'src/app/models/site.model';
import { Address, GoogleSiteModel } from '../interfaces/maps/google-site-model.interface';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { CachingService } from './caching.service';
import { DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class GoogleMapService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private http: HttpClient,
    private cachingService: CachingService
  ) {}
  /**
   * Sets the marker label if a specific threshold is exceeded.
   *
   * ToDo: This is not working anymore for advanced markers, they dont have a label property
   *
   * @param allSites
   * @param currentZoom
   * @param threshold
   * @returns
   */
  zoomChanged(allSites: GoogleSiteModel[], currentZoom: number, threshold = 12): GoogleSiteModel[] {
    if (currentZoom >= threshold) {
      allSites.forEach((element) => {
        if (element.symbol) {
          if (element.symbol.rotation === undefined) {
            element.symbol = {
              ...element.symbol,
              labelOrigin: new google.maps.Point(element.name.length * 16 + currentZoom * 5, 0)
            };
          } else {
            element.symbol = {
              ...element.symbol,
              labelOrigin: new google.maps.Point(24, -24)
            };
          }
        }
        element.markerLabel = {
          text: element.name,
          fontWeight: 'bold',
          fontSize: '16px',
          className: 'marker-label'
        };
      });
    } else {
      allSites.forEach((element) => {
        element.markerLabel = null;
      });
    }
    return allSites;
  }

  checkIfLocationIsOutdated(locationMeta: ILocationMeta[]) {
    const meta = locationMeta.find((m) => m.key === 'position.received');
    if (meta) {
      const currentTime = new Date();
      const timeDifference = currentTime.getTime() - new Date(meta.value).getTime();
      const hoursDifference = timeDifference / (1000 * 60 * 60);
      // Check if the time difference is greater than 2 hours
      if (hoursDifference > 2 && hoursDifference <= 11) {
        return 1;
      }
      if (hoursDifference > 12) {
        return 2;
      }
    }
    return 0;
  }

  getStyles(): any[] {
    return [
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }]
      },
      {
        elementType: 'geometry',
        stylers: [{ color: '#f5f5f5' }]
      },
      {
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }]
      },
      {
        elementType: 'labels.text.fill',
        stylers: [{ color: '#616161' }]
      },
      {
        elementType: 'labels.text.stroke',
        stylers: [{ color: '#f5f5f5' }]
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#bdbdbd' }]
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [{ color: '#eeeeee' }]
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ color: '#ffffff' }]
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#757575' }]
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [{ color: '#dadada' }]
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#616161' }]
      },
      {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#9e9e9e' }]
      },
      {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [{ color: '#e5e5e5' }]
      },
      {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [{ color: '#eeeeee' }]
      },
      {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [{ color: '#8AB4F8' }]
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#8AB4F8' }]
      }
    ];
  }

  getNearbySites(sites: SiteModel[], location: { lat: number; lng: number }): SiteModel[] {
    const radius = 15; // km
    const nearIds = sites
      .map((e) => {
        return {
          siteId: e.siteId,
          distance: this.getDistanceFromLatLngInKm(location.lat, location.lng, e.latitude, e.longitude)
        };
      })
      .filter((e) => e.distance < radius)
      .map((e) => e.siteId);

    return sites.filter((e) => nearIds.includes(e.siteId));
  }

  /**
   * Gets symbol as svg
   *
   * @param site
   * @returns google.maps.Symbol
   */
  getSymbol(site: SiteModel, noStatus?: boolean): any {
    let color: { fill: string; stroke: string };
    const heading = site.locationMeta.find((e) => e.key === 'heading')?.value;
    const speed = site.locationMeta.find((e) => e.key === 'position.speed')?.value;

    if (site.mainAsset?.condition) {
      switch (site.mainAsset.condition) {
        case 1:
          color = {
            stroke: '#000000',
            fill: getComputedStyle(document.documentElement).getPropertyValue('--notify-success')
          };
          break;
        case 3:
          color = {
            stroke: '#000000',
            fill: getComputedStyle(document.documentElement).getPropertyValue('--notify-warn')
          };
          break;
        case 5:
          color = {
            stroke: '#000000',
            fill: getComputedStyle(document.documentElement).getPropertyValue('--notify-error')
          };
          break;
        default:
          color = {
            stroke: '#000000',
            fill: getComputedStyle(document.documentElement).getPropertyValue('--notify-no-status')
          };
      }
    }

    if (noStatus) {
      color = { stroke: '#000000', fill: getComputedStyle(document.documentElement).getPropertyValue('--table-info') };
    }

    if (heading && Number(speed) > 0.1) {
      const svgString = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48" height="48">
        <g>
        <path d="M9.5 42 8 40.5 24 4l16 36.5-1.5 1.5L24 35.4Z" fill="${color.fill}" stroke="${color.stroke}" stroke-width="2" />
        </g>
        </svg>`;

      const markerDiv = document.createElement('div');
      markerDiv.className = 'site-inner-div';
      markerDiv.innerHTML = svgString;
      markerDiv.style.transform = ` translate(-50%, 24px) rotate(${heading}deg) scale(0.5)`;

      return markerDiv;
    } else {
      const svgString = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48" height="48">
                            <g>
                                <path d="M24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 24q0-4.15 1.575-7.8 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24 4q4.15 0 7.8 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Z"
                                        fill="${color.fill}" stroke="${color.stroke}" stroke-width="4"/>
                            </g>
                        </svg>`;

      const markerDiv = document.createElement('div');
      markerDiv.className = 'site-inner-div';
      markerDiv.innerHTML = svgString;
      markerDiv.style.transform = ` translate(-50%, 24px) rotate(${heading}deg) scale(0.25)`;

      return markerDiv;
    }
  }

  getBoundsZoomLevel(latNorth, lngEast, latSouth, lngWest, mapContainerHeight, mapContainerWidth) {
    const GLOBE_WIDTH = 256; // a constant in Google's map projection
    const ZOOM_MAX = 21; // also define min zoom, for choosing one asset only
    const latFraction = (this.latRad(latNorth) - this.latRad(latSouth)) / Math.PI;
    const lngDiff = lngEast - lngWest;
    const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;
    const latZoom = this.calcZoom(mapContainerHeight, GLOBE_WIDTH, latFraction);
    const lngZoom = this.calcZoom(mapContainerWidth, GLOBE_WIDTH, lngFraction);
    return Math.min(Math.min(latZoom, lngZoom), ZOOM_MAX) - 0.3;
  }

  /**
   * Validate address with google validation api. Use a cached subscription,  we do not want to make the same request twice.
   *
   * Use invalidateAddressCache to delete the cache subscription.
   *
   * @param cacheName
   * @param address
   * @returns
   */
  validateAddress(cacheName: string, address: Address): Observable<any> {
    const body = {
      address: {
        regionCode: address.country, // Assuming country code is in the address object
        locality: address.city,
        addressLines: [`${address.streetNr}, ${address.postal}`] // Combine street and number
      }
    };
    const url = `https://addressvalidation.googleapis.com/v1:validateAddress?key=${environment.googleMapAPI}`;
    return this.cachingService.createCachingSubscription(cacheName, this.http.post<any>(url, body));
  }

  invalidateAddressCache(cacheName: string) {
    this.cachingService.invalidateCache(cacheName);
  }

  private latRad(lat: number) {
    const sin = Math.sin((lat * Math.PI) / 180);
    const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  }

  private calcZoom(mapPx, worldPx, fraction) {
    return Math.log(mapPx / worldPx / fraction) / Math.LN2;
  }

  /**
   * See Haversine formula for reference
   *
   * @param lat1
   * @param lng1
   * @param lat2
   * @param lng2
   * @returns
   */
  private getDistanceFromLatLngInKm(lat1, lng1, lat2, lng2) {
    const R = 6371; // Radius of the earth in km
    const dLat = this.deg2rad(lat2 - lat1);
    const dLon = this.deg2rad(lng2 - lng1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
  }

  private deg2rad(deg) {
    return deg * (Math.PI / 180);
  }
}
