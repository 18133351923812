import { Component, Input, OnInit } from '@angular/core';
import { Asset } from 'src/app/interfaces/assets/asset.interface';

@Component({
  selector: 'app-asset-outline',
  templateUrl: './asset-outline.component.html',
  styleUrls: ['./asset-outline.component.scss']
})
export class AssetOutlineComponent implements OnInit {
  @Input() size = 'normal';
  @Input() asset: Asset;

  constructor() {}

  ngOnInit(): void {}
}
