import { Injectable } from '@angular/core';
import { BackendObservable, BackendService } from './backend.service';
import { firstValueFrom, Observable } from 'rxjs';
import { ISequelizeCount } from '../models/sequelize-count.interface';
import { ProcessResult } from '../components/dialogs/process-result/interfaces/process-result.interface';
import { TranslocoService } from '@ngneat/transloco';
import { HttpClient } from '@angular/common/http';
import { MemoryService } from './memory.service';
import { CachingService } from './caching.service';
import { DatePipe } from '@angular/common';
import { NotificationService } from './notification.service';
import { Router, UrlSerializer } from '@angular/router';
import { SmvPerUser } from '../components/smv-history/smv-user.interface';
import { IGetAllACtiveStepsOptions, IGetProcessesOptions } from '../interfaces/endpoint-options.interface';
import {
  ExpansionTableData,
  ExpansionTableRows
} from '../components/expansion-table/interfaces/expansion-table-data.interface';
import { UserService } from './user.service';
import { AssetAttributeValue } from '../interfaces/assets/asset-attribute-value.interface';
import { ActionResult } from '../interfaces/process/action-result.interface';
import { DataField, DataFieldTranslation } from '../interfaces/process/data-field.interface';
import { ProcessDataModel } from '../interfaces/process/process-data.interface';
import { ProcessDefinition } from '../interfaces/process/process-definition.interface';
import { Process, IProcessStartOptions } from '../interfaces/process/process.interface';
import { StepDefinition, TypeData } from '../interfaces/process/step-definition.interface';
import { Step } from '../interfaces/process/step.interface';
import { INPUT_TYPES } from '../process-engine/process/process-handler/step-handle-manual-input/step-handle-manual-input.component';

@Injectable({
  providedIn: 'root'
})
export class ProcessService extends BackendService {
  public static readonly TYPES = {
    MANUAL_SELECTION: 1,
    MANUAL_INPUT: 2,
    UPLOAD: 3,
    INPUT_WATCHER: 4,
    EMAIL_SENDER: 100,
    WEBHOOK: 101,
    BRANCH_COLLECTOR: 1000
  };

  constructor(
    private readonly translate: TranslocoService,
    private readonly userService: UserService,
    protected http: HttpClient,
    protected cachingService: CachingService,
    protected memory: MemoryService,
    protected datePipe: DatePipe,
    protected notify: NotificationService,
    protected router: Router,
    protected serializer: UrlSerializer
  ) {
    super(http, notify, memory, router, null, cachingService, datePipe, null);
  }

  static stepDefinitionToFakeStep(
    stepDefinition: StepDefinition,
    id_code_1?: string,
    id_code_2?: string
  ): Partial<Step> {
    return {
      stepDefinition,
      step_definition_id: stepDefinition.id,
      title: stepDefinition.title,
      process: {
        id_code_1,
        id_code_2,
        ProcessDefinition: stepDefinition.processDefinition,
        process_definition_id: stepDefinition.process_definition_id,
        title: stepDefinition.processDefinition.title,
        phase: stepDefinition.processDefinition.phase
      }
    };
  }

  getStep(stepId: number, processId: number): Observable<Step> {
    return this.get<Step>(`process/${processId}/step/${stepId}/get`);
  }

  findAllActiveSteps(
    criteria?: any,
    limit?: number,
    offset?: number,
    order?: any[],
    options?: IGetAllACtiveStepsOptions
  ): Observable<ISequelizeCount<Step>> {
    return this.post<ISequelizeCount<Step>>(`process/getAllActiveSteps`, {
      criteria,
      options,
      limit,
      offset,
      order
    });
  }

  getPhaseLastExecutedStep(
    phase: string,
    limit: number = 10,
    offset: number = 0
  ): Observable<ISequelizeCount<Process>> {
    return this.get('process/getPhaseLastExecutedStep', { params: { phase, limit, offset } });
  }

  getPhaseExecutedProcesses(
    phase: string,
    processDefinitionId: number,
    siteId: number,
    limit: number = 10,
    offset: number = 0
  ): Observable<Process[]> {
    return this.get('process/getPhaseExecutedProcesses', {
      params: { phase, processDefinitionId, siteId, limit, offset }
    });
  }

  getProcesses(
    criteria?: any,
    limit?: number,
    offset?: number,
    order?: any[],
    options?: IGetProcessesOptions
  ): Observable<ISequelizeCount<Process>> {
    return this.post<ISequelizeCount<Process>>(`process/getProcesses`, {
      criteria,
      options,
      limit,
      offset,
      order
    });
  }

  /**
   * Sometimes a process is set to executed even if there is a step that is not. Therefore we check here for processes and the steps.
   *
   * @param criteria
   * @param limit
   * @param offset
   * @param order
   * @param options
   * @returns
   */
  getExecutedProcesses(
    criteria?: any,
    limit?: number,
    offset?: number,
    order?: any[],
    options?: IGetProcessesOptions
  ): Observable<ISequelizeCount<Process>> {
    return this.post<ISequelizeCount<Process>>(`process/getExecutedProcesses`, {
      criteria,
      options,
      limit,
      offset,
      order
    });
  }

  deleteProcess(processId: number, deleteCalendarEntry?: boolean): Observable<string> {
    return this.post<string>(`process/${processId}/delete`, { calendar: deleteCalendarEntry });
  }

  /**
   *
   * @param step
   * @param data
   * @param processOptions - only available on process start
   * @returns
   */
  performStep(step: Step, data: any, processOptions?: IProcessStartOptions): Observable<ActionResult> {
    if (Object.values(data.input || {}).find((d: any) => d instanceof FileList)) {
      data = this.convertDataToFormData(data, processOptions);
    } else {
      data = { data };
    }
    if (!step.process_id) {
      // ToDo: this is not a good idea, try to do it safer! Better typing for stepDefinition?
      if (step.process.phase.includes('Reports') || step.process.phase.includes('Visits')) {
        let date: string;
        if (step.stepDefinition.type_data.inputs?.find((e) => e.type === INPUT_TYPES.DATE)) {
          try {
            date = this.datePipe.transform(step.stepDefinition.type_data.inputs[2].value, 'yyyy-MM-dd');
          } catch (error) {
            console.error(error.message);
            return;
          }
        } else {
          date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
        }
        processOptions.predefined_attributes.id_code_2 = date;
      }
      return this.startProcessByManualStep(step, Object.assign(data, { processOptions }));
    }
    return this.post<ActionResult>(`process/${step.process_id}/performStep/${step.id}`, data);
  }

  startProcessByManualStep(step: Step, data: any): Observable<ActionResult> {
    return this.post<ActionResult>(
      `processDefinition/${step.process.process_definition_id}/startByManualStep/${step.step_definition_id}`,
      data
    );
  }

  getData(processId: number): Observable<ProcessDataModel> {
    return this.get<any>(`process/${processId}/getData`);
  }

  getMultiData(processIds: number[]): Observable<ProcessDataModel[]> {
    return this.post<any>(`process/getMultiData`, { processIds });
  }

  getHistory(processId: number): Observable<Step<TypeData>[]> {
    return this.get<Step<TypeData>[]>(`process/${processId}/getHistory`);
  }

  getDataFields(processId: number): Observable<DataField[]> {
    return this.get<DataField[]>(`process/${processId}/getDataFields`);
  }

  updateCreateDataFieldValue(datafieldDefinitionId: number, processId: number, datafieldValue: any) {
    return this.post<DataField>('process/setDataFieldValue', {
      datafieldDefinitionId,
      processId,
      datafieldValue
    });
  }

  getProcessResult(processId: number): Observable<ProcessResult> {
    return this.get<ProcessResult>(`process/${processId}/getProcessResultData`);
  }

  getMultiProcessResults(processIds: number[]): Observable<ProcessResult[]> {
    return this.post<ProcessResult[]>(`process/getMultiProcessResultData`, { processIds });
  }

  downloadFile(processId: number, dataFieldId: number, fileIndex = 0, overrideDownloadPath?: string): void {
    if (overrideDownloadPath) {
      this.performDownload(
        overrideDownloadPath
          .replace('%0', processId + '')
          .replace('%1', dataFieldId + '')
          .replace('%2', fileIndex + '')
      );
      return;
    }
    this.performDownload(
      `processEngine/process/${processId}/downloadDataFieldFile/${dataFieldId}` + (fileIndex > 0 ? `/${fileIndex}` : '')
    );
  }

  getAllProcessDefinitions(criteria: any): Observable<ProcessDefinition[]> {
    return this.post<ProcessDefinition[]>(`processDefinition/all/get`, {
      criteria
    });
  }

  getEnhanceableDataFieldDefinitionIds(): Observable<{
    [s: string]: number[];
  }> {
    return this.cachingService.createCachingSubscription(
      'getEnhanceableDataFieldDefinitionIds',
      this.get<{ [s: string]: number[] }>('processDefinition/getEnhanceableDataFieldDefinitionIds')
    );
  }

  getStartStepDefinition(processDefinitionId: number): Observable<StepDefinition> {
    return this.get<StepDefinition>(`processDefinition/${processDefinitionId}/getStartStepDefinition`);
  }

  getAllSMVExport(year: number, smvPerUser: SmvPerUser[]) {
    this.performDownload('processEngine/process/all/smv/export', {
      year,
      smvPerUser: encodeURIComponent(JSON.stringify(smvPerUser))
    });
  }

  getAllVisitsExport() {
    this.performDownload('processEngine/process/all/visits/export', {});
  }

  getEmergencyExport(start?: Date, end?: Date) {
    this.performDownload('processEngine/process/all/emergency/export', { start, end });
  }

  terminateToOtherStepDefinition(stepId: number, stepDefinitionId: number): Observable<ActionResult> {
    return this.post<ActionResult>(`processDefinition/terminateToOtherStepDefinition`, {
      currentStepId: stepId,
      stepDefinitionId
    });
  }

  post<T>(url: string, body: any = {}, options?: any): BackendObservable<T> {
    options = { ...options, headers: { language: this.translate.getActiveLang() } };
    return super.post<T>(`processEngine/${url}`, body, options);
  }

  get<T>(url: string, options?: any): Observable<T> {
    options = { ...options, headers: { language: this.translate.getActiveLang() } };
    return super.get<T>(`processEngine/${url}`, options);
  }

  getVesselPhone(assetAttributeValues: AssetAttributeValue[]): string {
    return assetAttributeValues.find((e) => [132, 135, 145, 148, 183].includes(e.assetAttributeId))?.value;
  }

  getVesselEmail(assetAttributeValues: AssetAttributeValue[]): string {
    return assetAttributeValues.find((e) => [133, 136, 139, 144, 184].includes(e.assetAttributeId))?.value;
  }

  /**
   * We convert table data to expansiontableData for mobile view.
   *
   * Since we use the DataField 'Visitor' here and need to find it by name reference. We need to use the translations. Because the field is translated into different languages.
   *
   * @param steps
   * @param processPhase
   * @param translations currently only for DF Visitor
   * @returns
   */
  async convertToExpansionTableData(
    steps: Step[],
    processPhase: string,
    translations: DataFieldTranslation[]
  ): Promise<ExpansionTableData[]> {
    const allUsers = await firstValueFrom(this.userService.getAllUsers({}));
    const data = steps?.map((e) => {
      let userId: number;
      let rows: ExpansionTableRows[];
      if (processPhase === 'visits') {
        rows = [
          { header: 'Visit', content: e.process.title },
          { header: 'Schiff', content: e.process.id_code_1 },
          { header: 'Date of Visit', content: this.datePipe.transform(e.process.id_code_2, 'longDate', '+0200', 'de') },
          { header: 'Schritt', content: e.title },
          { header: 'ausgeführt am', content: this.datePipe.transform(e.invoked, 'longDate', '+0200', 'de') }
        ];
        let userName: string;
        // Translate userId to username if needed. We changed from setting the user name in the visitor dataField to setting the userId.
        // Thats why we need the conversion here for the "old" processes.
        const rawVisitorValue = e.process.DataFields.find(
          (field) => field.name === translations.find((trans) => trans.name === 'Visitor').value
        )?.value;
        if (Number(rawVisitorValue)) {
          const user = allUsers.rows.find((u) => u.userId === Number(rawVisitorValue));
          userId = Number(rawVisitorValue);
          userName = `${user.forename} ${user.surname}`;
        } else {
          userName = e.process.DataFields.find(
            (field) => field.name === translations.find((trans) => trans.name === 'Visitor').value
          )?.value;
          const userModel = allUsers.rows.find(
            (user) => userName?.includes(user.forename) && userName?.includes(user.surname)
          );
          userId = userModel?.userId;
        }
        rows.push({
          header: 'ausgeführt von',
          content: userName
        });
      } else if (processPhase === 'emergency') {
        userId = allUsers.rows.find((u) => u.userId === Number(e.process.responsible_company))?.userId;
        rows = [{ header: 'Process', content: e.process.title }];
      }

      return {
        title: e.process.title || 'Emergency Report',
        description: this.datePipe.transform(e.process.id_code_2, 'longDate', '+0200', 'de'),
        rows,
        processId: e.process.id,
        canResume: e.canResume,
        userId,
        stepId: e.id
      } as ExpansionTableData;
    });
    return data;
  }

  private convertDataToFormData(data: any, processOptions?: any): FormData {
    const formData: FormData = new FormData();
    const postData: any = {};
    for (const id in data.input || []) {
      if (data.input.hasOwnProperty(id)) {
        if (data.input[id] instanceof FileList) {
          for (const file of data.input[id]) {
            formData.append(id, file);
          }
          delete data.input[id];
        } else {
          postData[id] = data.input[id];
        }
      }
    }
    formData.append('data', JSON.stringify(data));
    formData.append('processOptions', JSON.stringify(processOptions));
    return formData;
  }
}
