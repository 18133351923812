import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { BackendService } from './backend.service';

const DYNAMIC_TABLE_MENU_ITEMS_SETTINGS_KEY = 'dynamic_table_menu_items';

export class FilterListMenuItem {
  title: string;
  filter: any[];
  columns: string[];
  id?: number;
  menuEntry?: string;

  constructor(title: string, filter: any[], columns: string[], id?: number, menuEntry?: string) {
    this.title = title;
    this.filter = filter;
    this.columns = columns;
    this.menuEntry = menuEntry;
    this.id = id || Math.round(Math.random() * 10000000000);
  }

  getParamsAsQueryParams(): any {
    return {
      columns: JSON.stringify(this.columns),
      filter: JSON.stringify(this.filter),
      origin: this.menuEntry
    };
  }
}

function transformToObjects(data: FilterListMenuDictionary): FilterListMenuDictionary {
  for (const attr in data) {
    if (data[attr]) {
      const resArray: FilterListMenuItem[] = [];
      data[attr].forEach((item) => {
        resArray.push(new FilterListMenuItem(item.title, item.filter, item.columns, item.id, item.menuEntry));
      });
      data[attr] = resArray;
    }
  }
  return data;
}

export type FilterListMenuDictionary = { [s: string]: FilterListMenuItem[] };

const MOCK_LIST: { [s: string]: FilterListMenuItem[] } = {
  '/assets/table': [new FilterListMenuItem('Mega Menüpunkt', [], [])]
};

@Injectable({
  providedIn: 'root'
})
export class FilterListMenuService extends BackendService {
  createNewEnabledFor: string[] = [];

  /** subject that is triggered when create is a new menu item should be created somewhere */
  onDynamicTableDataRequested$ = new Subject<void>();
  onEditRequested$ = new Subject<FilterListMenuItem | false>();

  dynamicTableData$ = new Subject<Partial<FilterListMenuItem>>();

  editing = false;

  getItems(staySubscribed = true): Observable<FilterListMenuDictionary> {
    return this.cachingService.createCachingSubscription(
      'filterListMenus',
      this.get<FilterListMenuDictionary>(
        `user/role/${this.memoryService.getLoginData().roleId}/setting/${DYNAMIC_TABLE_MENU_ITEMS_SETTINGS_KEY}/get`
      ).pipe(map((d) => transformToObjects(d) || {})),
      staySubscribed
    );
  }

  createNewIsEnabled(baseUrl: string, staySubscribed = true): Observable<boolean> {
    return this.cachingService.createCachingSubscription(
      this.getCreateNewCachingName(baseUrl),
      new Observable((observer) => {
        observer.next(this.createNewEnabledFor.includes(baseUrl));
        observer.complete();
      }),
      staySubscribed
    );
  }

  dynamicTableChanged(baseUrl: string, filter: any[], columns: string[]): void {
    if (this.editing) {
    } else {
      this.createNewEnabledFor.push(baseUrl);
      this.cachingService.invalidateCache(this.getCreateNewCachingName(baseUrl));
      this.createNewIsEnabled(baseUrl, false).subscribe();
    }
  }

  disableCreateNew(baseUrl: string): void {
    this.createNewEnabledFor = this.createNewEnabledFor.filter((b) => b !== baseUrl);
    this.cachingService.invalidateCache(this.getCreateNewCachingName(baseUrl));
    this.createNewIsEnabled(baseUrl, false).subscribe();
  }

  disableEdit(): void {
    this.editing = undefined;
    this.onEditRequested$.next(false);
  }

  createNew(baseUrl: string, title: string, filter: any, columns: any): Observable<any> {
    return new Observable<any>((observer) => {
      console.log(baseUrl, title, filter, columns);
      this.modifySetting((items) => {
        console.log(JSON.stringify(items[baseUrl]));
        items[baseUrl] = items[baseUrl] || [];
        console.log(JSON.stringify(items[baseUrl]));
        items[baseUrl].push(new FilterListMenuItem(title, filter, columns));
        console.log(JSON.stringify(items[baseUrl]));
        console.log(JSON.stringify(items));
        return items;
      }).subscribe(() => {
        observer.next(true);
        observer.complete();
        this.cachingService.invalidateCache('filterListMenus');
        this.getItems(false).subscribe();
        this.disableCreateNew(baseUrl);
        this.createNewIsEnabled(baseUrl).subscribe();
      });
    });
  }

  createNewItemFromCurrentView(baseUrl: string, name: string): void {
    this.requestDynamicTableData().subscribe((d) => {
      console.log(d);
      this.createNew(baseUrl, name, d.filter || [], d.columns || []).subscribe();
    });
  }

  editItem(baseUrl: string, item: FilterListMenuItem): Observable<any> {
    return new Observable<any>((observer) => {
      this.modifySetting((items) => {
        const internalItem = items[baseUrl].find((i) => i.id === item.id);
        if (internalItem) {
          internalItem.columns = item.columns;
          internalItem.filter = item.filter;
        }
        return items;
      }).subscribe(() => {
        observer.next(true);
        observer.complete();
        this.cachingService.invalidateCache('filterListMenus');
        this.getItems(false).subscribe();
        this.disableEdit();
        this.createNewIsEnabled(baseUrl).subscribe();
        this.editing = false;
      });
    });
  }

  requestDeleteItem(baseUrl: string, item: FilterListMenuItem): void {
    this.notification
      .confirmDialog({
        title: 'Wirklich löschen?',
        body: 'Wenn gelöscht, wird der Menüpunkt für alle Inhaber Deiner Rolle ausgeblendet'
      })
      .then((r) => {
        if (r) {
          this.deleteItem(baseUrl, item).subscribe();
        }
      });
  }

  deleteItem(baseUrl: string, item: FilterListMenuItem): Observable<any> {
    return new Observable<any>((observer) => {
      this.modifySetting((items) => {
        items[baseUrl] = items[baseUrl].filter((i) => i.id !== item.id);
        return items;
      }).subscribe(() => {
        observer.next(true);
        observer.complete();
        this.cachingService.invalidateCache('filterListMenus');
        this.getItems(false).subscribe();
        this.disableEdit();
        this.editing = false;
      });
    });
  }

  requestDynamicTableData(): Observable<Partial<FilterListMenuItem>> {
    window.setTimeout(() => {
      this.onDynamicTableDataRequested$.next();
    });
    return this.dynamicTableData$.pipe(first());
  }

  requestEdit(item: FilterListMenuItem, baseUrl: string): void {
    this.onEditRequested$.next(item);
    this.editing = true;
    this.disableCreateNew(baseUrl);
  }

  private getCreateNewCachingName(baseUrl: string): string {
    return 'filterList_create_new_enabled' + baseUrl;
  }

  private saveSetting(items: { [s: string]: FilterListMenuItem[] }): Observable<any> {
    return this.post<{ [s: string]: FilterListMenuItem[] }>(
      `user/role/${this.memoryService.getLoginData().roleId}/setting/set`,
      {
        data: {
          data: items,
          key: DYNAMIC_TABLE_MENU_ITEMS_SETTINGS_KEY
        }
      }
    );
  }

  private modifySetting(
    callback: (items: FilterListMenuDictionary) => FilterListMenuDictionary
  ): Observable<FilterListMenuDictionary> {
    return new Observable<FilterListMenuDictionary>((observer) => {
      this.getItems(false).subscribe((items) => {
        this.saveSetting(callback(items)).subscribe((s) => {
          observer.next(items);
          observer.complete();
        });
      });
    });
  }
}
