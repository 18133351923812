import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { MemoryService } from 'src/app/services/memory.service';
import { FilterListMenuItem, FilterListMenuService } from '../../../services/filter-list-menu.service';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoModule } from '@ngneat/transloco';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-filter-list-menu-outlet',
  templateUrl: './filter-list-menu-outlet.component.html',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatListModule, MatButtonModule, RouterModule, TranslocoModule]
})
export class FilterListMenuOutletComponent implements OnInit, OnDestroy {
  @Input() for: string;
  @Input() menuEntry: string;
  @Output() itemSelected = new EventEmitter();

  title: string;

  items: FilterListMenuItem[] = [];

  subscriptions: {
    createNewEnabled?: Subscription;
    items?: Subscription;
  } = {};

  createNewIsEnabled = false;
  creating = false;

  constructor(
    private filterListService: FilterListMenuService,
    private readonly memoryService: MemoryService
  ) {}

  ngOnInit(): void {
    this.getData();
    this.subscriptions.createNewEnabled = this.filterListService.createNewIsEnabled(this.for).subscribe((b) => {
      this.createNewIsEnabled = b;
    });
  }

  getData() {
    if (this.subscriptions.items) this.subscriptions.items.unsubscribe();
    this.subscriptions.items = this.filterListService.getItems().subscribe(
      (i) => {
        if (i[this.for]) {
          this.items = i[this.for].filter((e) => e.menuEntry === this.menuEntry);
        } else {
          this.items = [];
        }
      },
      (e) => {
        console.error(e);
      }
    );
  }

  requestEdit(item: FilterListMenuItem): void {
    this.filterListService.requestEdit(item, this.for);
    this.creating = false;
    this.title = undefined;
  }

  create(): void {
    if (!this.title) {
      return;
    }
    this.creating = false;
    this.filterListService.createNewItemFromCurrentView(this.for, this.title);
    this.title = undefined;
  }

  ngOnDestroy(): void {
    for (const item of Object.values(this.subscriptions)) {
      if (item) {
        item.unsubscribe();
      }
    }
  }
}
