import { Injectable } from '@angular/core';
import { BackendObservable, BackendService } from './backend.service';
import { Observable } from 'rxjs';
import { VesselDataResult } from '../views/assets/map-condition/vessel-data.interface';
import { ICreateAssetOptions, IGetAllAssetsOptions } from 'src/app/interfaces/endpoint-options.interface';
import { AssetAttributeValue } from '../interfaces/assets/asset-attribute-value.interface';
import { AssetAttribute } from '../interfaces/assets/asset-attribute.interface';
import { AssetCategoryRequirement } from '../interfaces/assets/asset-category-requirement.interface';
import { AssetCategory } from '../interfaces/assets/asset-category.interface';
import { AssetCertificateDefinition } from '../interfaces/assets/asset-certificate-definition.interface';
import { AssetCertificate } from '../interfaces/assets/asset-certificate.interface';
import { AssetHandoverInformation } from '../interfaces/assets/asset-handover-information.interface';
import { AssetPhoto } from '../interfaces/assets/asset-photo.interface';
import { Asset } from '../interfaces/assets/asset.interface';
import { Warehouse } from '../interfaces/assets/warehouse.interface';
import { Step } from '../interfaces/process/step.interface';
import { ISequelizeCount } from '../models/sequelize-count.interface';

@Injectable({
  providedIn: 'root'
})
export class AssetService extends BackendService {
  getAllAssets(
    criteria?: any,
    limit?: number,
    offset?: number,
    options?: IGetAllAssetsOptions,
    order: string[] | string[][] = [['name', 'asc']],
    certificateCriteria?: any
  ): BackendObservable<ISequelizeCount<Asset>> {
    return this.post<ISequelizeCount<Asset>>('all/get', {
      criteria,
      certificateCriteria,
      limit,
      offset,
      options,
      order
    });
  }

  getAllUserAssets(
    criteria?: any,
    limit?: number,
    offset?: number,
    options?: IGetAllAssetsOptions,
    order?: string[] | string[][]
  ): Observable<ISequelizeCount<Asset>> {
    return this.post<ISequelizeCount<Asset>>('user/all/get', { criteria, limit, offset, options, order });
  }

  getAsset(assetId: number): Observable<Asset> {
    return this.get<Asset>(`${assetId}/get`);
  }

  getAllCertificateDefinitions(
    criteria: Partial<AssetCertificateDefinition> | any
  ): Observable<ISequelizeCount<AssetCertificateDefinition>> {
    return this.post<ISequelizeCount<AssetCertificateDefinition>>(`certificateDefinition/all/get`, { criteria });
  }

  createAsset(
    name: string,
    warehouseId: number,
    assetCategoryId: string,
    attributes: Partial<Asset>,
    assetAttributes?: any[],
    ownership?: number,
    options?: ICreateAssetOptions
  ): Observable<Asset> {
    return this.post<Asset>('create', {
      ...attributes,
      name,
      warehouseId,
      assetCategoryId,
      additionalAttributes: assetAttributes,
      ownership,
      options
    });
  }

  createVessel(body: any): Observable<Asset> {
    return this.post<Asset>('createVesselAndAttributes', body);
  }

  takeAsset(assetId: number): Observable<any> {
    return this.post<any>(`${assetId}/take`, {});
  }

  takeoverAsset(assetId: number): Observable<any> {
    return this.post<any>(`${assetId}/takeover`, {});
  }

  returnAsset(assetId: number): Observable<any> {
    return this.post<any>(`${assetId}/return`, {});
  }

  editAsset(
    assetId: number,
    name: string,
    warehouseId: number,
    attributes: Partial<Asset>,
    additionalAttributes?: any[],
    moveConflictsToDefaultStore?: boolean,
    updateSiteAndWarehouse?: boolean,
    shipId?: number
  ): Observable<{ conflictingAssets: Asset[]; assetsChanged: Asset[] }> {
    return this.post<{ conflictingAssets: Asset[]; assetsChanged: Asset[] }>(`${assetId}/edit`, {
      name,
      warehouseId,
      ...attributes,
      additionalAttributes,
      moveConflictsToDefaultStore,
      updateSiteAndWarehouse,
      shipId
    });
  }

  createAssetCategory(name: string, parentCategoryId?: string): Observable<AssetCategory> {
    return this.post<AssetCategory>('category/create', { name, parentCategoryId });
  }

  editAssetCategory(category: AssetCategory): Observable<AssetCategory> {
    return this.post<AssetCategory>(
      `category/${encodeURIComponent(category.assetCategoryId.replace(/\//g, ','))}/edit`,
      { name: category.name }
    );
  }

  getAssetCategoryBreadcrumb(currentCategoryId: string): Observable<AssetCategory[]> {
    return this.get<AssetCategory[]>(`category/${currentCategoryId.replace(/\//g, ',')}/getBreadcrumb`);
  }

  editAssetAttribute(attribute: AssetAttribute): Observable<AssetAttribute> {
    return this.post<AssetAttribute>(`attribute/${attribute.assetAttributeId}/edit`, {
      showProminent: attribute.showProminent,
      required: attribute.required,
      name: attribute.name
    });
  }

  createAssetAttribute(
    name: string,
    assetCategoryId?: string,
    required?: boolean,
    showProminent?: boolean
  ): Observable<AssetAttribute> {
    return this.post<AssetAttribute>('attribute/create', { name, assetCategoryId, required, showProminent });
  }

  createAssetCertificateDefinition(
    categoryId: string,
    attributes: Partial<AssetCertificateDefinition>
  ): Observable<AssetCertificateDefinition> {
    return this.post<AssetCertificateDefinition>(
      `category/${encodeURIComponent(categoryId.replace(/\//g, ','))}/certificateDefinition/create`,
      attributes
    );
  }

  editAssetCertificateDefinition(
    certificateDefinition: AssetCertificateDefinition
  ): Observable<AssetCertificateDefinition> {
    return this.post<AssetCertificateDefinition>(
      `category/${encodeURIComponent(
        certificateDefinition.assetCategoryId.replace(/\//g, ',')
      )}/certificateDefinition/` + `${certificateDefinition.assetCertificateDefinitionId}/edit`,
      certificateDefinition
    );
  }

  deleteAssetCertificateDefinition(
    certificateDefinition: AssetCertificateDefinition
  ): Observable<AssetCertificateDefinition> {
    return this.post<AssetCertificateDefinition>(
      `category/${encodeURIComponent(
        certificateDefinition.assetCategoryId.replace(/\//g, ',')
      )}/certificateDefinition/` + `${certificateDefinition.assetCertificateDefinitionId}/delete`,
      {}
    );
  }

  createAssetHandoverInformation(
    name: string,
    type: number,
    assetCategoryId: string
  ): Observable<AssetHandoverInformation> {
    return this.post<AssetHandoverInformation>(
      `category/${encodeURIComponent(assetCategoryId.replace(/\//g, ','))}/handoverInformation/create`,
      {
        name,
        assetCategoryId,
        type
      }
    );
  }

  deleteAssetHandoverInformation(assetHandoverInformationId: number, assetCategoryId: string): Observable<boolean> {
    return this.post<boolean>(
      `category/${encodeURIComponent(
        assetCategoryId.replace(/\//g, ',')
      )}/handoverInformation/${assetHandoverInformationId}/delete`,
      {}
    );
  }

  editAssetHandoverInformation(
    assetHandoverInformation: AssetHandoverInformation,
    assetCategoryId: string
  ): Observable<boolean> {
    return this.post<boolean>(
      `category/${encodeURIComponent(assetCategoryId.replace(/\//g, ','))}/handoverInformation/${
        assetHandoverInformation.assetHandoverInformationId
      }/edit`,
      assetHandoverInformation
    );
  }

  getAllAssetCategories(start?: string): Observable<AssetCategory[]> {
    return this.post<AssetCategory[]>('category/all/get', { start });
  }

  getAssetAttributeValues(criteria?: { [s: string]: any }): Observable<ISequelizeCount<AssetAttributeValue>> {
    return this.post<ISequelizeCount<AssetAttributeValue>>('attribute/all/get', { criteria });
  }

  assignAsset(assetId: number, userId: number, force?: boolean): Observable<Asset> {
    return this.post<Asset>(`${assetId}/assign`, { userId, force });
  }

  getAllWarehouses(criteria?: any, options?: { includeStatistics: boolean }): Observable<Warehouse[]> {
    return this.post<Warehouse[]>('warehouse/all/get', { criteria, options });
  }

  /**
   * returns the number of assets in that warehouse that should be deleted or boolean state of the deletion
   *
   * @param warehouseId - the warehouse's id that should be deleted
   * @param fallbackWarehouseId - the warehouse's id that all assets should be transfered to (optional)
   */
  deleteWarehouse(warehouseId: number, fallbackWarehouseId?: number): Observable<boolean | { assetCount: number }> {
    return this.post<boolean | { assetCount: number }>(`warehouse/${warehouseId}/delete`, { fallbackWarehouseId });
  }

  editWarehouse(warehouse: Warehouse): Observable<Warehouse> {
    return this.post<Warehouse>(`warehouse/${warehouse.warehouseId}/edit`, {
      name: warehouse.name,
      siteId: warehouse.siteId,
      userIds: warehouse.users.map((u) => u.userId)
    });
  }

  createWarehouse(name: string, users?: number[], siteId?: number): Observable<Warehouse> {
    return this.post<Warehouse>('warehouse/create', { name, users, siteId }).pipe((warehouse) => {
      this.cachingService.invalidateCache('getAllWarehouses');
      return warehouse;
    });
  }

  createAssetCategoryRequirement(documentId: number, assetCategoryId: string): Observable<AssetCategoryRequirement> {
    return this.post<AssetCategoryRequirement>(
      `category/${encodeURIComponent(assetCategoryId.replace(/\//g, ','))}/requirement/create`,
      {
        documentSubtypeId: documentId
      }
    );
  }

  downloadAllAssets(criteria: any, additionalColumns: string[] = []): void {
    this.performDownload('asset/all/export', {
      criteria: JSON.stringify(criteria),
      additionalColumns: JSON.stringify(additionalColumns)
    });
  }

  downloadCertificate(certificate: AssetCertificate): void {
    this.performDownload(`asset/${certificate.assetId}/certificate/${certificate.assetCertificateId}/download`);
  }

  downloadQRCodes(size: number, printer: string, criteria?: any): void {
    this.performDownload(`asset/all/qr/get`, { size, printer, criteria: JSON.stringify(criteria) });
  }

  downloadQRCode(assetId: number): void {
    this.performDownload(`asset/${assetId}/qr/get`);
  }

  startConfirmTakeover(assetId: number): Observable<Step> {
    return this.post(`${assetId}/startConfirmTakeover`, {});
  }

  startConfirmTake(assetId: number): Observable<Step> {
    return this.post(`${assetId}/startConfirmTake`, {});
  }

  startConfirmReturn(assetId: number): Observable<Step> {
    return this.post(`${assetId}/startConfirmReturn`, {});
  }

  startReportDamage(assetId: number): Observable<Step> {
    return this.post(`${assetId}/startReportDamage`, {});
  }

  getDistinctAssetAttributes(): Observable<AssetAttribute[]> {
    return this.get(`attribute/getDistinctNames`);
  }

  getAssetAttributesByCategory(categoryIdStartsWith: string): Observable<AssetAttribute[]> {
    return this.post(`attribute/getAttributesByCategory`, { categoryId: categoryIdStartsWith });
  }

  getUnassignedCertificates(): Observable<AssetCertificate[]> {
    return this.get(`all/unassigned`);
  }

  editAssetCertificate(cert: AssetCertificate): Observable<AssetCertificate> {
    return this.post(`certificate/${cert.assetCertificateId}/edit`, cert);
  }
  deleteAssetCertificate(cert: AssetCertificate): Observable<AssetCertificate> {
    return this.post(`certificate/${cert.assetCertificateId}/delete`, cert);
  }

  deleteUnassignedAssetCertificate(cert: AssetCertificate): Observable<boolean> {
    return this.post(`certificate/unassigned/${cert.assetCertificateId}/delete`, cert);
  }

  getPendingRequests(): Observable<ISequelizeCount<any>> {
    return this.get(`all/pendingRequests`);
  }

  /**
   * Each vessel should have one Asset of Alcotest and Safety Folder etc. board. This function retrieves the status for each vessel and Asset of the choosen category.
   *
   * Request params are:
   *
   * categoryIds if the vessels, e.g. ['2/1','2/2']
   *
   * categoryId of the asset, e.g. 3 for Alcotest devices
   *
   * certificateDefinitionId of the asset certificate, e.g. 4 for SA12 certificate
   *
   * assetType is defined with const ASSET_TYPE, its a number that defines which vessel category is included. False or empty otherwise. Only these vessels are in the response. In this case the vessels are defined by setting the asset attribute 170
   *
   * @param categoryIds string[]
   * @param certificateDefinitionId number
   * @param categoryId number
   * @param gasdetector default = false
   */
  checkVesselAssetCertificateStatus(
    categoryIds: string[],
    certificateDefinitionId: number,
    categoryId: number,
    assetType: number = 0
  ): Observable<VesselDataResult> {
    return this.get('certificate/checkVesselAssetCertificateStatus', {
      params: { categoryIds, certificateDefinitionId, categoryId, assetType }
    });
  }

  checkVesselCertificateValidByAssetId(criteria?: any): BackendObservable<ISequelizeCount<any>> {
    return this.post<ISequelizeCount<any>>('certificate/checkVesselCertificateValidByAssetId', { criteria });
  }

  deleteAssetPhoto(assetPhoto: AssetPhoto) {
    return this.post(`photo/${assetPhoto.assetPhotoId}/delete`, assetPhoto);
  }

  deleteAssetAndReferences(assetId: number): BackendObservable<{ relatedAssets: Asset[] | boolean }> {
    return this.post(`${assetId}/delete`, {});
  }

  getAllVesselsCached(): Observable<ISequelizeCount<Asset>> {
    return this.cachingService.createCachingSubscription(
      'getAllVesselsCached',
      this.post<ISequelizeCount<Asset>>('all/get', { assetCategoryId: { $like: '2/%' } })
    );
  }

  post<T>(url: string, body: any, options?: any): BackendObservable<T> {
    return super.post<T>(`asset/${url}`, body, options);
  }

  get<T>(url: string, options?: any): Observable<T> {
    return super.get<T>(`asset/${url}`, options);
  }
}

export class _AbstractCategoryTree<T> {
  children?: _AbstractCategoryTree<T>[] = [];
  parent?: _AbstractCategoryTree<T>;

  constructor(data: T) {
    const proto = { ..._AbstractCategoryTree.prototype };
    Object.assign(proto, Object.getPrototypeOf(data));
    Object.setPrototypeOf(this, proto);
    Object.assign(this, data);
  }

  static convertToHierachial<T>(
    elements: T[] | _AbstractCategoryTree<T>[],
    primaryKey: keyof T
  ): AbstractCategoryTree<T>[] {
    elements = JSON.parse(JSON.stringify(elements)) as _AbstractCategoryTree<T>[];
    const groupsHirachial = [];
    for (const group of elements) {
      if (typeof (group as any)[primaryKey] !== 'string') {
        throw Error('Could not convert to AbstractCategoryTree');
      }
      const splitted = ((group as any)[primaryKey] as string).split('/');
      if (splitted.length > 1) {
        const parentId = splitted.splice(0, splitted.length - 1).join('/');
        const parent = elements.find((g) => (g as any)[primaryKey] === parentId);
        if (parent) {
          group.parent = parent;
          parent.children = parent.children || [];
          parent.children.push(group);
          continue;
        }
      }
      groupsHirachial.push(group);
    }
    return groupsHirachial;
  }
}

export type AbstractCategoryTree<T> = _AbstractCategoryTree<T> & T;
export const AbstractCategoryTree: new <T>(data: T) => AbstractCategoryTree<T> = _AbstractCategoryTree as any;

export class AssetCategoryTree extends AbstractCategoryTree<AssetCategory> {
  assets?: Asset[];
}
