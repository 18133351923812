import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-justto-do',
  templateUrl: './justto-do.component.html',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, MatIconModule]
})
export class JustToDoComponent {
  environment = environment.environment;
}
