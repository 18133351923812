export function clone<T>(element: T): T {
  if (!element) {
    return element;
  }
  return Object.assign({}, element);
}
export function cloneJSON<T>(element: T): T {
  if (!element) {
    return element;
  }
  return JSON.parse(JSON.stringify(element));
}

export function deepCopy<T>(inObject: T): T {
  let value: any;
  let key: any;

  if (typeof inObject !== 'object' || inObject === null) {
    return inObject; // Return the value if inObject is not an object
  }

  // Create an array or object to hold the values
  let outObject: any = Array.isArray(inObject) ? [] : {};

  for (key in inObject) {
    if (inObject.hasOwnProperty(key)) {
      value = inObject[key];

      // Recursively (deep) copy for nested objects, including arrays
      outObject[key] = deepCopy(value);
    }
  }
  if (inObject.constructor && !Array.isArray(inObject)) {
    outObject = Object.assign(Object.create(Object.getPrototypeOf(inObject)), inObject);
  }
  return outObject;
}

export const getReflectorAsArray = (reflectorString: string): string[] =>
  reflectorString.split(/[.\[]/).map((d) => d.replace(']', ''));

export const getArrayIndexesOfReflectorString = (reflectorString: string): number[] => {
  const reflector = reflectorString.split(/[.\[]/).filter((s: string) => s.indexOf(']') > 0);
  return reflector.map((r) => Number(r.replace(']', '')));
};

export const getValueByReflector = (data: any, reflectorString: string, leaveArray?: boolean): any => {
  let reflector = reflectorString.split(/[.\[]/);
  if (leaveArray) {
    reflector = reflector.filter((s: string) => !(s.indexOf(']') > 0));
  }
  let val = data;
  for (const ref of reflector) {
    if (val === undefined) {
      break;
    }
    val = val[ref.replace(']', '')];
  }
  return val;
};

export const setValueByReflector = (data: any, reflectorString: string, value: any) => {
  const reflector = getReflectorAsArray(reflectorString);
  const last = reflector.pop();
  let val = data;
  for (const ref of reflector) {
    val = val[ref.replace(']', '')];
  }
  val[last] = value;
  return data;
};
