/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { NotFoundComponent } from './components/not-found/not-found.component';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./views/authentication/authentication-views.module').then((m) => m.AuthenticationModule)
  },
  {
    path: 'assets',
    loadChildren: () => import('./views/assets/assets-views.module').then((m) => m.AssetViewsModule),
    canActivate: [AuthGuard],
    data: { role: ['Admin', 'SuperAdmin', 'Management', 'HSSEQ', 'Chartering', 'Technik'] }
  },
  {
    path: 'calendar',
    loadChildren: () => import('./views/calendar/calendar-views.module').then((m) => m.CalendarModule),
    canActivate: [AuthGuard],
    data: { role: ['Admin', 'SuperAdmin', 'Technik'] }
  },
  {
    path: 'emergency',
    loadChildren: () => import('./views/emergency/emergency.component').then((m) => m.EmergencyComponentModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'equipment',
    loadChildren: () => import('./views/equipment/equipment.module').then((m) => m.EquipmentModule),
    canActivate: [AuthGuard],
    data: { role: ['SuperAdmin', 'Verwaltung', 'Zulieferer'] }
  },
  {
    path: 'inspector',
    loadChildren: () => import('./views/inspector/inspector-views.module').then((m) => m.InspectorViewsModule),
    canActivate: [AuthGuard],
    data: { role: ['Admin', 'SuperAdmin', 'Technik'] }
  },
  {
    path: 'management',
    loadChildren: () => import('./views/management/management-views.module').then((m) => m.SMVViewsModule),
    canActivate: [AuthGuard],
    data: { role: ['Admin', 'SuperAdmin', 'Management', 'HSSEQ', 'Chartering', 'Technik'] }
  },
  {
    path: 'support',
    loadChildren: () => import('./views/support/support-views.module').then((m) => m.SupportViewsModule),
    canActivate: [AuthGuard],
    data: { role: ['Admin', 'SuperAdmin'] }
  },
  {
    path: 'user',
    loadChildren: () => import('./views/user/user-views.module').then((m) => m.UserModule),
    canActivate: [AuthGuard],
    data: {
      role: [
        'Admin',
        'SuperAdmin',
        'Management',
        'Chartering',
        'HSSEQ',
        'Technik',
        'Besatzungsmitglied',
        'Verwaltung',
        'Extern',
        'Zulieferer',
        'Lagerverwaltung'
      ]
    }
  },
  {
    path: 'vessel',
    loadChildren: () => import('./views/site-vessel/site-views.module').then((m) => m.SiteViewsModule),
    canActivate: [AuthGuard],
    data: {
      role: [
        'Admin',
        'SuperAdmin',
        'Management',
        'HSSEQ',
        'Schiffsführer',
        'Chartering',
        'Technik',
        'Verwaltung',
        'Extern'
      ]
    }
  },
  {
    path: 'visits-reports',
    loadChildren: () =>
      import('./views/visits-reports/visit-report-views.module').then((m) => m.VisitReportViewsModule),
    canActivate: [AuthGuard],
    data: { role: ['Admin', 'SuperAdmin', 'Management', 'HSSEQ', 'Chartering', 'Technik', 'Extern'] }
  },
  {
    path: 'warehouse',
    loadChildren: () => import('./views/warehouse/warehouse.module').then((m) => m.WarehouseViewsModule),
    canActivate: [AuthGuard],
    data: { role: ['Lagerverwaltung', 'Verwaltung', 'SuperAdmin'] }
  },
  { path: '', redirectTo: '/user/dashboard', pathMatch: 'full' },
  { path: 'not-found', component: NotFoundComponent },
  {
    path: '**',
    loadComponent: () => import('./components/not-found/not-found.component').then((m) => m.NotFoundComponent)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
