import {
  Component,
  OnInit,
  Input,
  ComponentFactoryResolver,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef
} from '@angular/core';
import { INPUT_TYPES } from '../step-handle-manual-input/step-handle-manual-input.component';
import { Subject } from 'rxjs';
import { WarehouseOutlineComponent } from '../../warehouse-outline/warehouse-outline.component';
import { first } from 'rxjs/operators';
import { AssetOutlineComponent } from '../../asset-outline/asset-outline.component';
import { ProcessHandlerService } from '../../process-handler-dialog/process-handler.service';
import { UserService } from 'src/app/services/user.service';
import { DataField } from 'src/app/interfaces/process/data-field.interface';
import { UserOutlineComponent } from 'src/app/components/user-outline/user-outline.component';
import { ViewContainerDirective } from 'src/app/process-engine/directives/view-container.directive';
import { AssetService } from 'src/app/services/asset.service';
import { ProcessService } from 'src/app/services/process.service';
import { CommonModule, DatePipe } from '@angular/common';
import { Nl2brPipe } from 'src/app/pipes/nl2br.pipe';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { config } from 'src/config';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-data-field',
  templateUrl: './data-field.component.html',
  styles: [],
  standalone: true,
  imports: [CommonModule, Nl2brPipe, MatListModule, MatTooltipModule, NgxExtendedPdfViewerModule]
})
export class DataFieldComponent implements OnInit, AfterViewInit {
  @Input() dataField: DataField;
  @Input() overrideDownloadPath: string;
  // ToDo: only use this, but I need to check where this component is used and how it currently looks
  @Input() noDate: boolean;

  @ViewChild(ViewContainerDirective) templateHost: ViewContainerDirective;

  backendUrl = config.apiUrl;
  value: any;
  editing = false;
  isUsingTemplate = false;
  isEmail = false;
  isPhone = false;
  fileUrl: string;
  aisInfo: string;

  readonly inputTypes = INPUT_TYPES;

  $afterViewInit: Subject<any> = new Subject<any>();

  private viewInited = false;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private cd: ChangeDetectorRef,
    private processService: ProcessService,
    private userService: UserService,
    private assetService: AssetService,
    private readonly helper: HelperService,
    private readonly processHandlerService: ProcessHandlerService,
    private readonly datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    if (this.dataField) {
      this.value = this.dataField.value;
      const valueType = this.processHandlerService.checkPhoneEmail(this.value);
      if (valueType === 'mail') {
        this.isEmail = true;
      } else if (valueType === 'tel') {
        this.isPhone = true;
      }

      if (this.dataField.name.includes('Vessel') && Number(this.value)) {
        // translate assetid in older reports to name
        this.assetService.getAsset(Number(this.value)).subscribe((asset) => (this.value = asset.name));
      }

      this.processService.getEnhanceableDataFieldDefinitionIds().subscribe((d) => {
        for (const attribute of Object.keys(d)) {
          if (d.hasOwnProperty(attribute)) {
            if (d[attribute].includes(this.dataField.data_field_definition_id)) {
              switch (attribute) {
                case 'USER_DATA_FIELD_ID':
                  this.enhance(() => this.loadUserOutline());
                  return;
                case 'ASSET_DATA_FIELD_ID':
                  this.enhance(() => this.loadAssetOutline());
                  break;
                case 'ASSET_WAREHOUSE_DATA_FIELD_ID':
                  this.enhance(() => this.loadWarehouseOutline());
                  return;
                case 'ASSET_CATEGORY_DATA_FIELD_ID':
                  break;
              }
            }
          }
        }
      });
      if (this.dataField.type === this.inputTypes.DATE) {
        if (this.value !== 'Invalid date') {
          const utcDate = new Date(this.value);
          this.value = this.datePipe.transform(utcDate, 'medium');
        } else {
          this.value = '-';
        }
      }
    }
  }

  calcOutdated(date: string): string {
    const dateObj = new Date(date);
    const condition = this.helper.calculateCondition(date);
    switch (condition) {
      case 1:
        return '';
      case 3:
        this.aisInfo = '- älter 2h';
        return 'bg-warn-85';
      case 5:
        this.aisInfo = '- älter 12h';
        return 'bg-error-85';
    }
  }

  ngAfterViewInit(): void {
    this.viewInited = true;
    this.$afterViewInit.next(true);
  }

  downloadFile(fileUrl) {
    window.open(fileUrl);
  }

  getPicturePath(fileId: number, file: any) {
    if (!file.url) {
      const pathParts = file.path.split('/');
      const filename = pathParts.pop();
      const processId = pathParts.pop();
      file.url = `${this.backendUrl}processEngine/process/${processId}/${filename}/${file.mimetype.split('/')[1]}/get`;
    }
    return file.url;
  }

  private enhance(callback: () => void): void {
    if (this.viewInited) {
      callback();
      return;
    }
    this.$afterViewInit.pipe(first()).subscribe(() => {
      callback();
    });
  }

  private loadUserOutline(): void {
    this.userService
      .getAllUsers({
        userId: this.dataField.value
      })
      .subscribe((u) => {
        if (!u.count) {
          return;
        }
        this.isUsingTemplate = true;
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(UserOutlineComponent);

        const viewContainerRef = this.templateHost.viewContainerRef;
        viewContainerRef.clear();

        const componentRef = viewContainerRef.createComponent(componentFactory);
        componentRef.instance.user = u.rows.shift();
        componentRef.instance.size = 'normal';
        this.cd.detectChanges();
      });
  }

  private loadAssetOutline(): void {
    this.assetService.getAsset(Number(this.dataField.value)).subscribe((asset) => {
      if (!asset) {
        return;
      }
      this.isUsingTemplate = true;
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(AssetOutlineComponent);

      const viewContainerRef = this.templateHost?.viewContainerRef;
      if (viewContainerRef) {
        viewContainerRef.clear();

        const componentRef = viewContainerRef.createComponent(componentFactory);
        componentRef.instance.asset = asset;
        componentRef.instance.size = 'normal';
      }
      this.cd.detectChanges();
    });
  }

  private loadWarehouseOutline(): void {
    this.assetService
      .getAllWarehouses({
        warehouseId: this.dataField.value
      })
      .subscribe((w) => {
        if (!w.length) {
          return;
        }
        this.isUsingTemplate = true;
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(WarehouseOutlineComponent);

        const viewContainerRef = this.templateHost.viewContainerRef;
        viewContainerRef.clear();

        const componentRef = viewContainerRef.createComponent(componentFactory);
        componentRef.instance.warehouse = w.shift();
        this.cd.detectChanges();
      });
  }
}
