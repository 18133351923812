<ng-container *transloco="let t">
  <h1 mat-dialog-title>{{ t('process.already_running.found') }}</h1>
  <mat-dialog-content>
    {{ t('process.already_running.info', { phase: data.alreadyRunningSteps[0]?.process.phase }) }}
    <app-running
      [processPhase]="data.alreadyRunningSteps[0]?.process.phase"
      [showInputFilter]="false"
      [displayedColumns]="['process.title', 'title', 'process.id_code_2', 'visitor', 'actions']"
      [runningSteps]="data.alreadyRunningSteps"
      [expansionTableData]="expansionTableData"
      [hideProcessType]="true"
      (processDialogClosed)="processClosed($event)"
      (deleteEvent)="onDelete($event)"
    ></app-running>
  </mat-dialog-content>
  <mat-dialog-actions class="flex justify-end">
    <button mat-raised-button mat-dialog-close>{{ t('general.close') }}</button>
    <button mat-raised-button [mat-dialog-close]="{ startNew: true }" color="primary">
      {{ t('general.start_new') }}
    </button>
  </mat-dialog-actions>
</ng-container>
