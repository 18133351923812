import { Injectable } from '@angular/core';

// ToDo: solve differently?
@Injectable({ providedIn: 'root' })
export class TranslationKeeperService {
  private translations;

  getTranslations() {
    return this.translations;
  }

  setTranslations(data) {
    this.translations = data;
  }
}
