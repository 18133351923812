<ng-container *transloco="let t">
  <mat-card>
    <mat-card-title class="gap-xs">
      <ng-container *ngIf="processPhase === 'visits'">
        {{ t('site.visits.currently_running') }}
      </ng-container>
      <ng-container *ngIf="processPhase === 'reports'">
        {{ t('site.reports.currently_running') }}
      </ng-container>
    </mat-card-title>
    <mat-card-content>
      <mat-button-toggle-group
        *ngIf="!hideProcessType"
        [(ngModel)]="processType"
        (change)="toggleProcessType()"
        [value]="processType"
      >
        <mat-button-toggle value="own">{{ t('site.onlyOwn') }}</mat-button-toggle>
        <mat-button-toggle value="all">{{ t('modules.user.document-status.export.full') }}</mat-button-toggle>
      </mat-button-toggle-group>
      <app-expansion-table
        (stepPerformed)="allStepsPerformed($event)"
        [tableData]="filteredExpansionTableData"
        [processDialog]="showActionButton"
        [subscription]="activeSubscription"
        (deleteEvent)="deleteEvent.emit($event)"
        class="visible-sm"
      ></app-expansion-table>
      <app-process-list
        class="hidden-sm"
        [showInputFilter]="showInputFilter"
        [subscription]="activeSubscription"
        [steps]="filteredRunningSteps"
        (stepPerformed)="allStepsPerformed($event)"
        (filterChanged)="filterChanged($event)"
        [visibleColumns]="displayedColumns"
        (deleteEvent)="deleteEvent.emit($event)"
      ></app-process-list>
      <mat-progress-bar *ngIf="subscriptionIsActive(activeSubscription)" [mode]="'indeterminate'"></mat-progress-bar>
    </mat-card-content>
  </mat-card>
</ng-container>
