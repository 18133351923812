<ng-container *transloco="let t">
  <h1 mat-dialog-title>
    <div class="flex space-between">
      <ng-container *ngIf="!webcamImage">Foto aufnehmen</ng-container>
      <ng-container *ngIf="webcamImage">Zufrieden?</ng-container>
      <button mat-button (click)="onClick(null)">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </h1>
  <div mat-dialog-content style="overflow: hidden; padding: unset">
    <div class="video-container flex align-center justify-center">
      <webcam
        *ngIf="!webcamImage"
        #video
        [trigger]="triggerObservable"
        (imageCapture)="handleImage($event)"
        id="preview"
      ></webcam>
      <img *ngIf="webcamImage" [src]="webcamImage" />
    </div>
    <button class="floating-button" *ngIf="!webcamImage" mat-fab (click)="triggerSnapshot()">
      <mat-icon class="material-symbols-outlined">circle</mat-icon>
    </button>
  </div>
  <div mat-dialog-actions *ngIf="webcamImage" class="flex space-between">
    <button mat-raised-button (click)="webcamImage = null">Wiederholen</button>
    <button mat-raised-button color="primary" (click)="onAccept()">Übernehmen</button>
  </div>
</ng-container>
