import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent {
  message = '';
  action = '';
  duration: 3;
  animationString: SafeStyle;

  constructor(@Inject(MAT_SNACK_BAR_DATA) data: any, protected sanitizer: DomSanitizer) {
    this.message = data.message;
    this.action = data.action;
    this.animationString =
      'animation: snackProgress ' + (data.duration ? data.duration : this.duration) + '.1s linear forwards 0s;';
  }
}
