import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslocoModule } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { subscriptionIsActive } from 'src/app/services/backend.service';
import { ProcessListComponentModule } from 'src/app/components/process-list/process-list.component';
import { ExpansionTableComponent } from '../../expansion-table/expansion-table.component';
import { ExpansionTableData } from '../../expansion-table/interfaces/expansion-table-data.interface';
import { MemoryService } from 'src/app/services/memory.service';
import { Step } from 'src/app/interfaces/process/step.interface';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FormsModule } from '@angular/forms';
import { ProcessFinishedListComponent } from '../../process-list-finished/process-finished-list.component';

@Component({
  selector: 'app-running',
  templateUrl: './running.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    ProcessListComponentModule,
    ProcessFinishedListComponent,
    ExpansionTableComponent,
    MatPaginatorModule,
    FormsModule,
    MatProgressBarModule,
    MatButtonToggleModule,
    TranslocoModule
  ]
})
export class RunningComponent implements OnChanges {
  @Input() processPhase: string;
  @Input() runningSteps: Step[];
  @Input() expansionTableData: ExpansionTableData[];
  @Input() activeSubscription: Subscription;
  @Input() showInputFilter = true;
  @Input() displayedColumns: string[] = ['title', 'id_code_1', 'executed', 'actions'];
  @Input() allUserProcesses: boolean;
  @Input() hideProcessType: boolean;
  @Output() processDialogClosed = new EventEmitter<boolean>();
  @Output() criteriaFilterChanged = new EventEmitter<{ [s: string]: any }>();
  @Output() deleteEvent = new EventEmitter<{
    event: { processId: number; title: string; vessel: string; date: Date };
  }>();

  filteredExpansionTableData: ExpansionTableData[];
  filteredRunningSteps: Step[];
  processType = 'own';
  filter: { [s: string]: any } = {};
  subscriptionIsActive = subscriptionIsActive;
  limit = 25;
  offset = 0;
  roleId: number;
  userId: number;
  showActionButton = true;

  private inputFilterTimeout: number;

  constructor(memoryService: MemoryService) {
    this.roleId = memoryService.getLoginData().roleId;
    this.userId = memoryService.getLoginData().userId;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // set if the action button should be shown in the mobile view.
    // for reports a admin can start the process but can not resume.
    // If the process Proceed button is shown it can still be set to disabled by the canResume property.
    if (changes.processPhase) {
      if (this.processPhase === 'Reports') {
        this.showActionButton = false;
      } else {
        this.showActionButton = true;
      }
    }
    if (changes.runningSteps && this.runningSteps) {
      this.toggleProcessType();
    }
    if (changes.expansionTableData && this.expansionTableData) {
      this.toggleProcessType();
    }
  }

  toggleProcessType() {
    if (this.processType === 'own') {
      this.filteredRunningSteps = this.runningSteps?.filter((step) =>
        step.visitor?.toString().includes(this.userId.toString())
      );
      this.filteredExpansionTableData = this.expansionTableData?.filter((entry) => entry.userId === this.userId);
    } else {
      this.filteredRunningSteps = [...this.runningSteps];
      this.filteredExpansionTableData = [...this.expansionTableData];
    }
  }

  allStepsPerformed(event) {
    this.processDialogClosed.emit(event);
  }

  filterChanged(filter: { [s: string]: any }): void {
    this.criteriaFilterChanged.emit(filter);
  }

  inputFilterChanged(event: { start: string; end: string }): void {
    const start = event.start;
    const end = event.end;

    clearTimeout(this.inputFilterTimeout);

    this.inputFilterTimeout = window.setTimeout(() => {
      this.filter = { executed: { $lte: end, $gte: start } };
    }, 500);
  }

  onPageChange(event: PageEvent) {
    if (event.previousPageIndex !== event.pageIndex) {
      this.offset = event.pageIndex * event.pageSize;
    }
    if (this.limit !== event.pageSize) {
      this.limit = event.pageSize;
    }
  }
}
