<ng-container *transloco="let t">
  <h1 mat-dialog-title>{{ t('google.confirm.title') }}</h1>
  <div mat-dialog-content style="min-width: 300px">
    @if (!data.confirmation) {
      <mat-radio-group [(ngModel)]="optionSelected">
        <mat-card class="margin-xs-bottom">
          <mat-radio-button value="0">
            <mat-card-title class="gap-xs">
              {{ t('google.confirm.input') }}
            </mat-card-title>
            <mat-card-content>
              {{ data.initialAddress }}
            </mat-card-content>
          </mat-radio-button>
        </mat-card>
        <mat-card>
          <mat-radio-button value="1">
            <mat-card-title class="gap-xs">
              {{ t('google.confirm.recommendation') }}
            </mat-card-title>
            <mat-card-content>
              @for (part of data.addressComponents; track $index; let idx = $index) {
                @if (part.confirmationLevel === 'UNCONFIRMED_BUT_PLAUSIBLE') {
                  <span class="bg-notification">{{ part.componentName.text }}</span>
                } @else if (part.confirmationLevel === 'CONFIRMED' && part.replaced) {
                  <span class="bg-notification">{{ part.componentName.text }}</span>
                } @else {
                  <span>{{ part.componentName.text }}</span>
                }
                @if (idx % 2) {
                  <span>,&nbsp;</span>
                } @else {
                  <span>&nbsp;</span>
                }
              }
            </mat-card-content>
          </mat-radio-button>
        </mat-card>
      </mat-radio-group>
    } @else {
      <mat-card class="margin-xs-bottom">
        <mat-card-title class="gap-xs">
          {{ t('google.confirm.input') }}
        </mat-card-title>
        <mat-card-content>
          @for (part of data.addressComponents; track $index; let idx = $index) {
            @if (part.confirmationLevel === 'UNCONFIRMED_BUT_PLAUSIBLE') {
              <span class="bg-warn-85">{{ part.componentName.text }}</span>
            } @else if (part.confirmationLevel === 'UNCONFIRMED_AND_SUSPICIOUS') {
              <span class="bg-error-85">{{ part.componentName?.text }}</span>
            } @else {
              <span>{{ part.componentName.text }}</span>
            }
            @if (idx % 2) {
              <span>,&nbsp;</span>
            } @else {
              <span>&nbsp;</span>
            }
          }
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-title class="gap-xs">
          {{ t('google.confirm.problem') }}
        </mat-card-title>
        <mat-card-content>
          <div class="flex column width-100">
            @for (part of data.addressComponents; track $index; let idx = $index) {
              @if (part.confirmationLevel === 'UNCONFIRMED_BUT_PLAUSIBLE') {
                <div class="bg-warn-85 radius-4px gap-xxs flex">
                  <mat-icon class="material-symbols-outlined">warning</mat-icon>
                  <div>
                    <p>{{ translateProblems(part.confirmationLevel, part.componentType) }}</p>
                    <p>{{ part.componentName?.text || '-' }}</p>
                  </div>
                </div>
              } @else if (part.confirmationLevel === 'UNCONFIRMED_AND_SUSPICIOUS') {
                <div class="bg-error-85 radius-4px gap-xxs flex">
                  <mat-icon class="material-symbols-outlined">warning</mat-icon>
                  <div>
                    <p>{{ translateProblems(part.confirmationLevel, part.componentType) }}</p>
                    <p>{{ part.componentName?.text || '-' }}</p>
                  </div>
                </div>
              } @else if (part.confirmationLevel === 'CONFIRMED' && part.unexpected) {
                <div class="bg-error-85 radius-4px gap-xxs flex">
                  <mat-icon class="material-symbols-outlined">warning</mat-icon>
                  <div>
                    <p>{{ translateProblems(part.confirmationLevel, part.componentType, true) }}</p>
                    <p>{{ part.componentName?.text || '-' }}</p>
                  </div>
                </div>
              }
            }
          </div>
        </mat-card-content>
      </mat-card>
    }
  </div>
  <div mat-dialog-actions [align]="'end'">
    @if (data.confirmation) {
      <button mat-raised-button [disabled]="hideProceed" (click)="onClick(true)">
        {{ t('general.confirm') }}
      </button>
      <button mat-raised-button mat-dialog-close cdkFocusInitial color="primary">
        {{ t('general.edit') }}
      </button>
    } @else {
      <button mat-raised-button mat-dialog-close>{{ t('general.cancel') }}</button>
      <button
        mat-raised-button
        [disabled]="hideProceed || !optionSelected"
        (click)="onClick(true)"
        cdkFocusInitial
        color="primary"
      >
        {{ t('general.confirm') }}
      </button>
    }
  </div>
</ng-container>
