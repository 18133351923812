<mat-card appearance="outlined">
  <mat-card-header class="gap-xs">
    <mat-card-title>Seite nicht gefunden :(</mat-card-title>
  </mat-card-header>
  <mat-card-content> </mat-card-content>
  <hr />
  <mat-card-footer>
    <button mat-button color="primary"><mat-icon>navigate_before</mat-icon>zurück</button>
    <button mat-button color="primary" routerLink="/"><mat-icon>home</mat-icon> zur Startseite</button>
  </mat-card-footer>
</mat-card>
