<table mat-table [dataSource]="steps" *transloco="let t">
  <ng-container matColumnDef="process.title">
    <th mat-header-cell *matHeaderCellDef>
      <mat-form-field [subscriptSizing]="'dynamic'" class="form-field-small" *ngIf="showInputFilter; else processElse">
        <input
          matInput
          placeholder="{{ t('process.title') }}"
          [(ngModel)]="filter['$process.title$']"
          (keyup)="inputFilterChanged('process.title')"
        />
      </mat-form-field>
      <ng-template #processElse>{{ t('process.title') }}</ng-template>
    </th>
    <td mat-cell *matCellDef="let document">{{ document.process.title }}</td>
  </ng-container>
  <ng-container matColumnDef="process.phase">
    <th mat-header-cell *matHeaderCellDef>
      {{ t('process.phase') }}
    </th>
    <td mat-cell *matCellDef="let document">
      {{ document.phase }}
    </td>
  </ng-container>
  <ng-container matColumnDef="combined_deadline">
    <th mat-header-cell *matHeaderCellDef>
      {{ t('process.combined_deadline') }}
    </th>
    <td mat-cell *matCellDef="let document">
      <ng-container *ngIf="document.combined_deadline">
        {{ document.combined_deadline | date : 'short' }}
      </ng-container>
      <ng-container *ngIf="!document.combined_deadline">{{ t('process.no_deadline') }}</ng-container>
    </td>
  </ng-container>
  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef>
      <mat-form-field [subscriptSizing]="'dynamic'" class="form-field-small" *ngIf="showInputFilter; else titleElse">
        <input
          matInput
          placeholder="{{ t('process.step.title') }}"
          [(ngModel)]="filter.title"
          (keyup)="inputFilterChanged('title')"
        />
      </mat-form-field>
      <ng-template #titleElse>{{ t('process.step.title') }}</ng-template>
    </th>
    <td mat-cell *matCellDef="let document">
      {{ document.title }}
    </td>
  </ng-container>
  <ng-container matColumnDef="process.id_code_1">
    <th mat-header-cell *matHeaderCellDef>
      <mat-form-field [subscriptSizing]="'dynamic'" class="form-field-small" *ngIf="showInputFilter; else idCode1Else">
        <input
          matInput
          placeholder="{{ t('expansion_table.vessel') }}"
          [(ngModel)]="filter['$process.id_code_1$']"
          (keyup)="inputFilterChanged('process.id_code_1')"
        />
      </mat-form-field>
      <ng-template #idCode1Else>{{ t('expansion_table.vessel') }}</ng-template>
    </th>
    <td mat-cell *matCellDef="let document">
      {{ document.process.id_code_1 }}
    </td>
  </ng-container>
  <ng-container matColumnDef="process.id_code_2">
    <th mat-header-cell *matHeaderCellDef>
      {{ t('expansion_table.from') }}
      <!-- <mat-form-field>
        <input
          matInput
          placeholder="{{ t('expansion_table.from') }}"
          [(ngModel)]="filter['$process.id_code_2$']"
          (keyup)="inputFilterChanged('process.id_code_2')"
        />
      </mat-form-field> -->
    </th>
    <td mat-cell *matCellDef="let document">
      {{ document.process.id_code_2 | date }}
    </td>
  </ng-container>
  <ng-container matColumnDef="responsible_role">
    <th mat-header-cell *matHeaderCellDef>
      {{ t('process.responsible_role') }}
    </th>
    <td mat-cell *matCellDef="let document">
      {{ document.responsible_role | roleName | async }}
    </td>
  </ng-container>
  <ng-container matColumnDef="visitor">
    <th mat-header-cell *matHeaderCellDef>{{ t('expansion_table.visitor') }}</th>
    <td mat-cell *matCellDef="let document">
      {{ document.visitor | idToName | async }}
    </td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let step">
      <div class="flex">
        <button mat-icon-button color="primary" [disabled]="!step.canResume" (click)="openStep(step)">
          <mat-icon class="material-symbols-outlined">resume</mat-icon>
        </button>
        <button
          mat-icon-button
          color="warn"
          [disabled]="!step.canResume"
          (click)="
            deleteEvent.emit({
              processId: step.process.id,
              title: step.process.title,
              vessel: step.process.id_code_1,
              date: step.process.id_code_2
            })
          "
        >
          <mat-icon class="material-symbols-outlined">delete</mat-icon>
        </button>
      </div>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="visibleColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: visibleColumns"></tr>
</table>
<!-- ToDo: Progress Bar not showing when no subscription is there -->
<!-- <mat-progress-bar
  *ngIf="subscriptionIsActive(subscription) || !steps?.length"
  [mode]="'indeterminate'"
></mat-progress-bar> -->
<div *ngIf="!subscriptionIsActive(subscription) && !steps?.length" class="gap-sm">Keine Ergebnisse</div>
