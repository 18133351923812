import { DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { ProcessEngineModule } from './process-engine/process-engine.module';
import '@angular/common/locales/global/de';
import { InterceptorService } from './http/interceptor.service';
import { TranslocoRootModule } from './transloco-root.module';
import { MatNativeDateModule } from '@angular/material/core';
import { TranslocoService } from '@ngneat/transloco';
import { NavigationComponent } from './components/navigation/navigation.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MtxLuxonDatetimeModule } from '@ng-matero/extensions-luxon-adapter';
import { MTX_DATETIME_FORMATS } from '@ng-matero/extensions/core';
import { provideLuxonDateAdapter } from '@angular/material-luxon-adapter';

export const LUXON_FORMATS = {
  parse: {
    dateInput: 'dd.LL.yyyy',
    monthInput: 'LLLL',
    yearInput: 'yyyy',
    timeInput: 'HH:mm',
    datetimeInput: 'dd.LL.yyyy HH:mm'
  },
  display: {
    dateInput: 'dd.LL.yyyy',
    monthInput: 'LLLL',
    yearInput: 'yyyy',
    timeInput: 'HH:mm',
    datetimeInput: 'dd.LL.yyyy HH:mm',
    monthYearLabel: 'LLLL yyyy',
    dateA11yLabel: 'DDD',
    monthYearA11yLabel: 'LLLL yyyy',
    popupHeaderDateLabel: 'LLL dd, ccc'
  }
};

@NgModule({
  declarations: [AppComponent, SnackbarComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HeaderComponent,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
    HttpClientModule,
    // need to import it here and in the desired compoenents to have a provider for: MAT_SELECT_SCROLL_STRATEGY
    // ToDo: set provider solely? Different solution?
    MatAutocompleteModule,
    MatNativeDateModule,
    NavigationComponent,
    TranslocoRootModule,
    ProcessEngineModule,
    MtxLuxonDatetimeModule,
    ServiceWorkerModule.register('push-sw.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  bootstrap: [AppComponent],
  providers: [
    DatePipe,
    provideLuxonDateAdapter(LUXON_FORMATS),
    {
      provide: MTX_DATETIME_FORMATS,
      useValue: LUXON_FORMATS
    },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    {
      // ToDo: make it dynmaic on language/locale change
      provide: LOCALE_ID,
      deps: [TranslocoService],
      useFactory: (translate: TranslocoService) => translate.getActiveLang()
    }
  ]
})
export class AppModule {}
