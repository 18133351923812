import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CachingService } from './caching.service';
import { Settings } from 'luxon';
import { TranslocoService } from '@ngneat/transloco';
import { NotificationService } from './notification.service';
import { HttpClient } from '@angular/common/http';
import { config } from 'src/config';
import { Router } from '@angular/router';
import { User, UserLogin } from '../interfaces/user/user.interface';

@Injectable({
  providedIn: 'root'
})
export class MemoryService {
  $languageChanged = new Subject<boolean>();
  $changeSideMenuVisibility = new Subject<boolean>();
  public $userLoginData = new Subject<UserLogin>();

  private userLoginData: UserLogin;

  constructor(
    private http: HttpClient,
    private notify: NotificationService,
    private router: Router,
    private cachingService: CachingService,
    private translocoService: TranslocoService
  ) {
    this.userLoginData = this.getParsed('userdata');

    if (this.userLoginData) {
      this.$userLoginData.next(this.userLoginData);
    }
  }

  getParsed(key: string): UserLogin {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (e) {
      localStorage.removeItem(key);
      console.error(e);
      return null;
    }
  }

  isLoggedIn(): boolean {
    return !!this.userLoginData;
  }

  getLoginToken(): string {
    if (this.userLoginData) {
      return this.userLoginData.loginToken;
    }
    return null;
  }

  setLoginData(userLogin?: UserLogin, user?: User) {
    const parsedUserData = this.getParsed('userdata');
    if (userLogin) {
      localStorage.setItem('userdata', JSON.stringify(userLogin));
      this.userLoginData = this.getParsed('userdata');
    }
    if (user) {
      parsedUserData.user = user;
      localStorage.setItem('userdata', JSON.stringify(parsedUserData));
      this.userLoginData = this.getParsed('userdata');
    }
  }

  getLoginData(): UserLogin {
    return this.userLoginData;
  }

  getRole(): string {
    return this.userLoginData.role.name;
  }

  setLoggedIn(userLoginData: UserLogin | false, message?: string): void {
    if (userLoginData) {
      this.userLoginData = userLoginData;
      localStorage.setItem('userdata', JSON.stringify(this.userLoginData));
    } else {
      this.logout(message);
    }
    this.$userLoginData.next(this.userLoginData);
  }

  logout(message?: string, options?: any) {
    const userData = this.getParsed('userdata');

    localStorage.removeItem('userdata');
    this.cachingService.invalidateAllCaches();
    this.userLoginData = undefined;
    this.router.navigate(['/auth/login']);

    if (userData) {
      options = options || {};
      options.headers = options.headers || {};
      options.observe = 'response';
      options.headers['login-token'] = userData.loginToken;

      this.http.post<any>(config.apiUrl + 'user/logout', null, options).subscribe(
        (e) => {
          // eslint-disable-next-line @typescript-eslint/dot-notation
          if (e['error']) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            this.notify.error(`Logout error: ${e['error'].message}`);
          } else {
            this.notify.message(message);
          }
        },
        (error) => this.notify.error(error.error.error.message)
      );
    } else {
      this.notify.error('Error, wrong token provided!');
    }
  }

  set(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  get(key: string): string {
    return localStorage.getItem(key);
  }

  setLanguage(): void {
    const lang = this.get('language');
    if (lang) {
      if (lang !== this.translocoService.getActiveLang()) {
        this.translocoService.setActiveLang(lang);
        this.setLocale(lang);
      }
    }
  }

  changeLanguage(id: string): void {
    const lang = id.includes('.') ? id.split('.')[0] : id;
    this.set('language', id);
    this.translocoService.setActiveLang(id);
    this.setLocale(lang);
  }

  private setLocale(locale: string): void {
    Settings.defaultLocale = locale;
  }
}
