<ng-container *transloco="let t">
  <div class="gap-xs flex justify-end">
    <button mat-button [matMenuTriggerFor]="languagesSelector">
      <mat-icon>translate</mat-icon> {{ t('menu.top.language') }}
    </button>
  </div>

  <mat-menu #languagesSelector>
    <ng-container *ngFor="let lang of languages">
      <button mat-menu-item (click)="useLanguage(lang.value)">{{ lang.label }}</button>
    </ng-container>
  </mat-menu>

  <h2 mat-dialog-title>{{ t('login.old_app') }}</h2>

  <mat-dialog-content>
    {{ t('login.old_app.description_1') }}
    <mat-list>
      <mat-list-item>1. {{ t('login.old_app.description_2') }}</mat-list-item>
      <mat-list-item>2. {{ t('login.old_app.description_3') }}</mat-list-item>
      <mat-list-item>3. {{ t('login.old_app.description_4') }}</mat-list-item>
      <mat-list-item>4. {{ t('login.old_app.description_5') }}</mat-list-item>
    </mat-list>
  </mat-dialog-content>
  <mat-dialog-actions class="flex space-between">
    <button mat-raised-button [mat-dialog-close]="true">{{ t('general.close') }}</button>
    @if (environment.includes('gefo')) {
      <a mat-raised-button color="accent" href="https://gefo.app">{{ t('login.old_app.goto') }} gefo.app</a>
    } @else {
      <a mat-raised-button color="accent" href="https://deymann.app">{{ t('login.old_app.goto') }} deymann.app</a>
    }
  </mat-dialog-actions>
</ng-container>
