<ng-container *transloco="let t">
  <div class="flex justify-end">
    <button mat-button [matMenuTriggerFor]="languagesSelector">
      <mat-icon>translate</mat-icon> {{ t('menu.top.language') }}
    </button>
  </div>

  <mat-menu #languagesSelector>
    <ng-container *ngFor="let lang of languages">
      <button mat-menu-item (click)="useLanguage(lang.value)">{{ lang.label }}</button>
    </ng-container>
  </mat-menu>

  <h1 mat-dialog-title>
    {{ t('login.first_steps') }}
  </h1>

  <mat-dialog-content>
    {{ t('login.first_steps.description_1') }}
    <span style="color: red">{{ t('login.first_steps.description_2') }}</span>
    <br />
    <br />
    <mat-button-toggle-group class="margin-xs-bottom" [(ngModel)]="browser" [value]="browser">
      <mat-button-toggle value="android">Android / Chrome</mat-button-toggle>
      <mat-button-toggle value="ios">IOS / Safari</mat-button-toggle>
    </mat-button-toggle-group>

    <ng-container *ngIf="browser === 'android'; else ios">
      <div class="flex wrap align-center justify-center">
        <figure class="space-xxs-bottom">
          <img src="/assets/icons/Android.png" style="width: 256px; height: auto" alt="Open options in Chrome" />
          <figcaption style="width: 256px">{{ t('login.first_steps.step_android_1') }}</figcaption>
        </figure>
        <figure class="space-xxs-bottom">
          <img src="/assets/icons/Android-actions.png" style="width: 256px; height: auto" alt="Choose 'App install'" />
          <figcaption style="width: 256px">{{ t('login.first_steps.step_android_2') }}</figcaption>
        </figure>
        <figure>
          <img src="/assets/icons/Home-screen.png" style="width: 256px; height: auto" alt="Open app on Home Screen" />
          <figcaption style="width: 256px">{{ t('login.first_steps.step_3') }}</figcaption>
        </figure>
      </div>
    </ng-container>
    <ng-template #ios>
      <div class="flex wrap align-center justify-center">
        <figure>
          <img src="/assets/icons/Safari.png" style="width: 256px; height: auto" alt="Open options in Safari" />
          <figcaption style="width: 256px">{{ t('login.first_steps.step_1') }}</figcaption>
        </figure>
        <figure>
          <img
            src="/assets/icons/Safari-actions.png"
            style="width: 256px; height: auto"
            alt="Choose 'Add to Home Screen'"
          />
          <figcaption style="width: 256px">{{ t('login.first_steps.step_2') }}</figcaption>
        </figure>
        <figure>
          <img src="/assets/icons/Home-screen.png" style="width: 256px; height: auto" alt="Open app on Home Screen" />
          <figcaption style="width: 256px">{{ t('login.first_steps.step_3') }}</figcaption>
        </figure>
      </div>
    </ng-template>
  </mat-dialog-content>

  <mat-dialog-actions class="flex space-between">
    <button mat-button color="accent" [mat-dialog-close]="true" (click)="dontShowAgain()">
      {{ t('components.hint_dialog.dont_show') }}
    </button>
    <button mat-raised-button color="primary" [mat-dialog-close]="true">{{ t('login.first_steps.ok') }}</button>
  </mat-dialog-actions>
</ng-container>
