<mat-accordion class="main-menu" displayMode="flat" *transloco="let t">
  <mat-action-list>
    <button *ngIf="![5, 9].includes(roleId)" mat-list-item routerLink="/user/dashboard" (click)="itemSelected.emit()">
      <mat-icon matListItemIcon class="material-symbols-outlined">dashboard</mat-icon>
      Dashboard
    </button>
  </mat-action-list>

  <!-- verwaltung -->
  <mat-expansion-panel *ngIf="[2, 8, 10, 12].includes(roleId)">
    <mat-expansion-panel-header expandedHeight="48px">
      <mat-panel-title>
        {{ t('menu.certification.manage') }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-action-list>
      <button
        *ngIf="[8, 10].includes(roleId)"
        mat-list-item
        routerLink="/equipment/manage"
        (click)="itemSelected.emit()"
      >
        <mat-icon matListItemIcon class="material-symbols-outlined">folder_managed</mat-icon>
        {{ t('views.psa.manage') }}
      </button>
      <button *ngIf="[8, 10].includes(roleId)" mat-list-item routerLink="/equipment/user" (click)="itemSelected.emit()">
        <mat-icon matListItemIcon class="material-symbols-outlined margin-xxs-right">overview</mat-icon>
        {{ t('views.psa.overview_equipment') }}
      </button>
      <button
        *ngIf="[2, 8, 10].includes(roleId)"
        mat-list-item
        routerLink="/equipment/overview"
        (click)="itemSelected.emit()"
      >
        <mat-icon matListItemIcon class="material-symbols-outlined margin-xxs-right">shopping_bag</mat-icon>
        {{ t('views.psa.overview_orders') }}
      </button>
      <button
        *ngIf="[8, 10, 12].includes(roleId)"
        mat-list-item
        routerLink="/warehouse/overview"
        (click)="itemSelected.emit()"
      >
        <mat-icon matListItemIcon class="material-symbols-outlined margin-xxs-right">local_post_office</mat-icon>
        {{ t('warehouse.overview') }}
      </button>
      <button
        *ngIf="[8, 10, 12].includes(roleId)"
        mat-list-item
        routerLink="/warehouse/scanner"
        (click)="itemSelected.emit()"
      >
        <mat-icon matListItemIcon class="material-symbols-outlined margin-xxs-right">barcode_scanner</mat-icon>
        {{ t('warehouse.dashboard.scanner') }}
      </button>
    </mat-action-list>
  </mat-expansion-panel>

  <!-- order -->
  <mat-expansion-panel *ngIf="[1, 3, 4, 6, 7, 8, 10].includes(roleId)">
    <mat-expansion-panel-header expandedHeight="48px">
      <mat-panel-title>
        {{ t('views.psa.equipment') }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-action-list>
      <button mat-list-item routerLink="/user/crew-member/order" (click)="itemSelected.emit()">
        <mat-icon matListItemIcon class="material-symbols-outlined margin-xxs-right">shopping_cart</mat-icon>
        {{ t('views.psa.order') }}
      </button>
      <button mat-list-item routerLink="/user/crew-member/status" (click)="itemSelected.emit()">
        <mat-icon matListItemIcon class="material-symbols-outlined margin-xxs-right">orders</mat-icon>
        {{ t('views.psa.order_status') }}
      </button>
      <button mat-list-item routerLink="/user/crew-member/myEquipment" (click)="itemSelected.emit()">
        <mat-icon matListItemIcon class="material-symbols-outlined margin-xxs-right">apparel</mat-icon>
        {{ t('views.psa.my_equipment') }}
      </button>
    </mat-action-list>
  </mat-expansion-panel>

  <!-- Management -->
  <mat-expansion-panel *ngIf="[1, 3, 4, 8].includes(roleId)">
    <mat-expansion-panel-header expandedHeight="48px">
      <mat-panel-title>
        {{ t('menu.management') }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-action-list>
      <button mat-list-item [routerLink]="'/management/smv/overview'" (click)="itemSelected.emit()">
        <mat-icon matListItemIcon class="material-symbols-outlined margin-xxs-right">running_with_errors</mat-icon>
        {{ t('menu.management.start_smv') }}
      </button>
      <button mat-list-item [routerLink]="['/management/visits', 'visits']" (click)="itemSelected.emit()">
        <mat-icon matListItemIcon class="material-symbols-outlined margin-xxs-right">supervised_user_circle</mat-icon>
        {{ t('menu.management.smv_running') }}
      </button>
    </mat-action-list>
  </mat-expansion-panel>

  <!-- Inspektor -->
  <mat-expansion-panel *ngIf="[1, 6, 8].includes(roleId)">
    <mat-expansion-panel-header expandedHeight="48px">
      <mat-panel-title> {{ t('menu.inspector') }} </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-action-list>
      <button mat-list-item [routerLink]="['/visits-reports/start', 'reports']" (click)="itemSelected.emit()">
        <mat-icon matListItemIcon class="material-symbols-outlined margin-xxs-right">start</mat-icon>
        {{ t('menu.site.start_report') }}
      </button>
      <button mat-list-item [routerLink]="'/inspector/tasks'" (click)="itemSelected.emit()">
        <mat-icon matListItemIcon class="material-symbols-outlined margin-xxs-right">task</mat-icon>
        {{ t('components.useravatar.tasks') }}
      </button>
      <button mat-list-item [routerLink]="'/inspector/history'" (click)="itemSelected.emit()">
        <mat-icon matListItemIcon class="material-symbols-outlined margin-xxs-right">history</mat-icon>
        {{ t('menu.certification.history') }}
      </button>
    </mat-action-list>
  </mat-expansion-panel>

  <!-- Shipreports -->
  <mat-expansion-panel *ngIf="[1, 7, 8].includes(roleId)">
    <mat-expansion-panel-header expandedHeight="48px">
      <mat-panel-title> {{ t('menu.site.reports') }} </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-action-list>
      <button
        *ngIf="[1, 8].includes(roleId)"
        mat-list-item
        routerLink="/visits-reports/emission-report"
        (click)="itemSelected.emit()"
      >
        <mat-icon matListItemIcon class="material-symbols-outlined margin-xxs-right">compost</mat-icon> Emmission Report
      </button>
      <button mat-list-item [routerLink]="['/visits-reports/start', 'reports']" (click)="itemSelected.emit()">
        <mat-icon matListItemIcon class="material-symbols-outlined margin-xxs-right">start</mat-icon>
        {{ t('menu.site.start_report') }}
      </button>
      <button mat-list-item [routerLink]="['/visits-reports/history', 'reports']" (click)="itemSelected.emit()">
        <mat-icon matListItemIcon class="material-symbols-outlined margin-xxs-right">history</mat-icon>
        {{ t('menu.certification.history') }}
      </button>
    </mat-action-list>
  </mat-expansion-panel>

  <!-- Shipvisits -->
  <mat-expansion-panel *ngIf="[1, 3, 4, 6, 7, 8, 11].includes(roleId)">
    <mat-expansion-panel-header expandedHeight="48px">
      <mat-panel-title> {{ t('menu.site.visits') }} </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-action-list>
      <button mat-list-item [routerLink]="['/visits-reports/start', 'visits']" (click)="itemSelected.emit()">
        <mat-icon matListItemIcon class="material-symbols-outlined margin-xxs-right">start</mat-icon>
        {{ t('menu.site.start_visit') }}
      </button>
      <button mat-list-item [routerLink]="['/visits-reports/history', 'visits']" (click)="itemSelected.emit()">
        <mat-icon matListItemIcon class="material-symbols-outlined margin-xxs-right">history</mat-icon>
        {{ t('menu.certification.history') }}
      </button>
    </mat-action-list>
  </mat-expansion-panel>

  <!-- Training -->
  <mat-expansion-panel *ngIf="[1, 6, 8].includes(roleId)">
    <mat-expansion-panel-header expandedHeight="48px">
      <mat-panel-title>
        {{ t('modules.calendar.title') }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-action-list>
      <button
        mat-list-item
        [routerLink]="'/calendar/calendar/' + (currentDate | date: 'yyyy-MM-dd')"
        (click)="itemSelected.emit()"
      >
        <mat-icon matListItemIcon class="material-symbols-outlined margin-xxs-right">event</mat-icon>
        {{ t('menu.certification.calendar') }}
      </button>
      <button disabled mat-list-item (click)="itemSelected.emit()">
        <mat-icon matListItemIcon class="material-symbols-outlined margin-xxs-right">summarize</mat-icon>
        {{ t('modules.calendar.evaluation') }}
      </button>
    </mat-action-list>
  </mat-expansion-panel>

  <!-- user -->
  <mat-expansion-panel *ngIf="[1, 8].includes(roleId)">
    <mat-expansion-panel-header expandedHeight="48px">
      <mat-panel-title>
        {{ t('menu.user.title') }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-action-list>
      <button mat-list-item routerLink="/user/list" (click)="itemSelected.emit()">
        <mat-icon matListItemIcon class="material-symbols-outlined margin-xxs-right">engineering</mat-icon>
        <span class="fill-remaining-space">{{ t('menu.user.manage') }}</span>
      </button>
      <button mat-list-item routerLink="/user/groups" (click)="itemSelected.emit()">
        <mat-icon matListItemIcon class="material-symbols-outlined margin-xxs-right">group</mat-icon>
        {{ t('menu.user.groups') }}
      </button>
      <button disabled mat-list-item routerLink="/user/document-status" (click)="itemSelected.emit()">
        <mat-icon matListItemIcon class="material-symbols-outlined margin-xxs-right">update</mat-icon>
        {{ t('menu.user.document-status') }}
      </button>
    </mat-action-list>
  </mat-expansion-panel>

  <!-- assets -->
  <mat-expansion-panel *ngIf="[1, 6, 7, 8].includes(roleId)">
    <mat-expansion-panel-header expandedHeight="48px">
      <mat-panel-title>
        {{ t('menu.assets.title') }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-action-list>
      <button mat-list-item routerLink="/assets/take" (click)="itemSelected.emit()">
        <mat-icon matListItemIcon class="material-symbols-outlined margin-xxs-right">qr_code_scanner</mat-icon>
        {{ t('menu.assets.transfer') }}
      </button>
      <app-filter-list-menu-outlet
        (itemSelected)="itemSelected.emit()"
        for="/assets/table"
        menuEntry="Assets"
      ></app-filter-list-menu-outlet>
      <button *ngIf="[1, 8].includes(roleId)" mat-list-item routerLink="/assets/list" (click)="itemSelected.emit()">
        <mat-icon matListItemIcon class="material-symbols-outlined margin-xxs-right">dynamic_feed</mat-icon>
        {{ t('menu.assets.overview') }}
      </button>
      <button
        mat-list-item
        routerLink="/assets/asset-map"
        [queryParams]="{ category: 3 }"
        (click)="itemSelected.emit()"
      >
        <mat-icon matListItemIcon class="material-symbols-outlined margin-xxs-right">map</mat-icon>
        {{ t('menu.assets.map') }}
      </button>
    </mat-action-list>
  </mat-expansion-panel>

  <!-- site -->
  <mat-expansion-panel *ngIf="[1, 3, 4, 6, 7, 8, 10, 11].includes(roleId)">
    <mat-expansion-panel-header expandedHeight="48px">
      <mat-panel-title>
        {{ t('menu.site.title') }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-action-list>
      <app-filter-list-menu-outlet
        (itemSelected)="itemSelected.emit()"
        for="/assets/table"
        menuEntry="Ships"
      ></app-filter-list-menu-outlet>
      <button mat-list-item routerLink="/vessel/vessel-map" (click)="itemSelected.emit()">
        <mat-icon matListItemIcon class="material-symbols-outlined margin-xxs-right">map</mat-icon>
        {{ t('menu.site.map') }}
      </button>
    </mat-action-list>
  </mat-expansion-panel>

  <!-- emergency -->
  <ng-container *ngIf="![2, 5, 9, 12].includes(roleId)">
    <mat-expansion-panel *ngFor="let menu of staticPageMenu | objectKeys">
      <mat-expansion-panel-header expandedHeight="48px">
        <mat-panel-title>
          {{ menu }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-action-list>
        <button mat-list-item routerLink="/emergency/barge" (click)="itemSelected.emit()">
          <mat-icon matListItemIcon class="material-symbols-outlined margin-xxs-right">campaign</mat-icon> Contact List
          (Barge)
        </button>
        <button mat-list-item routerLink="/emergency/sea" (click)="itemSelected.emit()">
          <mat-icon matListItemIcon class="material-symbols-outlined margin-xxs-right">campaign</mat-icon> Contact List
          (Sea)
        </button>
        <button mat-list-item routerLink="/emergency/oil" (click)="itemSelected.emit()">
          <mat-icon matListItemIcon class="material-symbols-outlined margin-xxs-right">campaign</mat-icon> Oil Major
          (Sea)
        </button>
        <ng-container *ngFor="let item of staticPageMenu[menu]">
          <button *ngIf="item.action" mat-list-item (click)="handleStaticPageAction.emit(item) && itemSelected.emit()">
            <mat-icon matListItemIcon class="material-symbols-outlined margin-xxs-right">{{
              item.menuItemIcon
            }}</mat-icon>
            {{ item.menuItem }}
          </button>
        </ng-container>
        <button
          *ngIf="[1, 8].includes(roleId)"
          mat-list-item
          routerLink="/emergency/history"
          (click)="itemSelected.emit()"
        >
          <mat-icon matListItemIcon class="material-symbols-outlined margin-xxs-right">history</mat-icon>
          {{ t('menu.certification.history') }}
        </button>
      </mat-action-list>
    </mat-expansion-panel>
  </ng-container>
</mat-accordion>
<div class="sidemenu-footer hidden-sm">
  <div class="flex justify-center align-center">
    <img src="/assets/logo/logo_default_small.png" height="20px" />
    Version {{ version }}
  </div>
</div>
