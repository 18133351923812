import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { MemoryService } from 'src/app/services/memory.service';
import { config } from 'src/config';

@Component({
  selector: 'app-install-dialog',
  templateUrl: './install-dialog.component.html'
})
export class InstallDialogComponent implements OnInit {
  languages: { label: string; value: string }[] = [];
  availableLanguages: string[];
  browser = 'android';

  constructor(
    public dialogRef: MatDialogRef<InstallDialogComponent>,
    private readonly translocoService: TranslocoService,
    private readonly memoryService: MemoryService
  ) {}

  ngOnInit(): void {
    this.availableLanguages = this.translocoService.getAvailableLangs() as string[];
    this.languages = [];
    for (const lang of this.availableLanguages) {
      this.languages.push({ label: config.languages[lang], value: lang });
    }
  }

  useLanguage(id: string): void {
    this.memoryService.changeLanguage(id);
  }

  dontShowAgain() {
    localStorage.setItem('first_steps_show', 'true');
  }
}

@NgModule({
  declarations: [InstallDialogComponent],
  exports: [InstallDialogComponent],
  imports: [
    TranslocoModule,
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatMenuModule,
    FormsModule,
    MatButtonToggleModule
  ]
})
export class InstallDialogComponentModule {}
