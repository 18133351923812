<mat-accordion>
  <mat-expansion-panel *ngFor="let item of tableData" [expanded]="item.expand">
    <mat-expansion-panel-header *ngIf="!detailedHeader">
      <mat-panel-title>
        {{ item.title }}
      </mat-panel-title>
      <mat-panel-description *ngIf="item.description">
        {{ item.description }}
      </mat-panel-description>
      <mat-panel-description *ngIf="item.deadline"> fällig am: {{ item.deadline }} </mat-panel-description>
    </mat-expansion-panel-header>
    <mat-expansion-panel-header *ngIf="detailedHeader" style="height: auto">
      <div class="detailed-panel flex width-100 align-center">
        <div class="circle" [ngClass]="item.processTypeMeta?.color">
          {{ item.processTypeMeta?.name || '...' }}
        </div>
        <div class="flex column space-xs-left space-xxs-top space-xxs-bottom space-between">
          <span class="detailed-header">
            {{ item.userId | idToName | async }}
            <span class="mat-expansion-panel-header-description">
              {{ item.description }}
              <br />
              {{ item.title }}
            </span>
          </span>
        </div>
      </div>
    </mat-expansion-panel-header>
    <div class="grid two-columns no-gap">
      <ng-container *ngFor="let row of item.rows">
        <div class="item gap-xxs">
          {{ row.header }} <br />
          <small *ngIf="row.details">{{ row.details }}</small>
        </div>
        <div class="item gap-xxs">
          <ng-container *ngIf="row.type; else default">
            <ng-container *ngIf="row.type === 'tel'">
              <a href="tel: {{ row.content }}">{{ row.content }}</a>
            </ng-container>
            <ng-container *ngIf="row.type === 'mail'">
              <a href="mailto: {{ row.content }}">{{ row.content }}</a>
            </ng-container>
            <ng-container *ngIf="row.type === 'userId'">
              {{ row.content | idToName | async }}
            </ng-container>
          </ng-container>
          <ng-template #default>
            {{ row.content }}
          </ng-template>
        </div>
      </ng-container>
    </div>
    <div class="flex gap-xxs" [class.justify-end]="!item.deletable" [class.space-between]="item.deletable">
      <button *ngIf="item.deletable" mat-raised-button color="warn" (click)="deleteEvent.emit(item)">
        <mat-icon class="material-symbols-outlined">delete</mat-icon>
      </button>
      <button *ngIf="resultDialog" mat-raised-button color="accent" (click)="openProcessResult(item.processId)">
        <mat-icon>search</mat-icon>
      </button>
      <!-- If the responsible roleId of the step is not the same as the roleId of the user. The process can not be resumed. 
        This is currently only needed for own-site-component if the process is at a Inspector step. This step should not be started by the Schiffsführer. -->
      <ng-container *ngIf="item.responsibleRole; else noRole">
        <button
          *ngIf="processDialog && item.responsibleRole !== excludeProcessByRoleId"
          mat-raised-button
          color="accent"
          (click)="proceedProcess(item.stepId, item.processId)"
        >
          <mat-icon>start</mat-icon>
        </button>
      </ng-container>
      <ng-template #noRole>
        <button
          *ngIf="processDialog"
          [disabled]="!item.canResume"
          mat-raised-button
          color="accent"
          (click)="proceedProcess(item.stepId, item.processId)"
        >
          <mat-icon>start</mat-icon>
        </button>
        <button
          [disabled]="!item.canResume"
          mat-raised-button
          color="warn"
          (click)="
            deleteEvent.emit({
              processId: item.processId,
              title: item.rows[0].content,
              vessel: item.title,
              date: item.description
            })
          "
        >
          <mat-icon class="material-symbols-outlined">delete</mat-icon>
        </button>
      </ng-template>
      <a *ngIf="item.routerLink" mat-raised-button color="accent" [routerLink]="item.routerLink">
        <mat-icon>open_in_new</mat-icon>
      </a>
      <!-- ToDo: currently only used for edit user, maybe this should be named differently -->
      <button *ngIf="customEvent" color="accent" mat-raised-button (click)="customEventEmitter.emit(item)">
        <mat-icon>edit</mat-icon>
      </button>
    </div>
  </mat-expansion-panel>
</mat-accordion>
<mat-progress-bar *ngIf="subscriptionIsActive(subscription)" [mode]="'indeterminate'"></mat-progress-bar>
<div style="background-color: white" *ngIf="!subscriptionIsActive(subscription) && !tableData?.length" class="gap-sm">
  Keine Ergebnisse
</div>
