import { Injectable } from '@angular/core';
import { ValidatorFn, AbstractControl } from '@angular/forms';
import { EquipmentSupplierModel } from '../models/equipment.model';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  conditionalRequiredValidator(conditionFn: () => boolean): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (conditionFn()) {
        if (!control.value) {
          return { required: true };
        }
      }
      return null;
    };
  }

  getSupplierName(id: number, supplier: EquipmentSupplierModel[]): string {
    return supplier.find((sup) => sup.id === id).name;
  }

  calculateCondition(date: string): number {
    // calc based on date, we use it to evaluate the position received of a vessel
    const currentTime = new Date();
    const timeDifference = currentTime.getTime() - new Date(date).getTime();
    const hoursDifference = timeDifference / (1000 * 60 * 60);
    // Check if the time difference is greater than 2 hours
    if (hoursDifference > 2 && hoursDifference <= 11) {
      return 3;
    }
    if (hoursDifference > 12) {
      return 5;
    }
    return 1;
  }
}
