<form *transloco="let t">
  <input
    class="real-input"
    name="fileInput"
    #fileInput
    type="file"
    [(ngModel)]="filePath"
    (change)="onChange($event.target.files)"
    [multiple]="multiple ? 'multiple' : ''"
  />
  <span class="file-form">
    <mat-form-field>
      <mat-label *ngIf="!file">{{ placeholder || t('general.choose_file') }}</mat-label>
      <mat-label *ngIf="file">{{ t('general.send') }}</mat-label>
      <input matInput disabled [(ngModel)]="fileName" name="fileName" (click)="selectFile()" place />
      <mat-icon matSuffix *ngIf="fileName" (click)="clearInputs()">clear</mat-icon>
    </mat-form-field>
    <button *ngIf="!file" mat-mini-fab color="primary" [disabled]="disabledPick" (click)="selectFile()">
      <mat-icon>attach_file</mat-icon>
    </button>
    <button *ngIf="file && !hideUpload" mat-mini-fab [disabled]="disabledUpload" (click)="uploadFile()">
      <mat-icon>send</mat-icon>
    </button>
  </span>
  <mat-progress-bar *ngIf="progress" mode="determinate" [value]="progress"></mat-progress-bar>
</form>
