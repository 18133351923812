import { Expose, Type } from 'class-transformer';
import { Stripper } from './stripper.model';

export class SiteUserModel extends Stripper {
  @Expose() siteId: number;
  @Expose() userId: number;

  @Type(() => Date)
  @Expose()
  presentFrom: Date;

  @Type(() => Date)
  @Expose()
  presentTo: Date;

  isActive(): boolean {
    return this.presentFrom < new Date() && (this.presentTo > new Date() || this.presentTo < this.presentFrom);
  }
}
