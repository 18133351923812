<ng-container *transloco="let t">
  <h1 mat-dialog-title>Select on Map</h1>
  <mat-dialog-content>
    <google-map
      [height]="height"
      [width]="width"
      [center]="center"
      [zoom]="zoom"
      mapTypeId="roadmap"
      [options]="mapOptions"
      (mapClick)="onclick($event)"
    >
      <map-marker *ngIf="clickPosition" [position]="clickPosition"></map-marker>
    </google-map>
  </mat-dialog-content>
  <mat-dialog-actions class="flex justify-end">
    <button mat-raised-button mat-dialog-close>{{ t('general.close') }}</button>
    @if (!data.readonly) {
      <button mat-raised-button color="primary" (click)="onConfirm()">{{ t('general.confirm') }}</button>
    }
  </mat-dialog-actions>
</ng-container>
