import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/services/user.service';

@Pipe({
  name: 'roleName'
})
export class RoleNamePipe implements PipeTransform {
  constructor(private readonly userService: UserService) {}

  transform(roleId: number): Observable<string> {
    return new Observable<string>((observer) => {
      this.userService.getAllRoles().subscribe((roles) => {
        const role = roles.rows.find((_r) => _r.roleId === Number(roleId));
        if (role) {
          observer.next(role.name);
        } else {
          observer.next('-');
        }
        observer.complete();
      });
    });
  }
}

@NgModule({
  declarations: [RoleNamePipe],
  exports: [RoleNamePipe]
})
export class RoleNamePipeModule {}
