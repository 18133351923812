import { Expose, Type } from 'class-transformer';
import { Role } from './role.interface';
import { UserGroup, UsersGroups } from './user-group.interface';
import { DocumentModel } from 'src/app/models/document.model';
import { Stripper } from 'src/app/models/stripper.model';
import { SiteUserModel } from 'src/app/models/site-user.model';

export class User extends Stripper {
  @Expose() userId?: number;
  @Expose() forename: string;
  @Expose() surname: string;

  // 1: male, 2: female
  @Expose() salutation: number;

  @Expose() birthday: string;

  @Expose() enteredAt: string;

  @Expose() mobilePhone: string;

  @Expose() clothingSizeTop: string;

  @Expose() clothingSizeBottom: string;

  @Expose() headCircumference: number;

  @Expose() gloveSize: number;

  @Expose() shoeSize: number;

  @Expose() measures: string;

  @Expose() equipmentClassId: number;

  @Expose() additionalInformation: string;

  @Type(() => UserLogin)
  @Expose()
  userLogin?: UserLogin;

  @Expose() profilePicturePath: string;

  @Type(() => DocumentModel)
  @Expose()
  documents?: DocumentModel[];
}

export class UserLogin extends Stripper {
  @Expose() userId: number;
  @Expose() email: string;
  @Expose() loginToken?: string;
  @Expose() loginTokenExpiresAt?: Date;
  @Expose() roleId: number;
  @Expose() active: null | 1 | 0;

  @Type(() => User)
  @Expose()
  user?: User;

  @Expose() role?: Role;
  @Expose() userGroups?: UserGroup[];

  @Expose() usersGroups?: UsersGroups[];

  @Type(() => SiteUserModel)
  @Expose()
  siteUser?: SiteUserModel;
}

export class UserToken {
  userId: number;
  loginToken: string;
  expires_at: Date;
}

export interface UserData {
  userLogin: UserLogin;
  userToken: UserToken;
}
