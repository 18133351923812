import { Component, ContentChild, ElementRef, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ProcessHandlerService } from '../../process-handler-dialog/process-handler.service';
import { AbstractStepHandleComponent } from '../abstract-step-handle/abstract-step-handle.component';
import { EquipmentService } from 'src/app/services/equipment.service';
import { EquipmentModel } from 'src/app/models/equipment.model';
import { config } from 'src/config';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-step-handle-manual-selection',
  templateUrl: './step-handle-manual-selection.component.html'
})
export class StepHandleManualSelectionComponent extends AbstractStepHandleComponent implements OnInit, OnChanges {
  @ContentChild('overlayContent') overlayContent: ElementRef;
  itemsInCart: EquipmentModel[];
  backendUrl = config.apiUrl;

  backSubscription: Subscription;

  constructor(
    private readonly processHandlerService: ProcessHandlerService,
    private readonly equipmentService: EquipmentService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.step) {
      let supplierIds: string[];
      if (this.step.process.process_definition_id === 19) {
        supplierIds = this.step.process.DataFields?.find((field) => field.name === 'Artikelnummern').value;
      } else if (this.step.process.process_definition_id === 23) {
        supplierIds = this.step.process.DataFields?.find((field) => field.name === 'notFittingArticles').value;
      }
      this.equipmentService
        .getEquipment({ supplierId: { $in: [supplierIds] } })
        .subscribe((res) => (this.itemsInCart = res.rows));

      if (this.step.stepDefinition.type_data.choices.length < 3) {
        const back = this.step.stepDefinition.type_data.terminate_buttons?.find((e) => e.label.includes('back'));
        this.processHandlerService.stepButtons.next({
          choices: this.step.stepDefinition.type_data.choices,
          back: Boolean(back)
        });
      }
    }
  }

  ngOnInit(): void {
    if (this.step.stepDefinition.type_data.terminate_buttons) {
      this.backSubscription = this.processHandlerService.backClicked.subscribe(() => this.onBackClicked());
    }
    this.processHandlerService.choiceSelected.subscribe((e) => this.stepActionHandled.emit({ choice: e }));
  }

  shouldShowOverlay(): boolean {
    return this.overlayContent?.nativeElement.children.length > 0;
  }

  onBackClicked(): void {
    const backButton = this.step.stepDefinition.type_data.terminate_buttons.find((e) => e.label.includes('back'));
    this.performBackAction.emit({
      stepId: this.step.id,
      stepDefinitionId: backButton.step_definition_id
    });
  }
}
