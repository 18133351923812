import { Component, Input, OnInit, OnChanges, SimpleChanges, NgModule } from '@angular/core';
import { config } from 'src/config';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { RouterModule } from '@angular/router';
import { GetSitePipeModule } from '../../pipes/get-site.pipe';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/interfaces/user/user.interface';

@Component({
  selector: 'app-user-outline',
  templateUrl: './user-outline.component.html',
  styleUrls: ['./user-outline.component.scss']
})
export class UserOutlineComponent implements OnInit, OnChanges {
  @Input() size: 'small' | 'normal' | 'large' = 'small';
  @Input() user: User;
  userGroupParentIcon: string;
  profilePictureLink: string;

  backendUrl = config.apiUrl;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    if (this.size !== 'small') {
      for (const group of this.user?.userLogin?.userGroups || []) {
        this.userService.getParentUserGroup(group.userGroupId).subscribe((g) => {
          group.parent = g;
        });
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user) {
      this.profilePictureLink = `${this.backendUrl}document/profilepicture/${this.user?.userId}/get`;

      if (this.size !== 'small') {
        for (const group of this.user?.userLogin?.userGroups || []) {
          this.userService.getParentUserGroup(group.userGroupId).subscribe((g) => {
            group.parent = g;
          });
        }
      }
    }
  }

  stringify(obj: any): string {
    return JSON.stringify(obj);
  }
}

@NgModule({
  declarations: [UserOutlineComponent],
  exports: [UserOutlineComponent],
  imports: [CommonModule, MatIconModule, GetSitePipeModule, MatTooltipModule, MatChipsModule, RouterModule]
})
export class UserOutlineComponentModule {}
