import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'app-update-counter',
  templateUrl: './update-counter.component.html',
  styleUrls: ['./update-counter.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslocoModule, MatButtonModule]
})
export class UpdateCounterComponent {
  counter = 5;

  constructor() {
    const interval = setInterval(() => {
      this.counter--;
      if (this.counter === 0) {
        location.reload();
        clearInterval(interval);
      }
    }, 1000);
  }

  onClick(): void {
    location.reload();
  }
}
