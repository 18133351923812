import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
  name: 'convertDays',
  standalone: true
})
export class ConvertDaysPipe implements PipeTransform {
  constructor(private readonly transloco: TranslocoService) {}

  transform(value: number): string {
    if (!(typeof value === 'number' && value === value)) return '-'; // handle empty or null input
    let dayString: string;
    if (value < 7) {
      dayString = `${value} ${this.transloco.translate('dates.time.days')}`;
    }
    if (Math.floor(value / 7) === 1) {
      dayString = `${Math.round(value / 7)} ${this.transloco.translate('dates.time.week')}`;
    }
    if (Math.floor(value / 7) > 1) {
      dayString = `${Math.round(value / 7)} ${this.transloco.translate('dates.time.weeks')}`;
    }
    // if (Math.floor(value / 30) === 1) {
    //   dayString = `${Math.round(value / 30)} ${this.transloco.translate('dates.time.month')}`;
    // }
    // if (Math.floor(value / 30) > 1) {
    //   dayString = `${Math.round(value / 30)} ${this.transloco.translate('dates.time.months')}`;
    // }
    // if (value === 0) {
    //   dayString = this.transloco.translate('views.psa.in_stock');
    // }

    return dayString;
  }
}
