import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BackendObservable, BackendService } from './backend.service';
import { EmergencyContent } from '../views/emergency/emergency-content.interface';
import { StaticPageModel } from '../interfaces/static-pages/static-page.model';

@Injectable({
  providedIn: 'root'
})
export class StaticPageService extends BackendService {
  getAll(forceReload = false): Observable<StaticPageModel[]> {
    if (forceReload) {
      this.cachingService.invalidateCache('getAllStaticPages');
    }
    return this.cachingService.createCachingSubscription(
      'getAllStaticPages',
      this.post<StaticPageModel[]>(`all/get`, {})
    );
  }

  getPage(pageId: number): Observable<StaticPageModel> {
    return this.cachingService.createCachingSubscription(
      `staticPage${pageId}`,
      this.get<StaticPageModel>(`${pageId}/get`)
    );
  }

  editPage(pageId: number, content: EmergencyContent): Observable<StaticPageModel> {
    this.cachingService.invalidateCache(`staticPage${pageId}`);
    return this.post<StaticPageModel>(`${pageId}/edit`, { content: JSON.stringify(content) });
  }

  post<T>(url: string, body: any = {}, options?: any): BackendObservable<T> {
    return super.post<T>(`staticPages/${url}`, body, options);
  }

  get<T>(url: string, options?: any): Observable<T> {
    return super.get<T>(`staticPages/${url}`, options);
  }
}
